const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

// const API_KEY = "AIzaSyDgsjp0OMKwV4liOoKoI6ZAdqLi6myHR1c";

export const redirectUrl = "https://findmyplots.in";
export const baseUrl = "https://findmyplots.in/cms_api";
export const baseUrlPlotsApi = "https://findmyplots.in/plot_api";

// export const redirectUrl = "https://findmyplots.com";
// export const baseUrl = "https://cms-api.findmyplots.com";
// export const baseUrlPlotsApi = "https://api.findmyplots.com";

export default API_KEY;
