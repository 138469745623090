import React from 'react';

export function useSnackbar() {
    const [isActive, setIsActive] = React.useState(false);
    const [message, setMessage] = React.useState();
    const [severity, setSeverity] = React.useState('');
    React.useEffect(() => {
        if (isActive === true) {
            setTimeout(() => {
                setIsActive(false);
            }, 5000);
        }
    }, [isActive]);

    const openSnackBar = (msg = 'Something went wrong...', severity = 'error') => {
        setMessage(msg)
        setSeverity(severity)
        setIsActive(true);
    }

    return { isActive, message, openSnackBar, setIsActive, severity }
}