import { LOGIN_SUCCESS, LOGIN_FAILED, ON_LOGIN, CONFIG } from "./actionType";

export const initialState = {
  user: {},
  config: {},
};

const authReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case ON_LOGIN:
      return state;
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          isAuthenticated: data.isAuthenticated,
          user_id: data.user_id,
          user_type: data.user_type,
        },
      };
    case LOGIN_FAILED:
      return {
        ...state,
        user: {
          ...state.user,
          isAuthenticated: data.isAuthenticated,
          user_id: data.user_id,
          user_type: data.user_type
        },
      };
    case CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          googleApiKey: data.googleApiKey,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
