import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { withScriptjs } from "react-google-maps";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AppsIcon from "@material-ui/icons/Apps";

import IconButton from "@material-ui/core/IconButton";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { SketchPicker } from "react-color";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
// import DrawMaps from "./DrawMapsNew";
import Snackbar from "@material-ui/core/Snackbar";
import XHR from "../../util/xhr";
import DrawMaps from "./DrawMapsNew1";
import CircularProgress from "@material-ui/core/CircularProgress";

import { redirectUrl } from "../../util/config";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Map = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const {
    id,
    name,
    gps_location,
    zoom_level = 17,
    polyline = [],
    row,
  } = history.location.state;
  const [state, setState] = useState({
    drawingControlEnabled: true,
    marker: null,
    polyline: null,
    circleRadius: null,
    circleCenter: null,
    rectangle: null,
    polygon: [],
    visible: true,
  });

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [map, setMap] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const [polygonOpen, setPolygonOpen] = React.useState(false);

  const [areaDetails, setAreaDetails] = React.useState({
    plot_type: 2,
    area: "",
    cost: "",
    no_of_sides: 4,
    side1: "",
    side2: "",
    side3: "",
    side4: "",
    side5: "",
    side6: "",
    side7: "",
    side8: "",
    per_s_feet: "",
    is_corner: "",
    back_road: "",
    back_road_feet: "",
    front_road: "",
    front_road_feet: "",
    left_road: "",
    left_road_feet: "",
    right_road: "",
    right_road_feet: "",
    direction: "",
    corner_direction_facing: "",
    gps: "",
    name: `${state.polygon.length + 1}`,
    fill_color: "#7ed321",
  });
  const [showLineColor, setShowLineColor] = React.useState(false);
  const [showFillColor, setShowFillColor] = React.useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(zoom_level);
  const [dragPolygon, setDragPolygon] = React.useState(null);
  const [uploadedPlots, setUploadedPlots] = React.useState([]);
  const [uploadedPlotId, setUploadedPlotId] = React.useState(null);
  const polyRef = useRef(null);

  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const [path, setPath] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setZoomLevel(zoom_level);
  }, [zoom_level]);

  useEffect(() => {
    if (areaDetails) {
      if (areaDetails.plot_status === 0) {
        setAreaDetails({
          ...areaDetails,
          fill_color: "#26BD26",
        });
      } else if (areaDetails.plot_status === 1) {
        setAreaDetails({
          ...areaDetails,
          fill_color: "#F13030",
        });
      } else if (areaDetails.plot_status === 2) {
        setAreaDetails({
          ...areaDetails,
          fill_color: "#3088F1",
        });
      }
    }
  }, [areaDetails && areaDetails.plot_status]);

  useEffect(() => {
    if (areaDetails) {
      if (areaDetails.plot_type === 5) {
        setAreaDetails({
          ...areaDetails,
          fill_color: "#0000FF",
        });
      } else if (areaDetails.plot_type === 6) {
        setAreaDetails({
          ...areaDetails,
          fill_color: "#964B00",
        });
      } else {
        setAreaDetails({
          ...areaDetails,
          fill_color: "#26BD26",
        });
      }
    }
  }, [areaDetails && areaDetails.plot_type]);

  useEffect(() => {
    getPlotDetails(id);
    getAllUploadedPlots(id);
  }, []);

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post("realestate/plots/list", payload).then(
      (res) => {
        setIsLoading(false);
        setState({
          ...state,
          polygon: (res.data && res.data.plots) || [],
        });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  useEffect(() => {
    if (areaDetails && areaDetails.area && areaDetails.per_s_feet) {
      setAreaDetails({
        ...areaDetails,
        cost: Math.round(
          parseInt(areaDetails.per_s_feet) * parseInt(areaDetails.area)
        ),
      });
    } else {
      setAreaDetails({
        ...areaDetails,
        cost: 0,
      });
    }
  }, [areaDetails && areaDetails.area, areaDetails && areaDetails.per_s_feet]);

  useEffect(() => {
    console.log("zoomLevel", zoomLevel);
  }, [zoomLevel]);

  const handleClickOpen = (data) => {
    if (!data.plot_type) {
      data.plot_type = 2;
    }
    setAreaDetails({ ...data, isEdit: true });
    setOpen(true);
  };

  const handlePolygonClick = (data, i) => {
    setAreaDetails({ ...data, isEdit: false });
    setPolygonOpen(true);
  };

  const handleRedraw = (el) => {
    if (dragPolygon === el.id) {
      updatePath(path, el.id);
      setDragPolygon(null);
    } else {
      setAreaDetails({ ...el, isEdit: true });
      setDragPolygon(el.id);
    }
  };

  const updatePath = (path, id) => {
    if (path && path.length > 0) {
      updatePlots({ ...areaDetails, gps: path });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setDragPolygon(null);
    setAreaDetails({
      plot_type: 2,
      isEdit: false,
    });
  };

  const handleChangeComplete = (color, key) => {
    setAreaDetails({ ...areaDetails, [key]: color.hex });
    setShowLineColor(false);
    setShowFillColor(false);
  };

  const handleChange = (key, value) => {
    setAreaDetails({ ...areaDetails, [key]: value });
  };

  const handleUpdate = () => {
    const stateCopy = JSON.parse(JSON.stringify(state));
    if (!areaDetails.no_of_sides) {
      areaDetails.no_of_sides = 4;
    }
    updatePlots(areaDetails);
    stateCopy.zoom_level = zoomLevel;
    setState(stateCopy);
    setAreaDetails({
      ...areaDetails,
      plot_type: 2,
      isEdit: false,
    });
    setOpen(false);
    setUploadedPlotId(null);
  };

  const handleRemoveArea = (plotId) => {
    setIsLoading(true);
    const payload = { id: plotId };
    XHR.post("realestate/plots/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          getPlotDetails(id);
          handleSnackClick();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAllPolygon = (currentPolygon) => {
    let newPolygon = [];
    for (let i = 0; i < currentPolygon.length; i++) {
      newPolygon.push({
        lat: currentPolygon[i].lat(),
        lng: currentPolygon[i].lng(),
      });
    }
    handleAddPolygon({
      gps: newPolygon,
      name: `${state.polygon.length + 1}`,
      fill_color: "#7ed321",
      area: "",
      cost: "",
      no_of_sides: 4,
      side1: "",
      side2: "",
      side3: "",
      side4: "",
      side5: "",
      side6: "",
      side7: "",
      side8: "",
      per_s_feet: "",
      plot_type: 2,
      is_corner: "",
      back_road: "",
      back_road_feet: "",
      front_road: "",
      front_road_feet: "",
      left_road: "",
      left_road_feet: "",
      right_road: "",
      right_road_feet: "",
      direction: "",
      corner_direction_facing: "",
      plot_status: 0,
      isEdit: false,
    });
  };

  const handleAddPolygon = (data) => {
    setIsLoading(true);
    const payload = { ...data, project_id: id };
    XHR.post("realestate/plots/add", payload).then(
      (res) => {
        setIsLoading(false);
        getPlotDetails(id);
        handleSnackClick();
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updatePlots = (data) => {
    setIsLoading(true);
    const payload = { ...data, project_id: id };
    XHR.post("realestate/plots/update", payload).then(
      (res) => {
        setIsLoading(false);
        getPlotDetails(id);
        handleSnackClick();
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const overlay = (e) => {
    switch (e.type) {
      case "marker":
        setState({
          ...state,
          marker: {
            lat: e.overlay.getPosition().lat(),
            lng: e.overlay.getPosition().lng(),
          },
        });
        e.overlay.setMap(null);
        break;
      case "polyline":
        setState({
          ...state,
          polyline: e.overlay.getPath(),
        });
        e.overlay.setMap(null);
        break;
      case "circle":
        setState({
          ...state,
          circleRadius: e.overlay.getRadius(),
          circleCenter: e.overlay.getCenter(),
        });
        e.overlay.setMap(null);
        break;
      case "rectangle":
        setState({
          ...state,
          rectangle: e.overlay.getBounds(),
        });
        e.overlay.setMap(null);
        break;
      case "polygon":
        getAllPolygon(e.overlay.getPath().getArray());
        e.overlay.setMap(null);
        break;
    }
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  // Set the ref
  const onPolygonLoad = (polygon) => {
    // setPolygonRef(polygon);
  };

  // Update the polygon
  const onPolygonMouseUp = () => onPolygonComplete(polyRef);

  // When first drawing is complete and edit has been made, this function is run
  const onPolygonComplete = (polygon, destroy = false) => {
    // Destroys the polygon that has been drawn by the manager.
    if (destroy) {
      polygon.setMap(null);
    }
  };

  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);
    }
  }, [setPath]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      if (listenersRef && listenersRef.current) {
        listenersRef.current.push(
          path.addListener("set_at", onEdit),
          path.addListener("insert_at", onEdit),
          path.addListener("remove_at", onEdit)
        );
      }
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const getRupeesFormat = (amountValue = "") => {
    return (amountValue || "").toLocaleString("en-IN");
  };

  const getSoldStatus = (type) => {
    switch (type) {
      case 1:
        return "Sold";
      case 2:
        return "Booked";
      case 0:
        return "Un Sold";
      case 1:
        return "";
    }
  };

  const getPlotType = (type) => {
    switch (type) {
      case 1:
        return "Street";
      case 2:
        return "Plot";
      case 3:
        return "Shop";
      case 4:
        return "Park";
      case 5:
        return "Road";
      case 6:
        return "Commercial";
      default:
        return "";
    }
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleDrawImage = (id) => {
    window.open(`${redirectUrl}/overlay/?id=${id}`, "_blank");
  };

  const handlePlotChange = (selectedPlot) => {
    setIsLoading(true);
    setUploadedPlotId(selectedPlot);
    const payload = { id: selectedPlot };
    XHR.post("realestate/plots/getuploadedplot", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.data && res.data.data.id) {
          delete res.data.data.id;
        }
        setAreaDetails({
          ...areaDetails,
          ...res.data.data,
          uploaded_id: selectedPlot,
        });
        // setUploadedPlots((res.data && res.data.data) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAllUploadedPlots = (id) => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post("realestate/plots/uploadedlist", payload).then(
      (res) => {
        setIsLoading(false);
        setUploadedPlots((res.data && res.data.plots) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          <span style={{ fontWeight: "bold", fontSize: 20 }}>
            Project - {name}
          </span>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          {areaDetails && areaDetails.isEdit ? (
            <>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={() => handleRedraw({ id: dragPolygon })}
                color="primary"
              >
                Save
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={() => handleClose()}
                color="primary"
              >
                Cancel
              </Button>
            </>
          ) : null}
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => handleDrawImage(row.id)}
            color="primary"
          >
            Draw with Image
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => history.goBack()}
            color="primary"
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={7} style={{ marginLeft: 10, marginRight: 10 }}>
          <DrawMaps
            overlay={overlay}
            onClick={() => {}}
            isProject={false}
            onPolygonClick={(el, i) => handlePolygonClick(el, i)}
            editable={true}
            gps_location={gps_location}
            zoom_level={zoomLevel}
            polygon={state.polygon}
            polyline={polyline}
            onZoomChanged={(val) => setZoomLevel(val)}
            dragPolygon={dragPolygon}
            onPolygonLoad={onPolygonLoad}
            onPolygonMouseUp={onPolygonMouseUp}
            polyRef={polyRef}
            onEdit={onEdit}
            onMouseUp={onEdit}
            onUnmount={onUnmount}
            onLoad={onLoad}
            path={path}
          />
        </Grid>
        <Grid item xs={4} style={{ marginTop: 10, marginBottom: 10 }}>
          <div>
            <div>List of Added Areas</div>
          </div>
          <List style={{ height: 600, overflowY: "auto" }}>
            {state.polygon &&
              state.polygon
                .filter((el) => el.drawn_type === 1)
                .map((el, i) => (
                  <>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={`${i + 1}. ${el.name} (${getSoldStatus(
                          el.plot_status
                        )}) (${getPlotType(el.plot_type)})`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="edit"
                          className={classes.margin}
                          size="small"
                          onClick={() => handleClickOpen(el, i)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="redraw"
                          className={classes.margin}
                          size="small"
                          style={{
                            color: dragPolygon === el.id ? "red" : null,
                          }}
                          onClick={() =>
                            handleRedraw(
                              el,
                              dragPolygon === el.id ? true : false
                            )
                          }
                        >
                          <AppsIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          className={classes.margin}
                          onClick={() => handleRemoveArea(el.id)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </>
                ))}
            {(!state.polygon || !state.polygon.length) && (
              <ListItem alignItems="flex-start">
                <ListItemText primary={`No Areas Added`} />
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        fullWidth
        size="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Area</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{ fontWeight: "bold", color: "#0000FF", fontSize: 18 }}
              >
                LAND TYPE
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={(areaDetails && areaDetails.plot_type) || 2}
                  onChange={(e) => handleChange("plot_type", e.target.value)}
                >
                  <MenuItem value={2}>Plot</MenuItem>
                  <MenuItem value={3}>Shop</MenuItem>
                  <MenuItem value={4}>Park</MenuItem>
                  <MenuItem value={5}>Road</MenuItem>
                  <MenuItem value={6}>Commercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}>
              <span>Line color</span>
              <div
                onClick={() => setShowLineColor(true)}
                style={{
                  padding: 10,
                  width: 50,
                  background:
                    areaDetails && areaDetails.line_color
                      ? areaDetails.line_color
                      : "#fff",
                  border: `1px solid ${
                    areaDetails && areaDetails.line_color
                      ? areaDetails.line_color
                      : "#000"
                  }`,
                }}
              />

              {showLineColor && (
                <SketchPicker
                  color={
                    areaDetails && areaDetails.line_color
                      ? areaDetails.line_color
                      : "#000"
                  }
                  onChangeComplete={(val) =>
                    handleChangeComplete(val, "line_color")
                  }
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <span>Fill color</span>
              <div
                onClick={() => setShowFillColor(true)}
                style={{
                  padding: 10,
                  width: 50,
                  background:
                    areaDetails && areaDetails.fill_color
                      ? areaDetails.fill_color
                      : "#7ed321",
                  border: `1px solid ${
                    areaDetails && areaDetails.fill_color
                      ? areaDetails.fill_color
                      : "#000"
                  }`,
                }}
              />

              {showFillColor && (
                <SketchPicker
                  color={
                    areaDetails && areaDetails.fill_color
                      ? areaDetails.fill_color
                      : "#000"
                  }
                  onChangeComplete={(val) =>
                    handleChangeComplete(val, "fill_color")
                  }
                />
              )}
            </Grid> */}
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{ fontWeight: "bold", color: "#0000FF", fontSize: 18 }}
              >
                AREA
              </span>
            </Grid>
            {areaDetails && parseInt(areaDetails.plot_type) != 5 ? (
              <>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Select Plot
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        uploadedPlotId ||
                        (areaDetails && areaDetails.uploaded_id)
                      }
                      onChange={(e) => handlePlotChange(e.target.value)}
                    >
                      {uploadedPlots.map((el, i) => (
                        <MenuItem key={i} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    value={(areaDetails && areaDetails.name) || ""}
                    onChange={(e) => handleChange("name", e.target.value)}
                    margin="dense"
                    id="name"
                    label="Plot No"
                    disabled={true}
                    type="name"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={Math.round(areaDetails && areaDetails.area) || ""}
                    margin="dense"
                    id="area"
                    disabled={true}
                    label="Total Area in sq feet"
                    type="text"
                    fullWidth
                    onChange={(e) => handleChange("area", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`₹${
                      getRupeesFormat(areaDetails && areaDetails.per_s_feet) ||
                      ""
                    }`}
                    margin="dense"
                    id="per_s_feet"
                    disabled={true}
                    label="Rate Per sq feet"
                    type="per_s_feet"
                    fullWidth
                    onChange={(e) => handleChange("per_s_feet", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`₹${
                      getRupeesFormat(areaDetails && areaDetails.cost) || ""
                    }`}
                    margin="dense"
                    id="cost"
                    label="Total Cost"
                    disabled={true}
                    type="text"
                    fullWidth
                    onChange={(e) => handleChange("cost", e.target.value)}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={6}>
                <TextField
                  value={(areaDetails && areaDetails.area) || ""}
                  margin="dense"
                  id="area"
                  label="Total Area in sq feet"
                  type="text"
                  fullWidth
                  onChange={(e) => handleChange("area", e.target.value)}
                />
              </Grid>
            )}
            {areaDetails && parseInt(areaDetails.plot_type) != 5 && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    // borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    PLOT SALES STATUS
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Plot Status</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    disabled={
                      areaDetails && areaDetails.uploaded_id ? false : true
                    }
                    value={(areaDetails && areaDetails.plot_status) || 0}
                    onChange={(e) =>
                      handleChange("plot_status", e.target.value)
                    }
                  >
                    <MenuItem value={1}>Sold</MenuItem>
                    <MenuItem value={0}>Unsold</MenuItem>
                    <MenuItem value={2}>Booked</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    // borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    CORNER PLOT
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset" style={{ marginTop: 10 }}>
                    <FormLabel component="legend">Is Corner Plot</FormLabel>
                    <RadioGroup
                      row
                      aria-label="is_corner"
                      name="is_corner"
                      disabled={true}
                      value={
                        areaDetails && areaDetails.is_corner
                          ? areaDetails.is_corner
                          : "no"
                      }
                      onChange={(e) =>
                        handleChange("is_corner", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        disabled
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        disabled
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Plot Direction Facing
                    </InputLabel>
                    <Select
                      disabled={true}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={(areaDetails && areaDetails.direction) || ""}
                      onChange={(e) =>
                        handleChange("direction", e.target.value)
                      }
                    >
                      <MenuItem value={1}>East</MenuItem>
                      <MenuItem value={2}>West</MenuItem>
                      <MenuItem value={3}>North</MenuItem>
                      <MenuItem value={4}>South</MenuItem>
                      <MenuItem value={5}>North East</MenuItem>
                      <MenuItem value={6}>North West</MenuItem>
                      <MenuItem value={7}>South East</MenuItem>
                      <MenuItem value={8}>South West</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    NO OF SIDES
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={(areaDetails && areaDetails.no_of_sides) || ""}
                    margin="dense"
                    id="no_of_sides"
                    label="Number of Sides"
                    type="no_of_sides"
                    disabled={true}
                    fullWidth
                    onChange={(e) =>
                      handleChange("no_of_sides", e.target.value)
                    }
                  />
                </Grid>
                {areaDetails && areaDetails.no_of_sides > 0 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side1) || ""}
                      margin="dense"
                      id="direction"
                      disabled={true}
                      label="Side A"
                      type="side1"
                      fullWidth
                      onChange={(e) => handleChange("side1", e.target.value)}
                    />
                  </Grid>
                )}
                {areaDetails && areaDetails.no_of_sides > 1 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled={true}
                      value={(areaDetails && areaDetails.side2) || ""}
                      margin="dense"
                      id="direction"
                      label="Side B"
                      type="side2"
                      fullWidth
                      onChange={(e) => handleChange("side2", e.target.value)}
                    />
                  </Grid>
                )}
                {areaDetails && areaDetails.no_of_sides > 2 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled={true}
                      value={(areaDetails && areaDetails.side3) || ""}
                      margin="dense"
                      id="direction"
                      label="Side C"
                      type="side3"
                      fullWidth
                      onChange={(e) => handleChange("side3", e.target.value)}
                    />
                  </Grid>
                )}
                {areaDetails && areaDetails.no_of_sides > 3 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled={true}
                      value={(areaDetails && areaDetails.side4) || ""}
                      margin="dense"
                      id="direction"
                      label="Side D"
                      type="side4"
                      fullWidth
                      onChange={(e) => handleChange("side4", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 4 && (
                  <Grid item xs={6}>
                    <TextField
                      disabled={true}
                      value={(areaDetails && areaDetails.side5) || ""}
                      margin="dense"
                      id="direction"
                      label="Side E"
                      type="side5"
                      fullWidth
                      onChange={(e) => handleChange("side5", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 5 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side6) || ""}
                      margin="dense"
                      id="direction"
                      label="Side F"
                      type="side6"
                      fullWidth
                      onChange={(e) => handleChange("side6", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 6 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side7) || ""}
                      margin="dense"
                      id="direction"
                      label="Side G"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side7", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 7 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side8) || ""}
                      margin="dense"
                      id="direction"
                      label="Side H"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side8", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 8 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side9) || ""}
                      margin="dense"
                      id="direction"
                      label="Side I"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side9", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 9 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side10) || ""}
                      margin="dense"
                      id="direction"
                      label="Side J"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side10", e.target.value)}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    ROAD
                  </span>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Front</FormLabel>
                      <RadioGroup
                        row
                        aria-label="front_road"
                        disabled={true}
                        name="front_road"
                        value={
                          areaDetails && areaDetails.front_road
                            ? areaDetails.front_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("front_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          disabled
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          disabled
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.front_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        disabled={true}
                        value={
                          (areaDetails && areaDetails.front_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Front Road Length (In Feet)"
                        type="front_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("front_road_feet", e.target.value)
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Back</FormLabel>
                      <RadioGroup
                        disabled={true}
                        row
                        aria-label="back_road"
                        name="back_road"
                        value={
                          areaDetails && areaDetails.back_road
                            ? areaDetails.back_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("back_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          disabled
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          disabled
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.back_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        disabled={true}
                        value={
                          (areaDetails && areaDetails.back_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Back Road Length (In Feet)"
                        type="back_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("back_road_feet", e.target.value)
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Left</FormLabel>
                      <RadioGroup
                        disabled={true}
                        row
                        aria-label="left_road"
                        name="left_road"
                        value={
                          areaDetails && areaDetails.left_road
                            ? areaDetails.left_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("left_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          disabled
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          disabled
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.left_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        disabled={true}
                        value={
                          (areaDetails && areaDetails.left_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Left Road Length (In Feet)"
                        type="left_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("left_road_feet", e.target.value)
                        }
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Right</FormLabel>
                      <RadioGroup
                        disabled={true}
                        row
                        aria-label="right_road"
                        name="right_road"
                        value={
                          areaDetails && areaDetails.right_road
                            ? areaDetails.right_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("right_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          disabled
                          control={<Radio color="primary" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          disabled
                          control={<Radio color="primary" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.right_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        disabled={true}
                        value={
                          (areaDetails && areaDetails.right_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Right Road Length (In Feet)"
                        type="right_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("right_road_feet", e.target.value)
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {/* <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{ fontWeight: "bold", color: "#0000FF", fontSize: 18 }}
              >
                DIRECTION FACING
              </span>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Direction Facing
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={(areaDetails && areaDetails.direction) || ""}
                  onChange={(e) => handleChange("direction", e.target.value)}
                >
                  <MenuItem value={1}>East</MenuItem>
                  <MenuItem value={2}>West</MenuItem>
                  <MenuItem value={3}>North</MenuItem>
                  <MenuItem value={4}>South</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={polygonOpen}
        size="md"
        fullWidth
        onClose={() => {
          setPolygonOpen(false);
          setAreaDetails({
            ...areaDetails,
            isEdit: false,
          });
        }}
      >
        <DialogTitle id="form-dialog-title">Choose Edit</DialogTitle>
        <DialogContent>
          <Button
            onClick={() => {
              setPolygonOpen(false);
              handleRedraw(areaDetails);
            }}
            color="primary"
            variant="contained"
          >
            Edit Polygon
          </Button>
          <Button
            onClick={() => {
              setPolygonOpen(false);
              handleClickOpen(areaDetails);
            }}
            color="primary"
            variant="contained"
            style={{ marginLeft: 10 }}
          >
            Edit Details
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAreaDetails({
                ...areaDetails,
                isEdit: false,
              });
              setPolygonOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message="Success"
        variant="success"
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </div>
  );
};

export default Map;
