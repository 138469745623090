import React, { useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Polyline,
  Marker,
  DirectionsRenderer,
  InfoWindow,
} from "react-google-maps";

import ErrorBoundary from "./ErrorBoundary";

// import PlotTooltip from "../DrawPlot/tooltip";
import "./DrawPlot/poly.css";
const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const { compose, withState, withHandlers, lifecycle } = require("recompose");

const getCenter = (arr) => {
  var x = arr.map((x) => x["lat"]);
  var y = arr.map((x) => x["lng"]);
  var cx = (Math.min(...x) + Math.max(...x)) / 2;
  var cy = (Math.min(...y) + Math.max(...y)) / 2;
  return { lat: cx, lng: cy };
};

const labelSize = { width: 220 };

const DrawMaps = compose(
  withState("zooms", "onZoomChange", 7),
  withState("lats", "onLatChange", 11.127123),
  withState("lngs", "onLngChange", 78.656891),
  withState("directions", "onDirectionsChange", null),
  withState("showInfo", "setShowInfo", false),
  withState("infoWindow", "setInfoWindow", null),
  withHandlers((props) => {
    const refs = {
      map: undefined,
    };
    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged: (props) => (e) => {
        props.onZoomChanged(refs.map.getZoom());
      },
      fitBounds: (props) => (e) => {
        // console.log("window.google", window.google);
        if (window.google) {
          const bounds = new window.google.maps.LatLngBounds();
          const locations =
            (props.appLocation &&
              JSON.parse(JSON.stringify(props.appLocation || []))) ||
            [];
          locations.map((item) => {
            bounds.extend(item.gps);
            return item.id;
          });
          console.log("locations", locations);
          if (locations && locations.length === 1) {
            refs.map &&
              refs.map.panTo(
                new window.google.maps.LatLng(
                  locations[0]["gps"]["lat"],
                  locations[0]["gps"]["lng"]
                )
              );
          } else {
            // refs.map && refs.map.fitBounds(bounds);
          }
        }
      },
      getRupeesFormat:
        () =>
        (amountValue = "") => {
          return (amountValue || "").toLocaleString("en-IN");
        },
      getDirections:
        ({ onDirectionsChange }) =>
        (origin, destination) => {
          const directionsService = new window.google.maps.DirectionsService();

          if (origin !== null && destination !== null) {
            directionsService.route(
              {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
              },
              (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                  const myRoute = result.routes[0].legs[0];
                  onDirectionsChange(result);
                  props.setDirectionDetails &&
                    props.setDirectionDetails({
                      from: myRoute.start_address,
                      to: myRoute.end_address,
                      distance: myRoute.distance.text,
                      minutes: myRoute.duration.text,
                    });
                } else {
                  onDirectionsChange(null);
                  props.setDirectionDetails && props.setDirectionDetails(null);
                }
              }
            );
          } else {
            onDirectionsChange(null);
            props.setDirectionDetails && props.setDirectionDetails(null);
          }
        },
      onMapClick:
        ({ onLatChange, onLngChange }) =>
        (e) => {
          onLatChange(e.latLng.lat());
          onLngChange(e.latLng.lng());
        },
      getFillColor: () => (status) => {
        switch (parseInt(status)) {
          case 0:
            return "#26BD26";
          case 1:
            return "#F13030";
          case 2:
            return "#3088F1";
          default:
            return "transparent";
        }
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.onZoomChange(this.props.zoom);
      this.props.onLatChange(this.props.lat);
      this.props.onLngChange(this.props.lng);
    },
    componentDidUpdate() {
      if (this.props.appLocation && this.props.appLocation.length > 0) {
        this.props.fitBounds(this.props.appLocation);
      }
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <ErrorBoundary>
    <div>
      <GoogleMap
        defaultCenter={props.gps_location}
        center={props.gps_location}
        defaultZoom={props.zoom_level || 20}
        mapTypeId={props.type ? props.type : "satellite"}
        mapTypeControl={false}
        ref={props.onMapMounted}
      >
        {props.plots &&
          props.plots !== null &&
          props.plots.map((path, i) => (
            <Polygon
              key={i}
              paths={path.gps}
              options={{
                fillColor: props.getFillColor(path.plot_status),
                strokeColor: "#000",
                strokeWeight: 1,
              }}
              onClick={(event) => props.onPolygonClick(path)}
              onMouseOver={(e) => {
                console.log("2");

                props.setShowInfo(true);
                props.setInfoWindow(path);
              }}
              onMouseOut={(e) => {
                console.log("3");
                props.setShowInfo(false);
                props.setInfoWindow(null);
              }}
            />
          ))}

        {props.show_boundary &&
          props.polyline &&
          props.polyline !== null &&
          props.polyline.map((path, i) => (
            <Polyline
              key={i}
              path={path.gps}
              options={{
                fillColor: props.getFillColor(path.plot_status),
                strokeColor: path.line_color
                  ? path.line_color
                  : props.boundary_color,
                strokeWeight: 3,
              }}
              onMouseover={(e) => {
                // console.log(path.name);
              }}
              onMouseout={(e) => {
                // console.log(path.name);
              }}
            />
          ))}

        {/* {props.plots &&
        props.plots !== null &&
        props.plots.map((path, i) => (
          <MarkerWithLabel
            labelStyle={{
              textAlign: "center",
              fontSize: "12px",
            }}
            labelClass="map-label"
            labelAnchor={{ x: labelSize.width / 10, y: 15 }}
            key={i}
            icon={" "}
            position={getCenter(path.gps)}
          >
            <span>{path.name}</span>
          </MarkerWithLabel>
        ))} */}

        <Marker position={props.gps_location} label="H" />
        {console.log("location", props.appLocation)}
        {props.appLocation &&
          props.appLocation !== null &&
          props.appLocation.map((el, i) => (
            <Marker
              key={i}
              position={el.gps}
              icon={{
                url: el.icon || null,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              onClick={(event) => props.onMarkerClick(el.data)}
              onMouseOver={(e) => {
                console.log("2");

                props.setShowInfo(true);
                props.setInfoWindow(el);
              }}
              onMouseOut={(e) => {
                console.log("3");
                props.setShowInfo(false);
                props.setInfoWindow(null);
              }}
            />
          ))}

        {props.exactLocations &&
          props.exactLocations !== null &&
          props.exactLocations.map((el, i) => (
            <Marker
              key={i}
              position={el.gps}
              icon={{
                url: el.icon || null,
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              onClick={(event) => props.onMarkerClick(el.data)}
              onMouseOver={(e) => {
                console.log("2");

                props.setShowInfo(true);
                props.setInfoWindow(el);
              }}
              onMouseOut={(e) => {
                console.log("3");
                props.setShowInfo(false);
                props.setInfoWindow(null);
              }}
            />
          ))}

        {props.directions !== null && props.directionDetails != null && (
          <DirectionsRenderer
            directions={props.directions}
            defaultOptions={{
              suppressMarkers: true,
              polylineOptions: { strokeColor: "#0000FF" },
            }}
          />
        )}
        {/* {console.log("props.infoWindow.gps", props.infoWindow)} */}
        {props.showInfo && props.infoWindow && (
          <InfoWindow
            position={props.infoWindow.gps}
            // onCloseClick={() => {}}
            style={{ padding: 0 }}
          >
            <div style={{ width: 300 }}>
              <div className="tooltip-header">
                <h3>
                  <span>Rera Id: {props.infoWindow.data.rera_id}</span>
                </h3>
                <h3>
                  <span>No of Plots: {props.infoWindow.data.no_of_plots}</span>
                </h3>
                <h3>
                  <span>Layout Name: {props.infoWindow.data.project_name}</span>
                </h3>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  </ErrorBoundary>
));

export default DrawMaps;
