import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import XHR from "../../util/xhr";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default function Companies() {
  const classes = useStyles();
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [actionType, setActionType] = useState(null);

  const [showAction, setShowAction] = useState(false);
  const [toast, setToast] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [value, setValue] = React.useState(0);

  const proColumns = [
    { name: "sno", title: "S.No" },
    { name: "name", title: "Name" },
    { name: "email", title: "Email" },
    { name: "contact", title: "Contact" },
    { name: "approve_status", title: "Approved Status" },
    { name: "ts", title: "Registered On" },
    { name: "action", title: "Action" },
  ];

  const userColumns = [
    { name: "sno", title: "S.No" },
    { name: "name", title: "Name" },
    { name: "email", title: "Email" },
    { name: "contact", title: "Contact" },
    { name: "ts", title: "Registered On" },
    { name: "action", title: "Action" },
  ];

  const [columns, setColumns] = useState(proColumns);

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    setColumns(!value ? proColumns : userColumns);
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllCompanies = () => {
    setIsLoading(true);

    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;

    if (userDetails && userDetails.user_id) {
      const payload = { user_id: (userDetails && userDetails.user_id) || 0 };
      XHR.post("realestate/company/list", payload).then(
        (res) => {
          setIsLoading(false);
          setCompanies((res.data && res.data.companies) || []);
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
    }
  };

  const handleEditClick = (el) => {
    setUpdateData(el);
    setShowAction(true);
    setActionType("E");
  };

  const handleAddClick = () => {
    setUpdateData(null);
    setShowAction(true);
    setActionType("A");
  };

  const handleViewClick = (el) => {
    setUpdateData(el);
    setIsView(true);
    setActionType("V");
  };

  const handleDeleteClick = (el) => {
    setUpdateData(el);
    setIsDelete(true);
    setActionType("D");
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData };
    XHR.post("realestate/company/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleSnackClick();
          getAllCompanies();
          setToast({
            message: "Success",
            variant: "success",
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleApprove = (id, status) => {
    setIsLoading(true);
    const payload = { id, status };
    XHR.post("realestate/company/approve", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleSnackClick();
          getAllCompanies();
          setToast({
            message: "Success",
            variant: "success",
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleConvert = (id, type) => {
    setIsLoading(true);
    const payload = { id, type };
    XHR.post("realestate/company/change", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleSnackClick();
          getAllCompanies();
          setToast({
            message: "Success",
            variant: "success",
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleApprovePromotor = (id, is_approved) => {
    setIsLoading(true);
    const payload = { id, is_approved };
    XHR.post("realestate/company/approveuser", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleSnackClick();
          getAllCompanies();
          setToast({
            message: "Success",
            variant: "success",
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const getData = (type) => {
    return (companies || []).filter((el) => el.type === type);
  };

  const handleInputChange = (key, value) => {
    setUpdateData({
      ...updateData,
      [key]: value,
    });
  };

  const handleSave = (id, type) => {
    if (
      !updateData ||
      !updateData.name ||
      !updateData.email ||
      !updateData.contact ||
      !updateData.ori_password
    ) {
      setToast({
        message: "Enter All Fields",
        variant: "error",
      });
      handleSnackClick();
      return;
    }
    setIsLoading(true);
    const payload = { ...updateData, user_id: getUserId() };
    XHR.post("realestate/promoters/useradd", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          setShowAction(false);
          setUpdateData(null);
          handleSnackClick();
          getAllCompanies();
          setToast({
            message: "Success",
            variant: "success",
          });
        } else {
          setToast({
            message: "User Already Exists",
            variant: "error",
          });
          handleSnackClick();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getUserId = () => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;
    return (userDetails && userDetails.user_id) || companyId;
  };

  const viewBtn = ({ row }) => (
    <div>
      <Tooltip title="View User Details">
        <VisibilityIcon
          onClick={() => handleViewClick(row)}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
      {row.type === "P" && row.is_approved === 0 ? (
        <Tooltip title="Approve User">
          <LockIcon
            onClick={() => handleApprovePromotor(row.id, 1)}
            style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
          />
        </Tooltip>
      ) : row.type === "P" ? (
        <Tooltip title="UnApprove User">
          <LockOpenIcon
            onClick={() => handleApprovePromotor(row.id, 0)}
            style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
          />
        </Tooltip>
      ) : null}
      <Tooltip title="Delete User">
        <DeleteIcon
          onClick={() => handleDeleteClick(row.id)}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
      {/* <Tooltip
        title={
          row.type === "P" ? "Change to Normal User" : "Change to Promoter"
        }
      >
        <ReplayIcon
          color={row.type === "P" ? "secondary" : "primary"}
          onClick={() => handleConvert(row.id, row.type === "P" ? "N" : "P")}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip> */}
      <Tooltip title={"Edit User"}>
        <EditIcon
          onClick={() => handleEditClick(row)}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    </div>
  );

  const renderTab = (data) => {
    return (
      <Paper>
        <DataGrid rows={data} columns={columns}>
          <SortingState
            defaultSorting={[]}
            columnExtensions={sortingStateColumnExtensions}
          />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={filteringStateColumnExtensions}
          />
          <IntegratedFiltering />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <DataTypeProvider
            for={["action"]}
            formatterComponent={(row) => {
              return viewBtn(row);
            }}
          />
          <Table />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} />
          <TableFilterRow />
        </DataGrid>
      </Paper>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "right", marginBottom: 10 }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => handleAddClick()}
          color="primary"
        >
          Add User
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => history.replace("/", {})}
          color="primary"
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              label={`Promoters (${getData("P").length})`}
              {...a11yProps(0)}
            />
            <Tab
              label={`Normal User (${getData("N").length})`}
              {...a11yProps(1)}
            />
            <Tab
              label={`Franchise User (${getData("F").length})`}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {renderTab(getData("P"))}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {renderTab(getData("N"))}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {renderTab(getData("F"))}
        </TabPanel>

        <Dialog
          open={isDelete}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
          <DialogContent>Delete this item?</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmDelete()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isView}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Details</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <span style={{ marginRight: 10 }}>Name:</span>
                <span>{(updateData && updateData.name) || ""}</span>
              </Grid>
              <Grid item xs={12}>
                <span style={{ marginRight: 10 }}>Email:</span>
                <span>{(updateData && updateData.email) || ""}</span>
              </Grid>
              <Grid item xs={12}>
                <span style={{ marginRight: 10 }}>Contact:</span>
                <span>{(updateData && updateData.contact) || ""}</span>
              </Grid>
              {updateData && updateData.type === "P" && (
                <>
                  {" "}
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Company Name:</span>
                    <span>{(updateData && updateData.company) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Address Line 1:</span>
                    <span>{(updateData && updateData.address1) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Address Line 2:</span>
                    <span>{(updateData && updateData.address2) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Street Name:</span>
                    <span>{(updateData && updateData.street) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>City:</span>
                    <span>{(updateData && updateData.city) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Taluk:</span>
                    <span>{(updateData && updateData.taluk) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>District:</span>
                    <span>{(updateData && updateData.district) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>State:</span>
                    <span>{(updateData && updateData.state) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Zipcode:</span>
                    <span>{(updateData && updateData.zipcode) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Country:</span>
                    <span>{(updateData && updateData.country) || ""}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <span style={{ marginRight: 10 }}>Landmark:</span>
                    <span>{(updateData && updateData.landmark) || ""}</span>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsView(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message={(toast && toast.message) || "Success"}
          variant={(toast && toast.variant) || "success"}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "45%",
              left: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress thickness={"3"} size="4rem" />
          </div>
        )}
      </Grid>
      <Dialog
        open={showAction}
        size="md"
        fullWidth
        onClose={() => {
          setUpdateData(null);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">User Details</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(updateData && updateData.name) || ""}
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(updateData && updateData.email) || ""}
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(updateData && updateData.contact) || ""}
                margin="dense"
                id="contact"
                label="Mobile"
                type="text"
                fullWidth
                onChange={(e) => handleInputChange("contact", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(updateData && updateData.ori_password) || ""}
                margin="dense"
                id="ori_password"
                label="Password"
                type="text"
                fullWidth
                onChange={(e) =>
                  handleInputChange("ori_password", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <RadioGroup
                aria-label="type"
                name="type"
                value={(updateData && updateData.type) || "N"}
                onChange={(e) => handleInputChange("type", e.target.value)}
                row
              >
                <FormControlLabel
                  value={"N"}
                  control={<Radio />}
                  label="Normal User"
                />
                <FormControlLabel
                  value={"P"}
                  control={<Radio />}
                  label="Promoters"
                />
                <FormControlLabel
                  value={"F"}
                  control={<Radio />}
                  label="Franchise"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAction(false)} color="primary">
            Close
          </Button>
          <Button onClick={() => handleSave()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
