import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import AutoComplete from "../../components/AutoComplete";
import DrawMaps from "../../components/DrawMapsNew";
import XHR from "../../util/xhr";
import CircularProgress from '@material-ui/core/CircularProgress';
import API_KEY from "../../util/config";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const Map = (props) => (
  <DrawMaps
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `600px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    gps_location={props.gps_location}
    onClick={props.onMapClick}
    isProject={props.isProject}
    onZoomChanged={props.onZoomChanged}
    zoom_level={props.zoom_level}
    polyline={props.polyline}
    drawingControlEnabled={props.drawingControlEnabled}
  />
);

export default function UpdateAddress() {
  const classes = useStyles();
  const history = useHistory();

  const { row = null } =
    (history && history.location && history.location.state) || {};

  const [projectDetails, setProjectDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("row", row);
    if (row) {
      setTimeout(() => {
        getProjectDetails(row.id);
      }, 1000);
    }
  }, [row]);

  const getProjectDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        setProjectDetails((res.data && res.data.data) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const onMapClick = (e) => {
    // setProjectDetails({
    //   ...projectDetails,
    //   gps_location: {
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng(),
    //   },
    // });
  };

  const onZoomChanged = (zoom) => {
    setProjectDetails({
      ...projectDetails,
      zoom_level: zoom,
    });
  };

  const handleChange = (key, value) => {
    setProjectDetails({
      ...projectDetails,
      [key]: value,
    });
  };

  const handleClickSwap = (from, to) => {
    const projectDetailsCopy = JSON.parse(JSON.stringify(projectDetails));
    let temp = projectDetailsCopy[to];
    projectDetailsCopy[to] = projectDetailsCopy[from];
    projectDetailsCopy[from] = temp;
    setProjectDetails(projectDetailsCopy);
  };

  const handleNext = () => {
    let rowCopy = projectDetails;
    if (projectDetails && projectDetails.id) {
      updateProject(projectDetails);
    } else {
      rowCopy = {
        ...projectDetails,
      };
      addProject(rowCopy);
    }
  };

  const addProject = (data) => {
    setIsLoading(true);
    const payload = { ...data };
    XHR.post("realestate/add", payload).then(
      (res) => {
        setIsLoading(false);
        routeTo("steptwo", {
          row: { ...data, id: res.data && res.data.id },
        });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateProject = (data) => {
    setIsLoading(true);
    const payload = { ...data };
    XHR.post("realestate/update", payload).then(
      (res) => {
        setIsLoading(false);
        routeTo("stepthree", { row: data });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleLocationClick = (gps) => {
    if (gps.lat) {
      var url = "https://maps.google.com/?q=" + gps.lat + "," + gps.lng;
      window.open(url, "_blank");
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={6} lg={6}>
        <span style={{ fontWeight: "bold", fontSize: 20 }}>
          Step 2: Verify Project Address
        </span>
      </Grid>
      <Grid item xs={6} md={6} lg={6} style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => {
            history.replace("stepone", { row });
          }}
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNext()}
          color="primary"
        >
          Save & Next
        </Button>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.street) || ""}
                style={{ width: "80%" }}
                name="street"
                label="Street"
                onChange={(e) => handleChange("street", e.target.value)}
              />
              <IconButton
                aria-label="street"
                onClick={() => handleClickSwap("street", "add1")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.add1) || ""}
                style={{ width: "80%" }}
                name="add1"
                label="Address Line 1"
                onChange={(e) => handleChange("add1", e.target.value)}
              />
              <IconButton
                aria-label="add1"
                onClick={() => handleClickSwap("add1", "add2")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="town"
                onClick={() => handleClickSwap("add1", "street")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.add2) || ""}
                style={{ width: "80%" }}
                name="add2"
                label="Address Line 2"
                onChange={(e) => handleChange("add2", e.target.value)}
              />
              <IconButton
                aria-label="address"
                onClick={() => handleClickSwap("add2", "town")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="town"
                onClick={() => handleClickSwap("add2", "add2")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.town) || ""}
                style={{ width: "80%" }}
                label="village/Town name"
                name="town"
                onChange={(e) => handleChange("town", e.target.value)}
              />
              <IconButton
                aria-label="town"
                onClick={() => handleClickSwap("town", "city")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="town"
                onClick={() => handleClickSwap("town", "add2")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.city) || ""}
                style={{ width: "80%" }}
                label="City"
                name="city"
                onChange={(e) => handleChange("city", e.target.value)}
              />
              <IconButton
                aria-label="city"
                onClick={() => handleClickSwap("city", "taluk")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="city"
                onClick={() => handleClickSwap("city", "town")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.taluk) || ""}
                style={{ width: "80%" }}
                label="Taluk"
                name="taluk"
                onChange={(e) => handleChange("taluk", e.target.value)}
              />
              <IconButton
                aria-label="taluk"
                onClick={() => handleClickSwap("taluk", "district")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="city"
                onClick={() => handleClickSwap("taluk", "city")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.district) || ""}
                style={{ width: "80%" }}
                label="District"
                name="district"
                onChange={(e) => handleChange("district", e.target.value)}
              />
              <IconButton
                aria-label="district"
                onClick={() => handleClickSwap("district", "state")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="city"
                onClick={() => handleClickSwap("district", "taluk")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.state) || ""}
                style={{ width: "80%" }}
                label="State"
                name="state"
                onChange={(e) => handleChange("state", e.target.value)}
              />
              <IconButton
                aria-label="state"
                onClick={() => handleClickSwap("state", "zipcode")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="state"
                onClick={() => handleClickSwap("state", "district")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.zipcode) || ""}
                style={{ width: "80%" }}
                label="zipcode"
                name="zipcode"
                onChange={(e) => handleChange("zipcode", e.target.value)}
              />
              <IconButton
                aria-label="zipcode"
                onClick={() => handleClickSwap("zipcode", "country")}
                className={classes.margin}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="zipcode"
                onClick={() => handleClickSwap("zipcode", "state")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.country) || ""}
                style={{ width: "80%" }}
                label="Country"
                name="country"
                onChange={(e) => handleChange("country", e.target.value)}
              />
              <IconButton
                style={{ marginLeft: 70 }}
                aria-label="country"
                onClick={() => handleClickSwap("country", "zipcode")}
                className={classes.margin}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div>
              <TextField
                value={(projectDetails && projectDetails.landmark) || ""}
                style={{ width: "80%" }}
                label="Landmark"
                name="landmark"
                onChange={(e) => handleChange("landmark", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12} style={{ marginTop: 10 }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleLocationClick(projectDetails.gps_location)}
            >
              <span
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                Mapped Location:{" "}
                {projectDetails && projectDetails.gps_location
                  ? `${projectDetails.gps_location.lat}, ${projectDetails.gps_location.lng}`
                  : ""}
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Map
          gps_location={(projectDetails && projectDetails.gps_location) || null}
          zoom_level={(projectDetails && projectDetails.zoom_level) || 18}
          isProject={true}
          onMapClick={onMapClick}
          onZoomChanged={onZoomChanged}
          drawingControlEnabled={false}
          polyline={(projectDetails && projectDetails.polyline) || []}
        />
      </Grid>
      {
        isLoading && <div
        style={{
          position: "absolute",
          display: "flex",
          top: "45%",
          left: "50%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress thickness={'3'} size="4rem" />
      </div>
      }
    </Grid>
  );
}
