import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { SketchPicker } from "react-color";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";

import AutoComplete from "../../components/AutoComplete";
import DrawMaps from "../../components/DrawMapsNew";
import XHR from "../../util/xhr";
import CircularProgress from "@material-ui/core/CircularProgress";
import API_KEY from "../../util/config";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Map = (props) => (
  <DrawMaps
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `600px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    gps_location={props.gps_location}
    onClick={props.onMapClick}
    isProject={props.isProject}
    onZoomChanged={props.onZoomChanged}
    zoom_level={props.zoom_level}
    overlay={props.overlay}
    polyline={props.polyline}
    show_boundary={props.show_boundary}
    boundary_color={props.boundary_color}
  />
);

export default function PlotProject() {
  const classes = useStyles();
  const history = useHistory();

  const { row = null } =
    (history && history.location && history.location.state) || {};

  const [projectDetails, setProjectDetails] = useState(null);
  const [showFillColor, setShowFillColor] = React.useState(false);
  const [showLineColor, setShowLineColor] = React.useState(false);
  const [shapes, setShapes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    deleteShapes();
  }, [
    projectDetails && projectDetails.polygon,
    projectDetails && projectDetails.polyline,
  ]);

  useEffect(() => {
    if (row) {
      setTimeout(() => {
        getProjectDetails(row.id);
      }, 1000);
    }
  }, [row]);

  const getProjectDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        setProjectDetails((res.data && res.data.data) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const onMapClick = (e) => {
    // setProjectDetails({
    //   ...projectDetails,
    //   gps_location: {
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng(),
    //   },
    // });
  };

  const onZoomChanged = (zoom) => {
    setProjectDetails({
      ...projectDetails,
      zoom_level: zoom,
    });
  };

  const handleReset = () => {
    setProjectDetails({
      ...projectDetails,
      polyline: null,
      polygon: null,
    });
    deleteShapes();
  };

  const deleteShapes = () => {
    shapes.forEach((shape) => shape.setMap(null));
  };

  const handleChange = (key, value) => {
    setProjectDetails({
      ...projectDetails,
      [key]: value,
    });
  };

  const handleChangeComplete = (color, key) => {
    setProjectDetails({ ...projectDetails, [key]: color.hex });
    setShowLineColor(false);
    setShowFillColor(false);
  };

  const handleSwitchChange = (event, key) => {
    setProjectDetails({ ...projectDetails, [key]: event.target.checked?1:0 });
  };

  const handleNext = () => {
    const projectsString = localStorage.getItem("projects") || null;
    let projects = projectsString ? JSON.parse(projectsString) : [];
    if (projectDetails && projectDetails.id) {
      updateProject(projectDetails);
    } else {
      addProject(projectDetails);
    }

    routeTo("/projects", {});
  };

  const addProject = (data) => {
    setIsLoading(true);
    const payload = { ...data };
    XHR.post("realestate/add", payload).then(
      (res) => {
        setIsLoading(false);
        routeTo("/projects", {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateProject = (data) => {
    setIsLoading(true);
    const payload = { ...data };
    XHR.post("realestate/update", payload).then(
      (res) => {
        setIsLoading(false);
        routeTo("/projects", {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const getAllPolygon = (currentPolygon) => {
    let newPolygon = [];
    for (let i = 0; i < currentPolygon.length; i++) {
      newPolygon.push({
        lat: currentPolygon[i].lat(),
        lng: currentPolygon[i].lng(),
      });
    }
    return [{ gps: newPolygon }];
  };

  const overlay = (e) => {
    const shape = e.overlay;
    shape.type = e.type;

    setShapes([...shapes, shape]);

    switch (e.type) {
      case "marker":
        setProjectDetails({
          ...projectDetails,
          gps_location: {
            lat: e.overlay.getPosition().lat(),
            lng: e.overlay.getPosition().lng(),
          },
        });
        break;
      case "polyline":
        setProjectDetails({
          ...projectDetails,
          polyline: getAllPolygon(e.overlay.getPath().getArray()),
        });
        break;
      case "circle":
        setProjectDetails({
          ...projectDetails,
          circleRadius: e.overlay.getRadius(),
          circleCenter: e.overlay.getCenter(),
        });
        break;
      case "rectangle":
        setProjectDetails({
          ...projectDetails,
          rectangle: e.overlay.getBounds(),
        });

        break;
      case "polygon":
        setProjectDetails({
          ...projectDetails,
          polygon: getAllPolygon(e.overlay.getPath().getArray()),
        });
        break;
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={6} lg={6}>
        <span style={{ fontWeight: "bold", fontSize: 20 }}>
          Step 3: Draw Project boundary
        </span>
      </Grid>
      <Grid item xs={6} md={6} lg={6} style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => history.replace("steptwo", { row })}
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNext()}
          color="primary"
        >
          Save & Finish
        </Button>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              value={(projectDetails && projectDetails.name) || ""}
              style={{ width: "100%" }}
              name="name"
              label="Project Name"
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              style={{ width: "100%" }}
              value={(projectDetails && projectDetails.total_area) || ""}
              onChange={(e) => handleChange("total_area", e.target.value)}
              label="Total Area"
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Area Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={(projectDetails && projectDetails.total_area_type) || ""}
                onChange={(e) =>
                  handleChange("total_area_type", e.target.value)
                }
              >
                <MenuItem value={1}>In Acres</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              value={(projectDetails && projectDetails.price) || ""}
              onChange={(e) => handleChange("price", e.target.value)}
              label="Total Price"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              value={(projectDetails && projectDetails.price_per_sqft) || ""}
              onChange={(e) => handleChange("price_per_sqft", e.target.value)}
              label="Price Per SQFT"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              value={(projectDetails && projectDetails.total_plots) || ""}
              onChange={(e) => handleChange("total_plots", e.target.value)}
              label="Total Plots"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={{ width: "100%" }}
              value={
                projectDetails && projectDetails.gps_location
                  ? `${projectDetails.gps_location.lat}, ${projectDetails.gps_location.lng}`
                  : ""
              }
              disabled={true}
              onChange={(e) => handleChange("gps_location", e.target.value)}
              label="GPS Location"
            />
          </Grid>
          <Grid item xs={6}>
            <span>Boundary color</span>
            <div
              onClick={() => setShowLineColor(true)}
              style={{
                padding: 10,
                width: 50,
                background:
                  projectDetails && projectDetails.boundary_color
                    ? projectDetails.boundary_color
                    : "#fff",
                border: `1px solid ${
                  projectDetails && projectDetails.boundary_color
                    ? projectDetails.boundary_color
                    : "#000"
                }`,
              }}
            />

            {showLineColor && (
              <SketchPicker
                color={
                  projectDetails && projectDetails.boundary_color
                    ? projectDetails.boundary_color
                    : "#000"
                }
                onChangeComplete={(val) =>
                  handleChangeComplete(val, "boundary_color")
                }
              />
            )}
          </Grid>

          <Grid item xs={6}>
            <span>Show Boundary</span>
            <Switch
              checked={
                projectDetails && projectDetails.show_boundary
                  ? projectDetails.show_boundary
                  : 0
              }
              onChange={(event) => handleSwitchChange(event, "show_boundary")}
              name="show_boundary"
              inputProps={{ "aria-label": "show_boundary" }}
            />
          </Grid>

          {/* <Grid item xs={6}>
            <span>Fill color</span>
            <div
              onClick={() => setShowFillColor(true)}
              style={{
                padding: 10,
                width: 50,
                background:
                  projectDetails && projectDetails.fill_color
                    ? projectDetails.fill_color
                    : "#7ed321",
                border: `1px solid ${
                  projectDetails && projectDetails.fill_color
                    ? projectDetails.fill_color
                    : "#000"
                }`,
              }}
            />

            {showFillColor && (
              <SketchPicker
                color={
                  projectDetails && projectDetails.fill_color
                    ? projectDetails.fill_color
                    : "#000"
                }
                onChangeComplete={(val) =>
                  handleChangeComplete(val, "fill_color")
                }
              />
            )}
          </Grid> */}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Map
          gps_location={(projectDetails && projectDetails.gps_location) || null}
          zoom_level={(projectDetails && projectDetails.zoom_level) || 18}
          isProject={true}
          onMapClick={onMapClick}
          onZoomChanged={onZoomChanged}
          overlay={overlay}
          polyline={(projectDetails && projectDetails.polyline) || null}
          show_boundary={(projectDetails && projectDetails.show_boundary) || 0}
          boundary_color={(projectDetails && projectDetails.boundary_color) || ''}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => handleReset()}
          color="primary"
        >
          Reset Area
        </Button>
      </Grid>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </Grid>
  );
}
