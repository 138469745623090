import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Login from "./pages/login";
import ProtectedLayout from "./layout/protected";

const App = (props) => {
  const { user } = props;

  const theme = createMuiTheme({
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
    },
  });

  const [loggedIn, setLoggedIn] = useState(false);

  let userDetailsString = localStorage.getItem("realestate_user") || "";
  let userDetails =
    userDetailsString != "" ? JSON.parse(userDetailsString) : {};

  useEffect(() => {
    if (user.isAuthenticated) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    console.log("loggedIn USER AFFECT", loggedIn, user);
  }, [user.isAuthenticated]);
  console.log("loggedIn", loggedIn);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="*">
              {loggedIn || (userDetails && userDetails.isLogined) ? (
                <ProtectedLayout />
              ) : (
                <Login />
              )}
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(App);
