import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DirectionsBusIcon from "@material-ui/icons/DirectionsBus";
import { loginAction } from "../../store/auth/actionCreator";
import ReCAPTCHA from "react-google-recaptcha";

import "./index.css";

import { useSnackbar } from "../../hooks/useSnackbar";
import Snackbar from "../../components/snackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [captcha, setCaptcha] = useState(null);
  const [userDetails, setUserDetails] = useState({
    email: "",
    password: "",
  });

  const { isActive, message, openSnackBar, setIsActive, severity } =
    useSnackbar();

  const { user, loginAction } = props;

  function onChange(value) {
    setCaptcha(value);
    console.log("Captcha value:", value);
  }

  const handleLogin = (event) => {
    event.preventDefault();
    if (!userDetails.email || !userDetails.password) {
      openSnackBar(`Email and Password required`);
      return;
    }
    // if (!captcha) {
    //   openSnackBar(`Accept Captcha`);
    //   return;
    // }

    loginAction(userDetails, 1).then((res) => {
      if (res.status) {
        // login success
      } else {
        openSnackBar(`Invalid email and password`);
      }
    });
  };

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const Copyright = () => {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  };

  return (
    <>
      <Snackbar
        open={isActive}
        message={message}
        severity={severity}
        onClose={setIsActive}
      />
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <DirectionsBusIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="off"
                autoFocus
                onChange={(e) => handleChange(e)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
              />
              {/* <ReCAPTCHA
                sitekey="6LcQdPUgAAAAAGL6A3j8EEe3fT9iMvhJL-j5UAqd"
                onChange={onChange}
              /> */}
              <Button
                onClick={(e) => handleLogin(e)}
                fullWidth
                disabled={user.username == "" || user.password == ""}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
const mapDispatchToProps = {
  loginAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
