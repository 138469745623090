import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import AutoComplete from "../../components/AutoComplete";
import DrawMaps from "../../components/DrawMapsNew";
import XHR from "../../util/xhr";
import CircularProgress from '@material-ui/core/CircularProgress';

import Geocode from "react-geocode";
import API_KEY from "../../util/config";

Geocode.setApiKey(API_KEY);
Geocode.enableDebug();

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const Map = (props) => (
  <DrawMaps
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `500px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    gps_location={props.gps_location}
    onClick={props.onMapClick}
    isProject={props.isProject}
    zoom_level={props.zoom_level}
    onZoomChanged={props.onZoomChanged}
    drawingControlEnabled={props.drawingControlEnabled}
    polyline={props.polyline}
  />
);

export default function ChooseLocation() {
  const classes = useStyles();
  const history = useHistory();

  const { row = null } =
    (history && history.location && history.location.state) || {};

  const [address, setAddress] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      projectDetails &&
      projectDetails.gps_location &&
      projectDetails.gps_location.lat
    ) {
      Geocode.fromLatLng(
        projectDetails.gps_location.lat,
        projectDetails.gps_location.lng
      ).then(
        (response) => {
          const address = response.results.find(
            (el) =>
              el.types.includes("street_address") || el.types.includes("route")
          );
          if (address) {
            setAddress({
              ...address,
              address_components: (address && address.address_components) || [],
              address: address && address.formatted_address,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [projectDetails && projectDetails.gps_location]);

  useEffect(() => {
    setProjectDetails({
      ...projectDetails,
      gps_location: location,
    });
  }, [location]);

  useEffect(() => {
    if (row) {
      setTimeout(() => {
        getProjectDetails(row.id);
      }, 1000);
    }
  }, [row]);

  const getProjectDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        setProjectDetails((res.data && res.data.data) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const onMapClick = (e) => {
    setProjectDetails({
      ...projectDetails,
      gps_location: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const onZoomChanged = (zoom) => {
    setProjectDetails({
      ...projectDetails,
      zoom_level: zoom,
    });
  };

  const handleChange = (key, value) => {
    setProjectDetails({
      ...projectDetails,
      [key]: value,
    });
  };

  const handleNext = () => {
    let rowCopy = row;

    let add = {
      "price": "",
      "price_per_sqft": "",
      "state": "",
      "street": "",
      "total_area": "",
      "total_area_type": "",
      "total_plots": 0,
      "town": "",
      "zipcode": "",
      "add1": "",
      "add2": "",
      "assets": "",
      "city": "",
      "country": "",
      "landmark": ""
    };


    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;

    const addressComponents = (address && address.address_components) || [];
    console.log('addressComponents', addressComponents)
    const zipcode = addressComponents.find((el) =>
      el.types.includes("postal_code")
    );
    if (zipcode) {
      add.zipcode = zipcode.long_name || "";
    } else {
      add.zipcode = (projectDetails && projectDetails.zipcode) || "";
    }

    const country = addressComponents.find((el) =>
      el.types.includes("country")
    );
    if (country) {
      add.country = country.long_name || "";
    } else {
      add.country = (projectDetails && projectDetails.country) || "";
    }

    const state = addressComponents.find((el) =>
      el.types.includes("administrative_area_level_1")
    );
    if (state) {
      add.state = state.long_name || "";
    } else {
      add.state = (projectDetails && projectDetails.state) || "";
    }

    const city = addressComponents.find((el) =>
      el.types.includes("administrative_area_level_2")
    );
    if (city) {
      add.city = city.long_name || "";
    } else {
      add.city = (projectDetails && projectDetails.city) || "";
    }

    const town = addressComponents.find((el) => el.types.includes("locality"));
    if (town) {
      add.town = town.long_name || "";
    } else {
      add.town = (projectDetails && projectDetails.town) || "";
    }

    const add1 = addressComponents.find((el) =>
      el.types.includes("sublocality")
    );
    if (add1) {
      add.add1 = add1.long_name || "";
    } else {
      add.add1 = (projectDetails && projectDetails.add1) || "";
    }

    const street = addressComponents.find(
      (el) => el.types.includes("premise") || el.types.includes("route")
    );
    if (street) {
      add.street = street.long_name || "";
    } else {
      add.street = (projectDetails && projectDetails.street) || "";
    }

    if (projectDetails && projectDetails.id) {
      rowCopy = {
        ...add,
        ...projectDetails,
        user_id: userDetails && userDetails.user_id || 0
      };
      updateProject(rowCopy);
    } else {
      rowCopy = {
        ...add,
        ...projectDetails,
        user_id: userDetails && userDetails.user_id || 0
      };
      addProject(rowCopy);
    }
  };

  const updateProject = (data) => {
    setIsLoading(true);
    const payload = { ...data };
    XHR.post("realestate/update", payload).then(
      (res) => {
        setIsLoading(false);
        routeTo("steptwo", { row: data });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const addProject = (data) => {
    setIsLoading(true);
    const payload = { ...data };
    XHR.post("realestate/add", payload).then(
      (res) => {
        setIsLoading(false);
        if(res.data && res.data.id) {
          routeTo("steptwo", {
            row: { ...data, id: res.data && res.data.id },
          });
        } else {
          alert('Something went wrong');
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={6} lg={6}>
        <span style={{ fontWeight: "bold", fontSize: 20 }}>
          Step 1: Add Project Name and Location
        </span>
      </Grid>
      <Grid item xs={6} md={6} lg={6} style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => history.replace("/", {})}
          color="primary"
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNext()}
          color="primary"
        >
          Save & Next
        </Button>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          value={(projectDetails && projectDetails.name) || ""}
          style={{ width: "100%" }}
          name="name"
          label="Project Name"
          onChange={(e) => handleChange("name", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <AutoComplete
          setLocation={(val) => {
            setLocation(val && val.coor);
          }}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        {projectDetails && projectDetails.gps_location && (
          <Map
            gps_location={
              (projectDetails && projectDetails.gps_location) || {
                lat: 13.086289405322379,
                lng: 80.29239116173903,
              }
            }
            isProject={true}
            onMapClick={onMapClick}
            onZoomChanged={onZoomChanged}
            zoom_level={(projectDetails && projectDetails.zoom_level) || 18}
            drawingControlEnabled={false}
            polyline={(projectDetails && projectDetails.polyline) || []}
          />
        )}
      </Grid>
      {
        isLoading && <div
        style={{
          position: "absolute",
          display: "flex",
          top: "45%",
          left: "50%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress thickness={'3'} size="4rem" />
      </div>
      }
    </Grid>
  );
}
