import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AlertSnackbar({ open, onClose, message, severity, anchorOrigin = { vertical: 'bottom', horizontal: 'right' } }) {
    if (!severity) {
        severity = 'error'; //warning , info , success ,warning
    }
    if (!message) {
        message = 'We will get back to you soon!!!';
    }
    if (!anchorOrigin) {
        anchorOrigin = { vertical: 'top', horizontal: 'left' };
    }
    return (
        <div>
            <Snackbar anchorOrigin={anchorOrigin} open={open} autoHideDuration={4000} onClose={() => onClose(false)}>
                <Alert onClose={() => onClose(false)} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
}
