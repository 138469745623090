import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import EditIcon from "@material-ui/icons/Edit";

import XHR from "../../util/xhr";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default function VisitorsHistory() {
  const classes = useStyles();
  const history = useHistory();

  const { row = {} } = history.location.state;
  const { id = 0 } = row;

  const [visitorsHistory, setVisitorsHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [value, setValue] = React.useState(0);

  const proColumns = [
    { name: "sno", title: "S.No" },
    { name: "type", title: "Access Type" },
    { name: "mobile", title: "Mobile" },
    { name: "ts", title: "Access On" },
    // { name: "action", title: "Action" },
  ];

  const [columns, setColumns] = useState(proColumns);

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);

  useEffect(() => {
    getAllHistory(id);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllHistory = (id) => {
    setIsLoading(true);

    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;

    if (userDetails && userDetails.user_id) {
      const payload = {
        user_id: (userDetails && userDetails.user_id) || 0,
        id: id,
      };
      XHR.post("realestate/visitors/history", payload).then(
        (res) => {
          setIsLoading(false);
          setVisitorsHistory((res.data && res.data.visitors) || []);
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
    }
  };

  const handleEditClick = (el) => {
    setUpdateData(el);
    setShowAction(true);
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const getData = () => {
    return visitorsHistory || [];
  };

  const viewBtn = ({ row }) => (
    <div>
      <Tooltip title="View User Details">
        <VisibilityIcon
          onClick={() => handleViewClick(row)}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
      <Tooltip title="Delete User">
        <DeleteIcon
          onClick={() => handleDeleteClick(row.id)}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
      <Tooltip
        title={
          row.type === "P" ? "Change to Normal User" : "Change to Promoter"
        }
      >
        <ReplayIcon
          color={row.type === "P" ? "secondary" : "primary"}
          onClick={() => handleConvert(row.id, row.type === "P" ? "N" : "P")}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
      <Tooltip title={"Edit User"}>
        <EditIcon
          onClick={() => handleEditClick(row)}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    </div>
  );

  const renderTab = (data) => {
    return (
      <Paper>
        <DataGrid rows={data} columns={columns}>
          <SortingState
            defaultSorting={[]}
            columnExtensions={sortingStateColumnExtensions}
          />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={filteringStateColumnExtensions}
          />
          <IntegratedFiltering />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          {/* <DataTypeProvider
            for={["action"]}
            formatterComponent={(row) => {
              return viewBtn(row);
            }}
          /> */}
          <Table />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} />
          <TableFilterRow />
        </DataGrid>
      </Paper>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "right", marginBottom: 10 }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => history.goBack()}
          color="primary"
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label={`Visitors History`} {...a11yProps(0)} />
            {/* <Tab
              label={`Normal Users (${getData("N").length})`}
              {...a11yProps(1)}
            /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {renderTab(getData("P"))}
        </TabPanel>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message={(toast && toast.message) || "Success"}
          variant={(toast && toast.variant) || "success"}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "45%",
              left: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress thickness={"3"} size="4rem" />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
