import auth from "./auth/reducer";
import user from "./user/reducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user,
  auth,
});

export default rootReducer;
