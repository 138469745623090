import React, { Component, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import { makeStyles, styled } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { DataGrid } from "@material-ui/data-grid";
import { Map, GoogleApiWrapper, Polygon, Polyline } from "google-maps-react";
import { useHistory, useParams } from "react-router-dom";
import DrawMaps from "./MapNew";
import XHR from "../../util/xhr";
import API_KEY from "../../util/config";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{ float: "right" }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const ProjectMap = ({ google, zones = [], center = {} }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const { row = {} } = history.location.state;
  const {
    id = 0,
    gps_location = {},
    polyline = [],
    show_boundary = 0,
    boundary_color = "",
  } = row;

  const zoom_level = 19;

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [uploadObj, setUploadObj] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const [plots, setPlots] = useState([]);
  const [popupDetails, setPopupDetails] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);

  const [nearByLocations, setNearByLocations] = useState([]);

  const [showType, setShowType] = useState(null);
  const [nearbyTypes] = useState(["schools", "hospitals", "banks", "bustops"]);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    { field: "sno", headerName: "S.No", width: 150 },
    {
      field: "name",
      headerName: "Plot Number",
      width: 180,
    },
    {
      field: "area",
      headerName: "Total SqFeet",
      width: 180,
    },
    {
      field: "cost",
      headerName: "Total Price",
      width: 150,
    },
    {
      field: "plot_status",
      headerName: "Current Status",
      width: 180,
      valueGetter: ({ row }, index) => `${getSoldStatus(row.plot_status)}`,
    },
    {
      field: "direction",
      headerName: "Direction Facing",
      width: 180,
      valueGetter: ({ row }) => `${getDirection(row.direction)}`,
    },
    {
      field: "is_corner",
      headerName: "Corner Plot",
      width: 150,
      valueGetter: ({ row }) => `${row.is_corner}`,
    },
    {
      field: "no_of_sides",
      headerName: "No of Sides",
      width: 150,
    },
  ];

  useEffect(() => {
    getPlotDetails(id);
  }, []);

  useEffect(() => {
    if (showType) {
      const payload = {
        url: `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${gps_location.lat},${gps_location.lng}&radius=1500&type=${showType}&name=${showType}&key=${API_KEY}`,
      };
      XHR.post("getnearby", payload).then(
        (response) => {
          console.log("response", response);
          if (response.data && response.data.results) {
            const results = response.data.results;
            const locations = [];
            for (let i = 0; i < results.length; i++) {
              locations.push({
                gps: results[i]["geometry"]["location"],
                icon: results[i]["icon"],
              });
            }
            setNearByLocations(locations);
          } else {
            setNearByLocations([]);
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    } else {
      setNearByLocations([]);
    }
  }, [showType]);

  const handleChange = (event) => {
    setShowType(event.target.value);
  };

  const getPlotDetails = (id) => {
    const payload = { project_id: id };
    XHR.post("realestate/plots/list", payload).then(
      (res) => {
        setPlots((res.data && res.data.plots) || []);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  useEffect(() => {
    if (row) {
      setTimeout(() => {
        updateDetails(row.id);
      }, 100);
    }
  }, [row]);

  useEffect(() => {
    console.log("plots", plots);
  }, [plots]);

  const handleClickOpen = (data) => {
    setPopupDetails(data);
    setOpen(true);
  };
  const handleClose = () => {
    setPopupDetails(null);
    setOpen(false);
  };

  const getPlotType = (type) => {
    switch (type) {
      case 1:
        return "Street";
      case 2:
        return "Plot";
      case 3:
        return "Shop";
      case 4:
        return "Park";
      case 5:
        return "Road";
      case 6:
        return "Commercial";
      default:
        return "";
    }
  };

  const getSoldStatus = (type) => {
    switch (type) {
      case 1:
        return "Sold";
      case 2:
        return "Booked";
      case 0:
        return "Un Sold";
      case 1:
        return "";
    }
  };

  const getDirection = (type) => {
    switch (type) {
      case 1:
        return "East";
      case 2:
        return "West";
      case 3:
        return "North";
      case 4:
        return "South";
      case 5:
        return "North East";
      case 6:
        return "North West";
      case 7:
        return "South East";
      case 8:
        return "South West";
      default:
        return "";
    }
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadType(null);
    setUploadObj(null);
    setUploadStatus(null);
    setUploading(false);
  };

  const onFileChange = (event) => {
    setUploadStatus(null);
    setUploading(false);
    setUploadObj(event.target.files[0]);
  };

  useEffect(() => {
    console.log(uploadObj);
  }, [uploadObj]);

  const handleConfirmUpload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "content-type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    console.log("uploadObj", uploadObj);
    const formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("type", uploadType === "image" ? "I" : "S");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setUploading(false);
        console.log("res", res);
        setUploadStatus(res.data || null);
      },
      (err) => {
        setUploading(false);
        console.log("err", err);
      }
    );
  };

  const handlePreview = () => {
    if (uploadStatus && uploadStatus.url) {
      window.open(uploadStatus.url, "_blank");
    }
  };

  const handleSave = () => {
    let rowCopy = row;
    const projectsString = localStorage.getItem("projects") || null;
    let projects = projectsString ? JSON.parse(projectsString) : [];
    let assets = projectDetails.assets || [];
    assets.push({ type: uploadType, url: uploadStatus.url });
    rowCopy = {
      ...projectDetails,
      assets,
    };
    setProjectDetails(rowCopy);
    projects = projects.map((obj) =>
      obj.id === projectDetails.id ? { ...obj, assets } : obj
    );
    localStorage.setItem("projects", JSON.stringify(projects));

    handleUploadClose();
  };

  const updateDetails = (id) => {
    const projectsString = localStorage.getItem("projects") || null;
    let projects = projectsString ? JSON.parse(projectsString) : [];
    const projDetails = projects.find((el) => el.id === id);
    setProjectDetails(projDetails);
  };

  const getRupeesFormat = (amountValue = "") => {
    return (amountValue || "").toLocaleString("en-IN");
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} md={6} lg={6}>
          <span style={{ fontWeight: "bold", fontSize: 20 }}>View Project</span>
        </Grid>
        <Grid item xs={6} md={6} lg={6} style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => {
              history.goBack();
            }}
            color="primary"
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleTabChange}
              aria-label="simple tabs example"
            >
              <Tab label="Map View" {...a11yProps(0)} />
              <Tab label="Plots" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Show Nearby</FormLabel>
                  <RadioGroup
                    row
                    aria-label="showNearby"
                    name="showNearby"
                    value={showType}
                    onChange={handleChange}
                  >
                    {nearbyTypes.map((el, i) => (
                      <FormControlLabel
                        key={i}
                        value={el}
                        control={<Radio color="primary" />}
                        label={el.toUpperCase()}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={2} md={2} lg={2} />
              <Grid item xs={8} md={8} lg={8} style={{ textAlign: "right" }}>
                <DrawMaps
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `500px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  gps_location={gps_location}
                  zoom_level={zoom_level}
                  plots={plots}
                  polyline={polyline}
                  show_boundary={show_boundary}
                  boundary_color={boundary_color}
                  onPolygonClick={(plot) => handleClickOpen(plot)}
                  nearByLocations={nearByLocations}
                />
              </Grid>
              <Grid item xs={2} md={2} lg={2} />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <div style={{ height: 400, width: "100%" }}>
                  <DataGrid rows={plots} columns={columns} pageSize={10} />
                </div>
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        size="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Plots Details
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              Plot Type:
              <span style={{ fontWeight: "bold", margin: 10 }}>
                {getPlotType(popupDetails && popupDetails.plot_type)}
              </span>
            </Grid>
            <Grid item xs={6}>
              Plot Status:
              <span style={{ fontWeight: "bold", margin: 10 }}>
                {getSoldStatus(popupDetails && popupDetails.plot_status)}
              </span>
            </Grid>
            {popupDetails && parseInt(popupDetails.plot_type) != 5 && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: 18 }}>
                    NO OF SIDES
                  </span>
                </Grid>
                <Grid item xs={12}>
                  No of Sides:
                  <span style={{ fontWeight: "bold", margin: 10 }}>
                    {(popupDetails && popupDetails.no_of_sides) || "4"}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  Side A:
                  <span style={{ fontWeight: "bold", margin: 10 }}>
                    {(popupDetails && popupDetails.side1) || ""}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  Side B:
                  <span style={{ fontWeight: "bold", margin: 10 }}>
                    {(popupDetails && popupDetails.side2) || ""}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  Side C:
                  <span style={{ fontWeight: "bold", margin: 10 }}>
                    {(popupDetails && popupDetails.side3) || ""}
                  </span>
                </Grid>
                <Grid item xs={6}>
                  Side D:
                  <span style={{ fontWeight: "bold", margin: 10 }}>
                    {(popupDetails && popupDetails.side4) || ""}
                  </span>
                </Grid>

                {popupDetails && popupDetails.no_of_sides > 4 && (
                  <Grid item xs={6}>
                    Side E:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side5) || ""}
                    </span>
                  </Grid>
                )}

                {popupDetails && popupDetails.no_of_sides > 5 && (
                  <Grid item xs={6}>
                    Side F:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side6) || ""}
                    </span>
                  </Grid>
                )}
                {popupDetails && popupDetails.no_of_sides > 6 && (
                  <Grid item xs={6}>
                    Side G:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side7) || ""}
                    </span>
                  </Grid>
                )}
                {popupDetails && popupDetails.no_of_sides > 7 && (
                  <Grid item xs={6}>
                    Side H:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side8) || ""}
                    </span>
                  </Grid>
                )}
                {popupDetails && popupDetails.no_of_sides > 8 && (
                  <Grid item xs={6}>
                    Side I:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side9) || ""}
                    </span>
                  </Grid>
                )}
                {popupDetails && popupDetails.no_of_sides > 9 && (
                  <Grid item xs={6}>
                    Side J:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side10) || ""}
                    </span>
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: 18 }}>ROAD</span>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={6}>
                    Road in Front:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.front_road) || "No"}
                    </span>
                  </Grid>
                  {popupDetails && popupDetails.front_road === "yes" && (
                    <Grid item xs={6}>
                      Front Road Length (In Feet):
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails.cost && popupDetails.front_road_feet) ||
                          ""}
                      </span>
                    </Grid>
                  )}
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={6}>
                    Road in Back:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.back_road) || "No"}
                    </span>
                  </Grid>
                  {popupDetails && popupDetails.back_road === "yes" && (
                    <Grid item xs={6}>
                      Back Road Length (In Feet):
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.back_road_feet) || "No"}
                      </span>
                    </Grid>
                  )}
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={6}>
                    Road in Left:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.left_road) || "No"}
                    </span>
                  </Grid>
                  {popupDetails && popupDetails.left_road === "yes" && (
                    <Grid item xs={6}>
                      Left Road Length (In Feet):
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.left_road_feet) || ""}
                      </span>
                    </Grid>
                  )}
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={6}>
                    Road in Right:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.right_road) || "No"}
                    </span>
                  </Grid>
                  {popupDetails && popupDetails.right_road === "yes" && (
                    <Grid item xs={6}>
                      Right Road Length (In Feet):
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.right_road_feet) || ""}
                      </span>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                />
                <Grid item xs={6}>
                  Is Corner Plot:
                  <span style={{ fontWeight: "bold", margin: 10 }}>
                    {(popupDetails && popupDetails.is_corner) || "No"}
                  </span>
                </Grid>
                {popupDetails && popupDetails.is_corner !== "yes" ? null : (
                  <Grid item xs={6}>
                    Direction Facing:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.direction) || ""}
                    </span>
                  </Grid>
                )}
              </>
            )}
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span style={{ fontWeight: "bold", fontSize: 18 }}>AREA</span>
            </Grid>
            <Grid item xs={6}>
              Plot No:
              <span style={{ fontWeight: "bold", margin: 10 }}>
                {(popupDetails && popupDetails.name) || ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              Total Area in sq feet:
              <span style={{ fontWeight: "bold", margin: 10 }}>
                ₹{Math.round(popupDetails && popupDetails.area) || ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              Rate Per sq feet:
              <span style={{ fontWeight: "bold", margin: 10 }}>
                ₹
                {getRupeesFormat(popupDetails && popupDetails.per_s_feet) || ""}
              </span>
            </Grid>
            <Grid item xs={6}>
              Total Cost:
              <span style={{ fontWeight: "bold", margin: 10 }}>
                ₹{getRupeesFormat(popupDetails && popupDetails.cost)}
              </span>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog
        open={openUpload}
        size="md"
        fullWidth
        onClose={handleUploadClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Upload</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {uploadType === "image" && (
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="image-button-file"
                  onChange={onFileChange}
                  type="file"
                />
                {!uploadObj ? (
                  <label htmlFor="image-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Choose Image
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10 }}>
                      {uploadObj.name || "Image"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={handleConfirmUpload}
                          style={{ marginRight: 10 }}
                        >
                          {uploading ? "Uploading" : "Upload Image"}
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                        >
                          Preview Image
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
            {uploadType === "audio" && (
              <Grid item xs={12}>
                <input
                  accept="audio/*"
                  className={classes.input}
                  id="audio-button-file"
                  onChange={onFileChange}
                  type="file"
                />

                {!uploadObj ? (
                  <label htmlFor="audio-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Choose Audio
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10 }}>
                      {uploadObj.name || "Audio"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleConfirmUpload}
                          component="span"
                          style={{ marginRight: 10 }}
                        >
                          {uploading ? "Uploading" : "Upload Audio"}
                        </Button>
                        <label htmlFor="audio-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Audio
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                        >
                          Preview Audio
                        </Button>
                        <label htmlFor="audio-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Audio
                          </Button>
                        </label>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
            {uploadType === "video" && (
              <>
                <Grid item xs={12}>
                  <input
                    accept="video/*"
                    className={classes.input}
                    id="video-button-file"
                    onChange={onFileChange}
                    type="file"
                  />

                  {!uploadObj ? (
                    <label htmlFor="video-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        Choose Video
                      </Button>
                    </label>
                  ) : (
                    <>
                      <Typography style={{ marginBottom: 10 }}>
                        {uploadObj.name || "Video"}
                      </Typography>
                      {!uploadStatus ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmUpload}
                            component="span"
                            style={{ marginRight: 10 }}
                          >
                            {uploading ? "Uploading" : "Upload Video"}
                          </Button>
                          <label htmlFor="video-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              Choose New Video
                            </Button>
                          </label>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            onClick={handlePreview}
                            style={{ marginRight: 10 }}
                          >
                            Preview Video
                          </Button>
                          <label htmlFor="video-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                            >
                              Choose New Video
                            </Button>
                          </label>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField id="standard-basic" label="Add Youtube url" />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadClose} color="primary">
            Close
          </Button>
          {uploadStatus && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: API_KEY,
})(ProjectMap);
