import React, { useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import {
  LoadScript,
  GoogleMap,
  Polygon,
  DrawingManager,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import API_KEY from "../../util/config";

import "./DrawMaps.css";

// This example presents a way to handle editing a Polygon
// The objective is to get the new path on every editing event :
// - on dragging the whole Polygon
// - on moving one of the existing points (vertex)
// - on adding a new point by dragging an edge point (midway between two vertices)

// We achieve it by defining refs for the google maps API Polygon instances and listeners with `useRef`
// Then we bind those refs to the currents instances with the help of `onLoad`
// Then we get the new path value with the `onEdit` `useCallback` and pass it to `setPath`
// Finally we clean up the refs with `onUnmount`

const OPTIONS = {
  minZoom: 4,
  maxZoom: 100,
};

const getFillColor = (status) => {
  switch (parseInt(status)) {
    case 0:
      return "#26BD26";
    case 1:
      return "#F13030";
    case 2:
      return "#3088F1";
    default:
      return "transparent";
  }
};

const DrawMapsNew1 = (props) => {
  return (
    <LoadScript googleMapsApiKey={API_KEY} libraries={["drawing"]}>
      <GoogleMap
        // options={OPTIONS}
        style={{ height: 600, width: "100%" }}
        mapContainerClassName="project-map"
        center={props.gps_location}
        zoom={props.zoom_level || 17}
        mapTypeId="satellite"
        onClick={props.onClick}
        ref={props.onMapMounted}
        onZoomChanged={props.onZoomChanged}
      >
        <DrawingManager
          options={{
            drawingControl: props.drawingControlEnabled,
          }}
          onOverlayComplete={props.overlay}
        />

        {props.polygon &&
          props.polygon !== null &&
          props.polygon
            .filter((el) => !props.dragPolygon || props.dragPolygon !== el.id)
            .map((path, i) => (
              <Polygon
                paths={path.gps}
                editable={false}
                options={{
                  fillColor: getFillColor(path.plot_status),
                  strokeColor: "#000",
                  strokeWeight: 1,
                }}
                onClick={(event) => props.onPolygonClick(path, i)}
              />
            ))}
        {props.polygon &&
          props.polygon !== null &&
          props.polygon
            .filter((el) => props.dragPolygon && props.dragPolygon === el.id)
            .map((path, i) => (
              <Polygon
                paths={path.gps}
                editable={true}
                draggable={true}
                options={{
                  fillColor: getFillColor(path.plot_status),
                  strokeColor: "#000",
                  strokeWeight: 1,
                }}
                onMouseUp={props.onEdit}
                onLoad={props.onLoad}
                onUnmount={props.onUnmount}
              />
            ))}
        {props.polyline &&
          props.polyline !== null &&
          props.polyline.map((path, i) => (
            <Polyline
              key={i}
              path={path.gps}
              options={{
                fillColor: getFillColor(path.plot_status),
                strokeColor: "#EF243D",
                strokeWeight: 3,
              }}
            />
          ))}

        {props.isProject &&
          props.gps_location &&
          props.gps_location !== null && (
            <Marker position={props.gps_location} />
          )}
      </GoogleMap>
    </LoadScript>
  );
};
export default DrawMapsNew1;
