import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Dashboard from "../pages/dashboard/Dashboard";
import Companies from "../pages/dashboard/Companies";
import AddedProperties from "../pages/dashboard/AddedProperties";

import ChooseLocation from "../pages/dashboard/ChooseLocation";
import UpdateAddress from "../pages/dashboard/UpdateAddress";
import PlotProject from "../pages/dashboard/PlotProject";
import DrawProject from "../pages/dashboard/DrawProject";
import UpdateClient from "../pages/dashboard/UpdateClient";
import UpdateCompany from "../pages/dashboard/UpdateCompany";
import PromoterDetails from "../pages/dashboard/PromoterDetails";

import PromotersList from "../pages/dashboard/PromotersList";
import MapProjects from "../pages/dashboard/MapProjects";
import ListProjects from "../pages/dashboard/ListProjects";

import OmtMember from "../pages/dashboard/OmtMember";

import DataEntriesProjectList from "../pages/dashboard/DataEntriesProjectList";

import DataEntriesPlotsList from "../pages/dashboard/DataEntriesPlotsList";

import Queries from "../pages/dashboard/Queries";

import { logout } from "../store/auth/actionCreator";
import { connect } from "react-redux";

import Map from "../pages/dashboard/Map";
import Poly from "../pages/poly/poly";

import VisitorsHistory from "../pages/dashboard/VisitorsHistory";
import Analytics from "../pages/dashboard/Analytics";
import SiteVisit from "../pages/dashboard/SiteVisit";
import Rera from "../pages/dashboard/Rera";
import UpdateRera from "../pages/dashboard/UpdateRera";
import IntermediaryHistory from "../pages/dashboard/IntermediaryHistory";
import UploadRera from "../pages/dashboard/UploadRera";
import Chatbot from "../pages/dashboard/Chatbot";
import CityPlp from "../pages/dashboard/CityPlp";

import "./protected.css";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    // paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  list: {
    marginLeft: 30,
  },
  title: {
    float: "left",
  },
}));

const Protected = (props) => {
  const history = useHistory();

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const { logout } = props;

  useEffect(() => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : {};
    if (!userDetails.isLogined) {
      routeTo("/login", {});
    } else if (userDetails.user_type === "P") {
      routeTo("/promoters", {});
    } else if (userDetails.user_type === "D") {
      routeTo("/dataentries", {});
    } else if (userDetails.user_type === "S") {
      routeTo("/projects", {});
    } else if (userDetails.user_type === "F") {
      routeTo("/rera", {});
    }
  }, []);

  const signOut = () => {
    logout();
  };

  const getComponent = () => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : {};
    console.log("userDetails", userDetails);
    if (userDetails.user_type === "P") {
      return <PromotersList />;
    } else if (userDetails.user_type === "D") {
      return <DataEntriesProjectList />;
    } else if (userDetails.user_type === "S") {
      return <Dashboard />;
    } else if (userDetails.user_type === "F") {
      return <Rera />;
    }
  };

  return (
    <div className="view">
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Real Estate
          </Typography>
          <IconButton
            style={{ position: "absolute", right: 10, color: "white" }}
            onClick={signOut}
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main>
        <div className="mainContent">
          <Router basename={"/portal"}>
            <Switch>
              <Route path="/projects" exact children={<Dashboard />} />
              <Route path="/companies" exact children={<Companies />} />
              <Route path="/omtmember" exact children={<OmtMember />} />
              <Route
                path="/addedproperties"
                exact
                children={<AddedProperties />}
              />
              <Route
                path="/projects/stepone"
                exact
                children={<ChooseLocation />}
              />
              <Route
                path="/projects/steptwo"
                exact
                children={<UpdateAddress />}
              />
              <Route
                path="/projects/stepthree"
                exact
                children={<PlotProject />}
              />
              <Route
                path="/visitorshistory"
                exact
                children={<VisitorsHistory />}
              />
              <Route path="/analytics" exact children={<Analytics />} />
              <Route
                path="/intermediaryhistory"
                exact
                children={<IntermediaryHistory />}
              />
              <Route path="/rera" exact children={<Rera />} />
              <Route path="/updaterera" exact children={<UpdateRera />} />
              <Route path="/sitevisit" exact children={<SiteVisit />} />
              <Route path="/drawplots" exact children={<DrawProject />} />
              <Route path="/drawimage" exact children={<Poly />} />
              <Route path="/viewproject" exact children={<Map />} />
              <Route path="/queries" exact children={<Queries />} />
              <Route path="/updateclient" exact children={<UpdateClient />} />
              <Route path="/updatecompany" exact children={<UpdateCompany />} />
              <Route
                path="/promoterdetails"
                exact
                children={<PromoterDetails />}
              />
              <Route path="/promoters" exact children={<PromotersList />} />
              <Route path="/mapprojects" exact children={<MapProjects />} />
              <Route path="/listprojects" exact children={<ListProjects />} />
              <Route
                path="/dataentries"
                exact
                children={<DataEntriesProjectList />}
              />
              <Route
                path="/dataentries/plots"
                exact
                children={<DataEntriesPlotsList />}
              />
              <Route path="/uploadrera" exact children={<UploadRera />} />
              <Route
                path="/chatbot"
                exact
                children={<Chatbot />}
              />
              <Route
                path="/cityplp"
                exact
                children={<CityPlp />}
              />
              <Route path="*" children={getComponent()} />
            </Switch>
          </Router>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  logout,
};
export default connect(mapStateToProps, mapDispatchToProps)(Protected);
