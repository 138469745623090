import React, { useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polygon,
  Polyline,
  Marker,
} from "react-google-maps";
const {
  MarkerWithLabel,
} = require("react-google-maps/lib/components/addons/MarkerWithLabel");

const { compose, withState, withHandlers, lifecycle } = require("recompose");

const getCenter = (arr) => {
  var x = arr.map((x) => x["lat"]);
  var y = arr.map((x) => x["lng"]);
  var cx = (Math.min(...x) + Math.max(...x)) / 2;
  var cy = (Math.min(...y) + Math.max(...y)) / 2;
  return { lat: cx, lng: cy };
};

const labelSize = { width: 220 };

const DrawMaps = compose(
  withState("zooms", "onZoomChange", 7),
  withState("lats", "onLatChange", 11.127123),
  withState("lngs", "onLngChange", 78.656891),
  withHandlers((props) => {
    const refs = {
      map: undefined,
    };
    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged: (props) => (e) => {
        props.onZoomChanged(refs.map.getZoom());
      },
      onMapClick:
        ({ onLatChange, onLngChange }) =>
        (e) => {
          onLatChange(e.latLng.lat());
          onLngChange(e.latLng.lng());
        },
      getFillColor: () => (status) => {
        switch (parseInt(status)) {
          case 0:
            return "#26BD26";
          case 1:
            return "#F13030";
          case 2:
            return "#3088F1";
          default:
            return "transparent";
        }
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.onZoomChange(this.props.zoom);
      this.props.onLatChange(this.props.lat);
      this.props.onLngChange(this.props.lng);
    },
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <div>
    <GoogleMap
      defaultCenter={props.gps_location}
      defaultZoom={props.zoom_level || 20}
      mapTypeId="satellite"
      ref={props.onMapMounted}
    >
      {props.plots &&
        props.plots !== null &&
        props.plots.map((path, i) => (
          <Polygon
            key={i}
            paths={path.gps}
            options={{
              fillColor: props.getFillColor(path.plot_status),
              strokeColor: "#000",
              strokeWeight: 1,
            }}
            onClick={(event) => props.onPolygonClick(path)}
          />
        ))}

      {props.show_boundary &&
        props.polyline &&
        props.polyline !== null &&
        props.polyline.map((path, i) => (
          <Polyline
            key={i}
            path={path.gps}
            options={{
              fillColor: props.getFillColor(path.plot_status),
              strokeColor: path.line_color
                ? path.line_color
                : props.boundary_color,
              strokeWeight: 3,
            }}
            onMouseover={(e) => {
              console.log(path.name);
            }}
            onMouseout={(e) => {
              console.log(path.name);
            }}
          />
        ))}

      {/* {props.plots &&
        props.plots !== null &&
        props.plots.map((path, i) => (
          <MarkerWithLabel
            labelStyle={{
              textAlign: "center",
              fontSize: "12px",
            }}
            labelClass="map-label"
            labelAnchor={{ x: labelSize.width / 10, y: 15 }}
            key={i}
            icon={" "}
            position={getCenter(path.gps)}
          >
            <span>{path.name}</span>
          </MarkerWithLabel>
        ))} */}

      {props.nearByLocations &&
        props.nearByLocations !== null &&
        props.nearByLocations.map((el, i) => (
          <Marker key={i} position={el.gps} icon={el.icon} />
        ))}
    </GoogleMap>
  </div>
));

export default DrawMaps;
