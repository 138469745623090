import React, { useState, useEffect, useRef } from "react";
import { fabric } from "fabric";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
// import TipsAndUpdatesIcon from "@material-ui/icons/TipsAndUpdates";
import Tooltip from "@material-ui/core/Tooltip";
import Divider from "@material-ui/core/Divider";
import { map } from "lodash";
import SaveIcon from "@material-ui/icons/Save";
import AppsIcon from "@material-ui/icons/Apps";
import ContentCopyIcon from "@material-ui/icons/FileCopy";

import {
  ArrowUpward,
  ArrowDownward,
  AddCircleOutline,
  RemoveCircleOutline,
  ArrowBack,
  ArrowForward,
  Reorder,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { SketchPicker } from "react-color";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import XHR from "../../util/xhr";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";

import { baseUrl } from "../../util/config";

import DataGrid from "../poly/DataGrid";

import "./poly.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
}));

var min = 99;
var max = 999999;
var polygonMode = false;
var pointArray = new Array();
var lineArray = new Array();
var activeLine;
var activeShape = false;
var canvas;
var panning = false;
var polyEdit = false;
var _clipboard;

function polygonPositionHandler(dim, finalMatrix, fabricObject) {
  console.log("INSIDE polygonPositionHandler", fabricObject);
  var x = fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x,
    y = fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y;
  return fabric.util.transformPoint(
    { x: x, y: y },
    fabric.util.multiplyTransformMatrices(
      fabricObject.canvas.viewportTransform,
      fabricObject.calcTransformMatrix()
    )
  );
}

function actionHandler(eventData, transform, x, y) {
  console.log("INSIDE actionHandler", transform);
  var polygonHandle = transform.target,
    currentControl = polygonHandle.controls[polygonHandle.__corner],
    mouseLocalPosition = polygonHandle.toLocalPoint(
      new fabric.Point(x, y),
      "center",
      "center"
    ),
    polygonBaseSize = polygonHandle._getNonTransformedDimensions(),
    size = polygonHandle._getTransformedDimensions(0, 0),
    finalPointPosition = {
      x:
        (mouseLocalPosition.x * polygonBaseSize.x) / size.x +
        polygonHandle.pathOffset.x,
      y:
        (mouseLocalPosition.y * polygonBaseSize.y) / size.y +
        polygonHandle.pathOffset.y,
    };
  polygonHandle.points[currentControl.pointIndex] = finalPointPosition;
  return true;
}

function anchorWrapper(anchorIndex, fn) {
  console.log("INSIDE anchorWrapper", anchorIndex);
  return function (eventData, transform, x, y) {
    var fabricObject = transform.target,
      absolutePoint = fabric.util.transformPoint(
        {
          x: fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x,
          y: fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y,
        },
        fabricObject.calcTransformMatrix()
      ),
      actionPerformed = fn(eventData, transform, x, y),
      newDim = fabricObject._setPositionDimensions({}),
      polygonBaseSize = fabricObject._getNonTransformedDimensions(),
      newX =
        (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) /
        polygonBaseSize.x,
      newY =
        (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) /
        polygonBaseSize.y;
    fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
    return actionPerformed;
  };
}

function Poly() {
  const canvasNewContainer = useRef();
  const canvasRefV1 = useRef();
  const fabricRefV1 = useRef();
  const toolTipRef = useRef();

  const history = useHistory();
  const classes = useStyles();
  const {
    id,
    name,
    gps_location,
    zoom_level = 17,
    polyline = [],
    layout_image = "",
  } = history.location.state;

  const [open, setOpen] = React.useState(false);
  const [polygonOpen, setPolygonOpen] = React.useState(false);
  const [showZoom, setShowZoom] = React.useState(false);
  const [data, setData] = useState({});
  const [areaDetails, setAreaDetails] = React.useState({
    plot_type: 2,
    area: "",
    cost: "",
    no_of_sides: 4,
    side1: "",
    side2: "",
    side3: "",
    side4: "",
    side5: "",
    side6: "",
    side7: "",
    side8: "",
    per_s_feet: "",
    is_corner: "",
    back_road: "",
    back_road_feet: "",
    front_road: "",
    front_road_feet: "",
    left_road: "",
    left_road_feet: "",
    right_road: "",
    right_road_feet: "",
    direction: "",
    corner_direction_facing: "",
    gps: "",
    name: "",
    fill_color: "#7ed321",
    line_color: "#000",
  });
  const [showLineColor, setShowLineColor] = React.useState(false);
  const [showFillColor, setShowFillColor] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [polyMode, setPolyMode] = useState(false);
  const polyIndex = useRef(0);
  //   const [polyIndex, setPolyIndex] = useState(0);
  const polyList = useRef([]);
  const [isPolyEdit, setIsPolyEdit] = useState(false);
  const [isPolyCopy, setIsPolyCopy] = useState(false);
  const [show, setShow] = useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [dragPolygon, setDragPolygon] = React.useState(null);
  const [fabricImage, setFabricImage] = React.useState(null);

  const [uploadedPlots, setUploadedPlots] = React.useState([]);
  const [uploadedPlotId, setUploadedPlotId] = React.useState(null);

  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  // Table Properties

  const columns = [
    { name: "sno", title: "S.No" },
    { name: "name", title: "Plot Number" },
    { name: "plot_status_disp", title: "Current Status" },
    { name: "type", title: "Type of Land" },
  ];

  const tableColumnExtensions = [
    { columnName: "sno", width: 100, wordWrapEnabled: true },
    { columnName: "name", wordWrapEnabled: true },
    { columnName: "plot_status_disp", wordWrapEnabled: true },
    { columnName: "type", wordWrapEnabled: true },
  ];

  const defaultHiddenColumnNames = [];

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
  ];

  const loadGrid = (inputs) => {
    // inputs["project_id"] = id;
    // inputs["onlyPublish"] = true;
    // getTablePlotDetails(inputs);
  };

  const getTablePlotDetails = (inputs) => {
    let XHRUrl = `${baseUrl}`;
    const payload = inputs;
    axios.post(`${XHRUrl}/realestate/plots/datatable`, payload).then(
      (res) => {
        setRows((res.data && res.data.data.rows) || []);
        setTotalRecords((res.data && res.data.data.total) || 0);
      },
      (err) => {
        console.log("err", err);
      }
    );
  };

  const [state, setState] = useState({
    polygon: [],
  });

  useEffect(() => {
    canvas = null;
  }, []);

  // INIT
  useEffect(() => {
    initCanvas();
  });

  useEffect(() => {
    if (layout_image) {
      setFabricImage(layout_image);
    }
  }, [layout_image]);

  useEffect(() => {
    resetCanvas();
  }, [fabricImage]);

  useEffect(() => {
    if (id) {
      getLayoutDetails(id);
      getPlotDetails(id);
      getAllUploadedPlots(id);
    }
  }, [id]);

  useEffect(() => {
    redrawPoints(state.polygon);
    reDrawTable(state.polygon);
  }, [state && state.polygon]);

  const redrawPoints = () => {
    const fabricPolygons =
      state.polygon && state.polygon.filter((el) => el.drawn_type === 2);
    if (fabricPolygons.length > 0) {
      console.log("redrawPoints @@@@@@", fabricPolygons);
      if (fabricPolygons.length > 0 && canvas) {
        canvas.getObjects().map(function (o) {
          if (o.objectType !== "image") canvas.remove(o);
        });
      }
      for (const element of fabricPolygons) {
        reGeneratePolygon(element);
      }
    }
  };

  const reDrawTable = () => {
    const fabricPolygons =
      state.polygon && state.polygon.filter((el) => el.drawn_type === 2);
    let tableRows = [];
    if (fabricPolygons.length > 0) {
      let index = 1;
      for (const element of fabricPolygons) {
        tableRows.push({
          sno: index,
          name: element.name,
          plot_status_disp: getSoldStatus(element.plot_status),
          type: getPlotType(element.plot_type),
        });
        index = index + 1;
      }
    }
    setRows(tableRows);
    setTotalRecords(tableRows.length);
  };
  const reGeneratePolygon = (element) => {
    polyIndex.current++;

    const polyListDetails = {
      id: element.id,
      points: element.points,
    };

    polyList.current.push(polyListDetails);

    var polygon = new fabric.Polygon(element.points, {
      id: element.id,
      name: element.name,
      objectValue: element,
      objectType: "polygon",
      fill:
        element.plot_status == 0 && element.plot_type != 5
          ? "#006400"
          : element.plot_status == 1 && element.plot_type != 5
          ? "#FF0000"
          : element.plot_status == 2 && element.plot_type != 5
          ? "#0000FF"
          : "#808080",
      stroke: "#000",
      strokeWidth: 2,
      opacity: 0.5,
      objectCaching: false,
      transparentCorners: false,
      selectable: false,
    });

    polygon.on("mousedown", function (options) {
      console.log(" polygon mousedblclick isEdit", polyEdit);
      if (!polyEdit) {
        setAreaDetails({ ...options.target.objectValue, isEdit: false });
        setPolygonOpen(true);
      }
    });

    canvas.add(polygon);

    activeLine = null;
    activeShape = null;
    polygonMode = false;
    canvas.selection = true;
    setPolyMode(false);
  };

  const getAllUploadedPlots = (id) => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post("realestate/plots/uploadedlist", payload).then(
      (res) => {
        setIsLoading(false);
        setUploadedPlots((res.data && res.data.plots) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getLayoutDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.data) {
          setData(res.data.data);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleInputChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const handleSaveZoom = () => {
    setIsLoading(true);
    const payload = {
      id: id,
      fabric_min_zoom: data.fabric_min_zoom,
      fabric_max_zoom: data.fabric_max_zoom,
      default_zoom_level: data.default_zoom_level,
      magnification: data.magnification
    };
    XHR.post("realestate/updatefabriczoom", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleSnackClick();
          getLayoutDetails(id);
          setShowZoom(false);
          setToast({
            message: "Success",
            variant: "success",
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { project_id: id };
    console.log("payload", payload);
    XHR.post("realestate/plots/list", payload).then(
      (res) => {
        setIsLoading(false);
        setState({
          ...state,
          polygon: (res.data && res.data.plots) || [],
        });
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const savePolygon = (id) => {
    let polygonPoints;
    polyEdit = false;
    setIsPolyEdit(false);
    setDragPolygon(null);
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    let allObject = canvas.getObjects();
    map(allObject, (object) => {
      if (object.id === id) {
        polygonPoints = object;
      }
    });

    let matrix = polygonPoints.calcTransformMatrix();

    var translatedPoints = polygonPoints.get("points").map(function (p) {
      return {
        x: p.x - polygonPoints.pathOffset.x,
        y: p.y - polygonPoints.pathOffset.y,
      };
    });
    for (var i = 0; i < translatedPoints.length; i++) {
      translatedPoints[i].x =
        matrix[0] * translatedPoints[i].x +
        matrix[2] * translatedPoints[i].y +
        matrix[4];
      translatedPoints[i].y =
        matrix[1] * translatedPoints[i].x +
        matrix[3] * translatedPoints[i].y +
        matrix[5];
    }

    console.log("translatedPoints", translatedPoints);

    console.log("Points @@@@", polygonPoints);
    updatePath(translatedPoints, polygonPoints.name, id);
  };

  const updatePath = (points, name, pId) => {
    if (points && points.length > 0) {
      updatePlots({ ...areaDetails, points: points, name: name, id: pId });
    }
  };

  const drawPolygon = () => {
    if (polyMode) {
      polygonMode = false;
      pointArray = new Array();
      lineArray = new Array();
      activeLine = null;
      setPolyMode(false);
    } else {
      polygonMode = true;
      pointArray = new Array();
      lineArray = new Array();
      activeLine = null;
      setPolyMode(true);
    }
  };

  console.log("polygonMode", polyMode, polygonMode);

  const initPolygon = () => {
    polygonMode = true;
    pointArray = new Array();
    lineArray = new Array();
    activeLine = null;
    setPolyMode(true);

    polygonMode = false;
    pointArray = new Array();
    lineArray = new Array();
    activeLine = null;
    setPolyMode(false);
  };

  const editPolygon = (id) => {
    setDragPolygon(id);
    setIsPolyEdit(true);
    polyEdit = true;
    var poly;
    let allObject = canvas.getObjects();
    map(allObject, (object) => {
      if (object.id === id) {
        poly = object;
      }
    });
    console.log(allObject);
    canvas.setActiveObject(poly);
    var lastControl = poly.points.length - 1;
    console.log("Points @@@@", poly.points);
    console.log("lastControl", lastControl, poly.points);
    poly.cornerStyle = "circle";
    poly.cornerColor = "rgba(9, 1, 5, 1)";
    poly.cornerStrokeColor = "red";
    poly.transparentCorners = false;
    poly.controls = poly.points.reduce(function (acc, point, index) {
      acc["p" + index] = new fabric.Control({
        positionHandler: polygonPositionHandler,
        actionHandler: anchorWrapper(
          index > 0 ? index - 1 : lastControl,
          actionHandler
        ),
        actionName: "modifyPolygon",
        pointIndex: index,
      });
      return acc;
    }, {});
    console.log("poly", poly);
    canvas.requestRenderAll();
  };

  const copyPolygon = (id) => {
    var poly;
    setIsPolyCopy(true);
    let allObject = canvas.getObjects();
    map(allObject, (object) => {
      if (object.id === id) {
        poly = object;
      }
    });
    canvas.setActiveObject(poly);
    canvas.getActiveObject().clone(function (cloned) {
      _clipboard = cloned;
    });
    // canvas.discardActiveObject();
    canvas.requestRenderAll();
    console.log("_clipboard", _clipboard);
  };

  const cancelSavePolygon = () => {
    setIsPolyCopy(false);
    canvas.discardActiveObject();
    canvas.requestRenderAll();
  };

  const pastePolygon = () => {
    // clone again, so you can do multiple copies.
    _clipboard.clone(function (clonedObj) {
      canvas.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj.type === "activeSelection") {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = canvas;
        clonedObj.forEachObject(function (obj) {
          canvas.add(obj);
        });
        // this should solve the unselectability
        clonedObj.setCoords();
      } else {
        canvas.add(clonedObj);
      }
      _clipboard.top += 10;
      _clipboard.left += 10;
      canvas.requestRenderAll();
      console.log("clonedObj", clonedObj);

      polyIndex.current++;
      const name = `Fabric ${polyIndex.current}`;

      handleAddPolygon({
        points: clonedObj.points,
        name: name,
        fill_color: "#7ed321",
        line_color: "#000",
        area: "",
        cost: "",
        no_of_sides: 4,
        side1: "",
        side2: "",
        side3: "",
        side4: "",
        side5: "",
        side6: "",
        side7: "",
        side8: "",
        per_s_feet: "",
        plot_type: 2,
        is_corner: "",
        back_road: "",
        back_road_feet: "",
        front_road: "",
        front_road_feet: "",
        left_road: "",
        left_road_feet: "",
        right_road: "",
        right_road_feet: "",
        direction: "",
        corner_direction_facing: "",
        plot_status: 0,
      });
      setIsPolyCopy(false);
    });
  };

  const generatePolygon = (pointArray, isAdd) => {
    polyIndex.current++;

    var points = new Array();
    pointArray.forEach((point) => {
      points.push({
        x: point.left,
        y: point.top,
      });
      console.log("point", point);
      canvas.remove(point);
    });

    lineArray.forEach((line) => {
      canvas.remove(line);
    });
    canvas.remove(activeShape).remove(activeLine);

    const polyListDetails = {
      id: polyIndex.current,
      points: points,
    };
    const name = `Fabric ${polyIndex.current}`;

    polyList.current.push(polyListDetails);

    var polygon = new fabric.Polygon(points, {
      id: polyIndex.current,
      name: name,
      objectType: "polygon",
      fill: "#7ed321",
      stroke: "#000",
      strokeWidth: 2,
      opacity: 0.5,
      objectCaching: false,
      transparentCorners: false,
      selectable: false,
    });
    canvas.add(polygon);

    activeLine = null;
    activeShape = null;
    polygonMode = false;
    canvas.selection = true;
    setPolyMode(false);

    console.log("isAdd", isAdd);
    if (isAdd) {
      handleAddPolygon({
        points: points,
        name: name,
        fill_color: "#7ed321",
        line_color: "#000",
        area: "",
        cost: "",
        no_of_sides: 4,
        side1: "",
        side2: "",
        side3: "",
        side4: "",
        side5: "",
        side6: "",
        side7: "",
        side8: "",
        per_s_feet: "",
        plot_type: 2,
        is_corner: "",
        back_road: "",
        back_road_feet: "",
        front_road: "",
        front_road_feet: "",
        left_road: "",
        left_road_feet: "",
        right_road: "",
        right_road_feet: "",
        direction: "",
        corner_direction_facing: "",
        plot_status: 0,
      });
    }
  };

  const addPoint = (position) => {
    var random = Math.floor(Math.random() * (max - min + 1)) + min;
    var id = new Date().getTime() + random;
    console.log("pointArray ****************", pointArray);
    var circle = new fabric.Circle({
      radius: 5,
      fill: "#ffffff",
      stroke: "#333333",
      strokeWidth: 0.5,
      left: position.x,
      top: position.y,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      originX: "center",
      originY: "center",
      id: id,
      objectCaching: false,
    });
    if (pointArray.length == 0) {
      circle.set({
        fill: "red",
      });
    }
    var points = [position.x, position.y, position.x, position.y];
    let line = new fabric.Line(points, {
      strokeWidth: 2,
      fill: "#999999",
      stroke: "#999999",
      class: "line",
      originX: "center",
      originY: "center",
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    if (activeShape) {
      var points = activeShape.get("points");
      points.push({
        x: position.x,
        y: position.y,
      });
      var polygon = new fabric.Polygon(points, {
        stroke: "#333333",
        strokeWidth: 1,
        fill: "#cccccc",
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });
      canvas.remove(activeShape);
      canvas.add(polygon);
      activeShape = polygon;
      canvas.renderAll();
    } else {
      var polyPoint = [
        {
          x: position.x,
          y: position.y,
        },
      ];
      var polygon = new fabric.Polygon(polyPoint, {
        stroke: "#333333",
        strokeWidth: 1,
        fill: "#cccccc",
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });
      activeShape = polygon;
      canvas.add(polygon);
    }
    activeLine = line;

    pointArray.push(circle);
    lineArray.push(line);

    canvas.add(line);
    canvas.add(circle);
    canvas.selection = false;
  };

  const initCanvas = () => {
    console.log(
      "initCanvas",
      fabricImage,
      fabricRefV1.current,
      canvasRefV1.current,
      canvas
    );

    if (!fabricImage) {
      return;
    }

    if (!canvasRefV1.current) {
      console.log("554");
      return;
    }
    if (fabricRefV1.current) {
      console.log("558", fabricRefV1.current, fabricImage);
      return;
    }
    console.log("fabricRefV1.before #", fabricRefV1.current, canvas);
    if (!canvas) {
      console.log("&&&&&", canvas);
      canvas = new fabric.Canvas("canvas_div", {
        selection: false,
      });
    }

    fabricRefV1.current = canvas;
    console.log("fabricRefV1.before @", fabricRefV1.current, canvas);
    fabric.Image.fromURL(fabricImage, function (myImg) {
      myImg.set({
        id: "img",
        left: 0,
        top: 0,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        hasRotatingPoint: false,
        name: "image",
        objectType: "image",
      });
      console.log(
        "@@@@@ Image Height & Weight",
        myImg.getScaledHeight(),
        myImg.getScaledWidth()
      );
      myImg.scaleToHeight(myImg.getScaledHeight());
      myImg.scaleToWidth(myImg.getScaledWidth());
      canvas.add(myImg);
      canvas.setWidth(myImg.getScaledWidth());
      canvas.setHeight(myImg.getScaledHeight());

      console.log(
        "@@@@@ Canvas Height & Weight",
        canvas.getHeight(),
        canvas.getWidth()
      );
    });

    canvas.on("mouse:up", function (e) {
      if (!polyMode && !polygonMode && !polyEdit) {
        panning = false;
      }
    });

    canvas.on("mouse:down", function (e) {
      if (!polyMode && !polygonMode && !polyEdit) {
        panning = true;
      }
    });
    canvas.on("mouse:move", function (e) {
      if (!polyMode && !polygonMode && !polyEdit) {
        if (panning && e && e.e) {
          //   debugger;
          var delta = new fabric.Point(e.e.movementX, e.e.movementY);
          canvas.relativePan(delta);
        }
      }
    });
    console.log("mouse:down before");
    // console.log('fabricRefV1.current', fabricRefV1.current)
    canvas.on("mouse:down", function (options) {
      console.log(" ######## options.target", options.target);
      if (
        options.target &&
        pointArray.length > 0 &&
        options.target.id === pointArray[0].id
      ) {
        // const pointArrayCopy = JSON.parse(JSON.stringify(pointArray));
        generatePolygon(pointArray, true);
      }
      if (polygonMode) {
        const position = canvas.getPointer(options.e);
        console.log(
          "######## addPoint --- mousedown",
          position,
          position.x,
          position.y
        );
        addPoint(position);
      }
    });

    canvas.on("mouse:up", function (options) {
      console.log("mouse:up", options);
    });

    fabricRefV1.current.on("mouse:move", function (options) {});

    setTimeout(() => {
      redrawPoints((state && state.polygon) || []);

      // Draw the Plot Details
    }, 500);
  };

  const plus = () => {
    if (canvas.getZoom() < 2) {
      canvas.setZoom(canvas.getZoom() * 1.1);
    }
  };

  const minus = () => {
    if (canvas.getZoom() > 1) {
      canvas.setZoom(canvas.getZoom() / 1.1);
    }
  };

  const goLeft = () => {
    var units = 10;
    var delta = new fabric.Point(units, 0);
    canvas.relativePan(delta);
  };

  const goRight = () => {
    var units = 10;
    var delta = new fabric.Point(-units, 0);
    canvas.relativePan(delta);
  };

  const goUp = () => {
    var units = 10;
    var delta = new fabric.Point(0, units);
    canvas.relativePan(delta);
  };

  const goDown = () => {
    var units = 10;
    var delta = new fabric.Point(0, -units);
    canvas.relativePan(delta);
  };

  const handleClose = () => {
    setOpen(false);
    setAreaDetails({
      plot_type: 2,
    });
  };

  const handlePlotChange = (selectedPlot) => {
    setIsLoading(true);
    setUploadedPlotId(selectedPlot);
    const payload = { id: selectedPlot };
    XHR.post("realestate/plots/getuploadedplot", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.data && res.data.data.id) {
          delete res.data.data.id;
        }
        setAreaDetails({
          ...areaDetails,
          ...res.data.data,
          uploaded_id: selectedPlot,
        });
        // setUploadedPlots((res.data && res.data.data) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleChange = (key, value) => {
    setAreaDetails({ ...areaDetails, [key]: value });
  };

  const handleChangeComplete = (color, key) => {
    setAreaDetails({ ...areaDetails, [key]: color.hex });
    setShowLineColor(false);
    setShowFillColor(false);
  };

  const handleUpdate = () => {
    if (!areaDetails.no_of_sides) {
      areaDetails.no_of_sides = 4;
    }
    updatePlots(areaDetails);
    setAreaDetails({
      ...areaDetails,
      plot_type: 2,
    });
    setOpen(false);
  };

  const updatePlots = (data) => {
    setIsLoading(true);
    const payload = { ...data, drawn_type: 2, project_id: id };
    XHR.post("realestate/plots/update", payload).then(
      (res) => {
        setIsLoading(false);
        getPlotDetails(id);
        handleSnackClick();
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleAddPolygon = (data) => {
    setIsLoading(true);
    const payload = { ...data, drawn_type: 2, project_id: id };
    XHR.post("realestate/plots/add", payload).then(
      (res) => {
        setIsLoading(false);
        getPlotDetails(id);
        handleSnackClick();
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleClickOpen = (data, i) => {
    if (!data.plot_type) {
      data.plot_type = 2;
    }
    setAreaDetails({ ...data, isEdit: true });
    setOpen(true);
  };

  const onFileChange = (event) => {
    setIsLoading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "content-type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("type", "I");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.url) {
          updateImage(res.data && res.data.url);
        }
        setFabricImage((res.data && res.data.url) || null);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  function updateImage(image) {
    let XHRUrl = `${baseUrl}`;
    var xhr = new XMLHttpRequest();
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
      }
    });
    xhr.open("POST", `${XHRUrl}/realestate/updatelayoutimage`);
    xhr.setRequestHeader("content-type", "application/json");
    xhr.setRequestHeader("cache-control", "no-cache");

    xhr.send(
      JSON.stringify({
        id,
        image,
      })
    );
  }

  const handleRemoveArea = (plotId) => {
    setIsLoading(true);
    const payload = { id: plotId };
    XHR.post("realestate/plots/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          getPlotDetails(id);
          handleSnackClick();
          resetCanvas();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const resetCanvas = () => {
    // if(canvas) {
    //   canvas.clear();
    //   canvas = null;
    //   if(fabricRefV1 && fabricRefV1.current) {
    //     console.log('fabricRefV1.current is null')
    //     fabricRefV1.current = null;
    //   }
    //   initCanvas();
    // }
  };

  const getSoldStatus = (type) => {
    switch (type) {
      case 1:
        return "Sold";
      case 2:
        return "Booked";
      case 0:
        return "Un Sold";
      case 1:
        return "";
    }
  };

  const getPlotType = (type) => {
    switch (type) {
      case 1:
        return "Street";
      case 2:
        return "Plot";
      case 3:
        return "Shop";
      case 4:
        return "Park";
      case 5:
        return "Road";
      case 6:
        return "Commercial";
      default:
        return "";
    }
  };

  const getRupeesFormat = (amountValue = "") => {
    return (amountValue || "").toLocaleString("en-IN");
  };

  return (
    <div style={{ margin: 20 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6} lg={6}>
          <span style={{ fontWeight: "bold", fontSize: 20 }}>
            Project - {name}
          </span>
        </Grid>
        <Grid item xs={8} style={{ marginBottom: 20 }}>
          <input
            accept="image/*"
            className={classes.input}
            id="image-button-file"
            onChange={onFileChange}
            type="file"
          />
          <label htmlFor="image-button-file">
            <Button
              onClick={() => {
                // setUploadType("logo");
                // setOpenUpload(true);
              }}
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              component="span"
            >
              Upload Layout Image
            </Button>
          </label>
          <Button
            onClick={() => {
              setShowZoom(true);
            }}
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
            component="span"
          >
            Set Zoom
          </Button>
          <a
            style={{
              padding: 10,
              cursor: "pointer",
              background: polyMode ? "green" : "lightgrey",
              color: "white",
              borderRadius: 5,
            }}
            onClick={() => drawPolygon()}
          >
            Create Polygon
          </a>
          <div style={{ float: "right" }}>
            <AddCircleOutline
              onClick={plus}
              style={{ paddingTop: 5, color: "blueviolet" }}
            />
            <RemoveCircleOutline
              onClick={minus}
              style={{ paddingTop: 5, color: "blueviolet" }}
            />
            <ArrowBack
              onClick={goLeft}
              style={{ paddingTop: 5, color: "blueviolet" }}
            />
            <ArrowForward
              onClick={goRight}
              style={{ paddingTop: 5, color: "blueviolet" }}
            />
            <ArrowUpward
              onClick={goUp}
              style={{ paddingTop: 5, color: "blueviolet" }}
            />
            <ArrowDownward
              onClick={goDown}
              style={{ paddingTop: 5, color: "blueviolet" }}
            />
          </div>
        </Grid>
        <Grid item xs={4} md={4} lg={4} style={{ textAlign: "right" }}>
          {isPolyCopy && (
            <>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={() => pastePolygon()}
                color="primary"
              >
                Paste
              </Button>
              <Button
                variant="contained"
                style={{ marginRight: 10 }}
                onClick={() => cancelSavePolygon()}
                color="primary"
              >
                Cancel
              </Button>
            </>
          )}
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => history.goBack()}
            color="primary"
          >
            Back
          </Button>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={8}>
            <div
              style={{
                width: "100%",
                height: 600,
                overflow: "scroll",
              }}
            >
              <canvas id="canvas_div" ref={canvasRefV1} />
            </div>
            <img src={layout_image} alt="gfg" style={{ display: "none" }} />
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ width: "100%", bgcolor: "#e4edf0" }}>
              <List style={{ height: 600, overflowY: "auto" }}>
                {state.polygon &&
                  map(
                    state.polygon.filter((el) => el.drawn_type === 2),
                    (object, i) => {
                      return (
                        <div style={{ padding: 5 }}>
                          {console.log("el", object)}
                          <ListItem>
                            <ListItemIcon
                              style={{ minWidth: 30, marginLeft: 10 }}
                            >
                              {i + 1}
                            </ListItemIcon>
                            <ListItemText
                              primary={`${object.name} (${getSoldStatus(
                                object.plot_status
                              )}) (${getPlotType(object.plot_type)})`}
                            />
                            <ListItemSecondaryAction>
                              <Tooltip title="Edit">
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  style={{
                                    marginRight: 1,
                                    color:
                                      dragPolygon === object.id ? "red" : null,
                                  }}
                                >
                                  {!dragPolygon || dragPolygon !== object.id ? (
                                    <AppsIcon
                                      onClick={() => editPolygon(object.id)}
                                    />
                                  ) : (
                                    <SaveIcon
                                      onClick={() => savePolygon(object.id)}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  style={{ marginRight: 1 }}
                                >
                                  <DeleteIcon
                                    onClick={() => handleRemoveArea(object.id)}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Overlay">
                                <IconButton
                                  edge="end"
                                  aria-label="tooltips"
                                  style={{ marginRight: 1 }}
                                >
                                  <EditIcon
                                    onClick={() => handleClickOpen(object, i)}
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Copy">
                                <IconButton
                                  edge="end"
                                  aria-label="tooltips"
                                  style={{ marginRight: 1 }}
                                >
                                  <ContentCopyIcon
                                    onClick={() => copyPolygon(object.id)}
                                  />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      );
                    }
                  )}
              </List>
            </Box>
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ margin: 20 }}>
          <Grid item xs={8}>
            <DataGrid
              rows={rows}
              columns={columns}
              tableColumnExtensions={tableColumnExtensions}
              defaultHiddenColumnNames={defaultHiddenColumnNames}
              filteringStateColumnExtensions={filteringStateColumnExtensions}
              sortingStateColumnExtensions={sortingStateColumnExtensions}
              loadGrid={loadGrid}
              totalRecords={totalRecords}
              action={[]}
              // actionClick={actionClick}
            />
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        fullWidth
        size="md"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Area</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{ fontWeight: "bold", color: "#0000FF", fontSize: 18 }}
              >
                LAND TYPE
              </span>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={(areaDetails && areaDetails.plot_type) || 2}
                  onChange={(e) => handleChange("plot_type", e.target.value)}
                >
                  <MenuItem value={2}>Plot</MenuItem>
                  <MenuItem value={3}>Shop</MenuItem>
                  <MenuItem value={4}>Park</MenuItem>
                  <MenuItem value={5}>Road</MenuItem>
                  <MenuItem value={6}>Commercial</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6}>
              <span>Line color</span>
              <div
                onClick={() => setShowLineColor(true)}
                style={{
                  padding: 10,
                  width: 50,
                  background:
                    areaDetails && areaDetails.line_color
                      ? areaDetails.line_color
                      : "#fff",
                  border: `1px solid ${
                    areaDetails && areaDetails.line_color
                      ? areaDetails.line_color
                      : "#000"
                  }`,
                }}
              />

              {showLineColor && (
                <SketchPicker
                  color={
                    areaDetails && areaDetails.line_color
                      ? areaDetails.line_color
                      : "#000"
                  }
                  onChangeComplete={(val) =>
                    handleChangeComplete(val, "line_color")
                  }
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <span>Fill color</span>
              <div
                onClick={() => setShowFillColor(true)}
                style={{
                  padding: 10,
                  width: 50,
                  background:
                    areaDetails && areaDetails.fill_color
                      ? areaDetails.fill_color
                      : "#7ed321",
                  border: `1px solid ${
                    areaDetails && areaDetails.fill_color
                      ? areaDetails.fill_color
                      : "#000"
                  }`,
                }}
              />

              {showFillColor && (
                <SketchPicker
                  color={
                    areaDetails && areaDetails.fill_color
                      ? areaDetails.fill_color
                      : "#000"
                  }
                  onChangeComplete={(val) =>
                    handleChangeComplete(val, "fill_color")
                  }
                />
              )}
            </Grid> */}
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{ fontWeight: "bold", color: "#0000FF", fontSize: 18 }}
              >
                AREA
              </span>
            </Grid>
            {areaDetails && parseInt(areaDetails.plot_type) != 5 ? (
              <>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Select Plot
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={uploadedPlotId || null}
                      onChange={(e) => handlePlotChange(e.target.value)}
                    >
                      {uploadedPlots.map((el, i) => (
                        <MenuItem key={i} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    autoFocus
                    value={(areaDetails && areaDetails.name) || ""}
                    onChange={(e) => handleChange("name", e.target.value)}
                    margin="dense"
                    id="name"
                    label="Plot No"
                    type="name"
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={Math.round(areaDetails && areaDetails.area) || ""}
                    margin="dense"
                    id="area"
                    label="Total Area in sq feet"
                    type="text"
                    fullWidth
                    onChange={(e) => handleChange("area", e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`₹${
                      getRupeesFormat(areaDetails && areaDetails.per_s_feet) ||
                      ""
                    }`}
                    margin="dense"
                    id="per_s_feet"
                    label="Rate Per sq feet"
                    type="per_s_feet"
                    fullWidth
                    onChange={(e) => handleChange("per_s_feet", e.target.value)}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={`₹${
                      getRupeesFormat(areaDetails && areaDetails.cost) || ""
                    }`}
                    margin="dense"
                    id="cost"
                    label="Total Cost"
                    type="text"
                    fullWidth
                    onChange={(e) => handleChange("cost", e.target.value)}
                    disabled={true}
                  />
                </Grid>
                {/* <Grid item xs={6} style={{ marginTop: 10 }}>
                  <InputLabel>Plot Status</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    value={(areaDetails && areaDetails.plot_status) || 0}
                    disabled={true}
                    onChange={(e) =>
                      handleChange("plot_status", e.target.value)
                    }
                  >
                    <MenuItem value={1}>Sold</MenuItem>
                    <MenuItem value={0}>Unsold</MenuItem>
                    <MenuItem value={2}>Booked</MenuItem>
                  </Select>
                </Grid> */}
              </>
            ) : (
              <Grid item xs={6}>
                <TextField
                  value={(areaDetails && areaDetails.area) || ""}
                  margin="dense"
                  id="area"
                  label="Total Area in sq feet"
                  type="text"
                  fullWidth
                  onChange={(e) => handleChange("area", e.target.value)}
                />
              </Grid>
            )}
            {areaDetails && parseInt(areaDetails.plot_type) != 5 && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    // borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                />
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    PLOT SALES STATUS
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Plot Status</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    disabled={true}
                    value={(areaDetails && areaDetails.plot_status) || 0}
                    onChange={(e) =>
                      handleChange("plot_status", e.target.value)
                    }
                  >
                    <MenuItem value={1}>Sold</MenuItem>
                    <MenuItem value={0}>Unsold</MenuItem>
                    <MenuItem value={2}>Booked</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    CORNER PLOT
                  </span>
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset" style={{ marginTop: 10 }}>
                    <FormLabel component="legend">Is Corner Plot</FormLabel>
                    <RadioGroup
                      row
                      aria-label="is_corner"
                      name="is_corner"
                      value={
                        areaDetails && areaDetails.is_corner
                          ? areaDetails.is_corner
                          : "no"
                      }
                      onChange={(e) =>
                        handleChange("is_corner", e.target.value)
                      }
                    >
                      <FormControlLabel
                        value="yes"
                        disabled
                        control={<Radio color="primary" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        disabled
                        control={<Radio color="primary" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Plot Direction Facing
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled
                      value={(areaDetails && areaDetails.direction) || ""}
                      onChange={(e) =>
                        handleChange("direction", e.target.value)
                      }
                    >
                      <MenuItem value={1}>East</MenuItem>
                      <MenuItem value={2}>West</MenuItem>
                      <MenuItem value={3}>North</MenuItem>
                      <MenuItem value={4}>South</MenuItem>
                      <MenuItem value={5}>North East</MenuItem>
                      <MenuItem value={6}>North West</MenuItem>
                      <MenuItem value={7}>South East</MenuItem>
                      <MenuItem value={8}>South West</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    NO OF SIDES
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={(areaDetails && areaDetails.no_of_sides) || ""}
                    margin="dense"
                    id="no_of_sides"
                    label="Number of Sides"
                    type="no_of_sides"
                    fullWidth
                    onChange={(e) =>
                      handleChange("no_of_sides", e.target.value)
                    }
                    disabled
                  />
                </Grid>
                {areaDetails && areaDetails.no_of_sides > 0 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side1) || ""}
                      margin="dense"
                      id="direction"
                      label="Side A"
                      type="side1"
                      fullWidth
                      onChange={(e) => handleChange("side1", e.target.value)}
                      disabled
                    />
                  </Grid>
                )}
                {areaDetails && areaDetails.no_of_sides > 1 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side2) || ""}
                      margin="dense"
                      id="direction"
                      label="Side B"
                      type="side2"
                      fullWidth
                      onChange={(e) => handleChange("side2", e.target.value)}
                      disabled
                    />
                  </Grid>
                )}
                {areaDetails && areaDetails.no_of_sides > 2 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side3) || ""}
                      margin="dense"
                      id="direction"
                      label="Side C"
                      type="side3"
                      fullWidth
                      onChange={(e) => handleChange("side3", e.target.value)}
                      disabled
                    />
                  </Grid>
                )}
                {areaDetails && areaDetails.no_of_sides > 3 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side4) || ""}
                      margin="dense"
                      id="direction"
                      label="Side D"
                      type="side4"
                      fullWidth
                      onChange={(e) => handleChange("side4", e.target.value)}
                      disabled
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 4 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side5) || ""}
                      margin="dense"
                      id="direction"
                      label="Side E"
                      type="side5"
                      fullWidth
                      onChange={(e) => handleChange("side5", e.target.value)}
                      disabled
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 5 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side6) || ""}
                      margin="dense"
                      id="direction"
                      label="Side F"
                      type="side6"
                      fullWidth
                      onChange={(e) => handleChange("side6", e.target.value)}
                      disabled
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 6 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side7) || ""}
                      margin="dense"
                      id="direction"
                      label="Side G"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side7", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 7 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side8) || ""}
                      margin="dense"
                      id="direction"
                      label="Side H"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side8", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 8 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side9) || ""}
                      margin="dense"
                      id="direction"
                      label="Side I"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side9", e.target.value)}
                    />
                  </Grid>
                )}

                {areaDetails && areaDetails.no_of_sides > 9 && (
                  <Grid item xs={6}>
                    <TextField
                      value={(areaDetails && areaDetails.side10) || ""}
                      margin="dense"
                      id="direction"
                      label="Side J"
                      type="text1"
                      fullWidth
                      onChange={(e) => handleChange("side10", e.target.value)}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  style={{
                    marginTop: 10,
                    borderTop: "1px solid #c1c1c1",
                    paddingTop: 10,
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0000FF",
                      fontSize: 18,
                    }}
                  >
                    ROAD
                  </span>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Front</FormLabel>
                      <RadioGroup
                        row
                        aria-label="front_road"
                        name="front_road"
                        value={
                          areaDetails && areaDetails.front_road
                            ? areaDetails.front_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("front_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          disabled
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.front_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        value={
                          (areaDetails && areaDetails.front_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Front Road Length (In Feet)"
                        type="front_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("front_road_feet", e.target.value)
                        }
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Back</FormLabel>
                      <RadioGroup
                        row
                        aria-label="back_road"
                        name="back_road"
                        value={
                          areaDetails && areaDetails.back_road
                            ? areaDetails.back_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("back_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          disabled
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.back_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        value={
                          (areaDetails && areaDetails.back_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Back Road Length (In Feet)"
                        type="back_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("back_road_feet", e.target.value)
                        }
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Left</FormLabel>
                      <RadioGroup
                        row
                        aria-label="left_road"
                        name="left_road"
                        value={
                          areaDetails && areaDetails.left_road
                            ? areaDetails.left_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("left_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          disabled
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.left_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        value={
                          (areaDetails && areaDetails.left_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Left Road Length (In Feet)"
                        type="left_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("left_road_feet", e.target.value)
                        }
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControl component="fieldset" style={{ marginTop: 10 }}>
                      <FormLabel component="legend">Road in Right</FormLabel>
                      <RadioGroup
                        row
                        aria-label="right_road"
                        name="right_road"
                        value={
                          areaDetails && areaDetails.right_road
                            ? areaDetails.right_road
                            : "no"
                        }
                        onChange={(e) =>
                          handleChange("right_road", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="primary" />}
                          label="Yes"
                          disabled
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio color="primary" />}
                          label="No"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {areaDetails && areaDetails.right_road === "yes" && (
                    <Grid item xs={6}>
                      <TextField
                        value={
                          (areaDetails && areaDetails.right_road_feet) || ""
                        }
                        margin="dense"
                        id="direction"
                        label="Right Road Length (In Feet)"
                        type="right_road_feet"
                        fullWidth
                        onChange={(e) =>
                          handleChange("right_road_feet", e.target.value)
                        }
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            {/* <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: "1px solid #c1c1c1",
                paddingTop: 10,
              }}
            >
              <span
                style={{ fontWeight: "bold", color: "#0000FF", fontSize: 18 }}
              >
                DIRECTION FACING
              </span>
            </Grid> */}
            {/* <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Direction Facing
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={(areaDetails && areaDetails.direction) || ""}
                  onChange={(e) => handleChange("direction", e.target.value)}
                >
                  <MenuItem value={1}>East</MenuItem>
                  <MenuItem value={2}>West</MenuItem>
                  <MenuItem value={3}>North</MenuItem>
                  <MenuItem value={4}>South</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={polygonOpen}
        size="md"
        fullWidth
        onClose={() => {
          setPolygonOpen(false);
          setAreaDetails({
            ...areaDetails,
            isEdit: false,
          });
        }}
      >
        <DialogTitle id="form-dialog-title">Choose Edit</DialogTitle>
        <DialogContent>
          <Button
            onClick={() => {
              setPolygonOpen(false);
              editPolygon(areaDetails.id);
              setAreaDetails({
                ...areaDetails,
                isEdit: true,
              });
            }}
            color="primary"
            variant="contained"
          >
            Edit Polygon
          </Button>
          <Button
            onClick={() => {
              setPolygonOpen(false);
              handleClickOpen(areaDetails);
              setAreaDetails({
                ...areaDetails,
                isEdit: false,
              });
            }}
            color="primary"
            variant="contained"
            style={{ marginLeft: 10 }}
          >
            Edit Details
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAreaDetails({
                ...areaDetails,
                isEdit: false,
              });
              setPolygonOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showZoom}
        size="md"
        fullWidth
        onClose={() => {
          setShowZoom(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Zoom Level</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(data && data.fabric_min_zoom) || ""}
                margin="dense"
                id="fabric_min_zoom"
                label="Min Zoom"
                type="text"
                fullWidth
                onChange={(e) =>
                  handleInputChange("fabric_min_zoom", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(data && data.fabric_max_zoom) || ""}
                margin="dense"
                id="fabric_max_zoom"
                label="Max Zoom"
                type="text"
                fullWidth
                onChange={(e) =>
                  handleInputChange("fabric_max_zoom", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(data && data.default_zoom_level) || ""}
                margin="dense"
                id="default_zoom_level"
                label="Default Zoom Level"
                type="text"
                fullWidth
                onChange={(e) =>
                  handleInputChange("default_zoom_level", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(data && data.magnification) || ""}
                margin="dense"
                id="magnification"
                label="Magnification"
                type="text"
                fullWidth
                onChange={(e) =>
                  handleInputChange("magnification", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowZoom(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSaveZoom()} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message="Success"
        variant="success"
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </div>
  );
}

export default Poly;
