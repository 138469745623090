import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import XHR from '../../util/xhr';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Geocode from 'react-geocode';
import API_KEY from '../../util/config';

import AutoComplete from '../../components/AutoComplete';
import DrawMaps from '../../components/DrawMapsNew';
import Slide from '@material-ui/core/Slide';

import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { SketchPicker } from 'react-color';
import SaveIcon from '@material-ui/icons/Save';
import ReorderIcon from '@material-ui/icons/Reorder';

import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import AutocompleteComponent from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import { baseUrl } from '../../util/config';
import * as XLSX from 'xlsx';

import './Map.css';

Geocode.setApiKey(API_KEY);
Geocode.enableDebug();

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  input: {
    display: 'none',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Map = (props) => (
  <DrawMaps
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `500px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    gps_location={props.gps_location}
    onClick={props.onMapClick}
    isProject={props.isProject}
    zoom_level={props.zoom_level}
    onZoomChanged={props.onZoomChanged}
    drawingControlEnabled={props.drawingControlEnabled}
    overlay={props.overlay}
    polyline={props.polyline}
    show_boundary={props.show_boundary}
    boundary_color={props.boundary_color}
  />
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: '85%' }}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function UpdateClient() {
  const classes = useStyles();

  const history = useHistory();
  const { row = {} } = history.location.state;
  const {
    id = 0,
    name,
    user_id = '',
    gps_location = {},
    zoom_level = 18,
    polyline = [],
  } = row;

  const [isLoading, setIsLoading] = useState(false);
  const [allAmenities, setAllAmenties] = useState([]);
  const [allSalient, setAllSalient] = useState([]);
  const [allBanks, setAllBanks] = useState([]);

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [uploadObj, setUploadObj] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const [newBank, setNewBank] = useState('');
  const [newAmenities, setNewAmenities] = useState('');
  const [newSalient, setNewSalient] = useState('');

  const [shapes, setShapes] = useState([]);

  const [isNearby, setIsNearby] = useState(false);
  const [isNearbyType, setIsNearbyType] = useState(false);
  const [isStreetView, setIsStreetView] = useState(false);
  const [isFaq, setIsFaq] = useState(false);

  const [address, setAddress] = useState(null);
  const [location, setLocation] = useState(null);

  const [plots, setPlots] = useState([]);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [updateMoreData, setUpdateMoreData] = useState(null);

  const [data, setData] = useState({
    general_information: {
      project_status: '',
      price_range_from: '',
      price_range_to: '',
      project_size: '',
      started_year: '',
      rate_per_sq_feet_from: '',
      rate_per_sq_feet_to: '',
      description: '',
      layout: '',
    },
    amenities: [],
    salient: [],
    bank_details: [],
    gallery: [],
    about_company: {
      address: '',
      no_of_projects: 0,
      logo: '',
      description: '',
      dtcp_number: '',
      rera_no: '',
    },
    contact: [],
    property_type: [],
  });

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [value, setValue] = React.useState(0);
  const [updateData, setUpdateData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [allNearby, setAllNearby] = useState([]);
  const [allNearbyTypes, setAllNearbyTypes] = useState([]);
  const [allStreetView, setAllStreetView] = useState([]);
  const [faq, setFaq] = useState([]);

  const [showFillColor, setShowFillColor] = React.useState(false);
  const [showLineColor, setShowLineColor] = React.useState(false);

  const [cityPlpList, setCityPlpList] = useState([]);
  const [selectedCityPLPData, setSelectedCityPLPData] = useState([]);
  const [selectedCityPLPId, setSelectedCityPLPId] = useState([]);
  const [mappedCityPLP, setMappedCityPLP] = React.useState([]);

  useEffect(() => {
    getAllBanks();
    getAllAmenities();
    getAllSalient();
    getAllNearby();
    getAllNearbyType();
    getAllFAQ();
    loadData(id);
    getAllStreetView();
    getAllCityPlp();
    setSelectedCityPLPData([]);
    setMappedCityPLP([]);
    setSelectedCityPLPId([]);
  }, []);

  useEffect(() => {
    if (data && data.selectedCityPLPId) {
      setSelectedCityPLPId(data.selectedCityPLPId);
      setMappedCityPLP(data.mappedCityPLP);
    }
    setSelectedCityPLPData(
      cityPlpList.filter((el) => data.selectedCityPLPId && data.selectedCityPLPId.includes(el.id))
    );
  }, [data, cityPlpList]);

  useEffect(() => {
    setSelectedCityPLPData(
      cityPlpList.filter((el) => data.selectedCityPLPId && data.selectedCityPLPId.includes(el.id))
    );
  }, [cityPlpList]);

  console.log('$$$$ selectedCityPLPData', selectedCityPLPData);

  const getAllCityPlp = () => {
    setIsLoading(true);

    const payload = {};
    XHR.post('realestate/cityplp/list', payload).then(
      (res) => {
        setIsLoading(false);
        setCityPlpList((res.data && res.data.cityPlp) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const loadData = (refid) => {
    getPlotDetails(refid);
    getAddedPlots(refid);
  };

  useEffect(() => {
    if (data && data.gps_location && data.gps_location.lat) {
      Geocode.fromLatLng(data.gps_location.lat, data.gps_location.lng).then(
        (response) => {
          const address = response.results.find(
            (el) =>
              el.types.includes('street_address') || el.types.includes('route')
          );
          if (address) {
            setAddress({
              ...address,
              address_components: (address && address.address_components) || [],
              address: address && address.formatted_address,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [data && data.gps_location]);

  useEffect(() => {
    updatePlotsRows();
  }, [value]);

  const updatePlotsRows = () => {
    const plotsArr = (JSON.parse(JSON.stringify(plots)) || []).slice(
      0,
      data.total_plots
    );
    if (data && data.total_plots > 0) {
      for (let i = plotsArr.length; i < data.total_plots; i++) {
        plotsArr.push({
          sno: i + 1,
          name: `Plot ${i + 1}`,
          area: '',
          per_s_feet: '',
          plot_status: '',
          cost: '',
          direction: '',
          is_corner: '',
        });
      }
      setPlots(plotsArr);
    } else if (plots.length === 0) {
      setPlots([]);
    }
  };

  useEffect(() => {
    if (updateData && updateData.gps_location && updateData.gps_location.lat) {
      Geocode.fromLatLng(
        updateData.gps_location.lat,
        updateData.gps_location.lng
      ).then(
        (response) => {
          const address = response.results.find(
            (el) =>
              el.types.includes('street_address') || el.types.includes('route')
          );
          if (address) {
            setAddress({
              ...address,
              address_components: (address && address.address_components) || [],
              address: address && address.formatted_address,
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [updateData && updateData.gps_location]);

  useEffect(() => {
    if (value === 0) {
      setData({
        ...data,
        gps_location: location,
      });
    } else {
      setUpdateData({
        ...updateData,
        gps_location: location,
      });
    }
  }, [location]);

  const handleSaveBank = () => {
    setIsLoading(true);
    const payload = {
      name: newBank,
      image: '',
      id: updateData ? updateData.id : 0,
    };
    XHR.post('realestate/banks/save', payload).then(
      (res) => {
        setIsLoading(false);
        getAllBanks();
        // setAllBanks([...allBanks, newBank]);
        setUpdateData(null);
        setNewBank('');
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleSaveAmenities = () => {
    setIsLoading(true);
    const payload = {
      name: newAmenities,
      image: '',
      id: updateData ? updateData.id : 0,
    };
    XHR.post('realestate/amenities/save', payload).then(
      (res) => {
        setIsLoading(false);
        getAllAmenities();
        setUpdateData(null);
        // setAllAmenties([...allAmenities, newAmenities]);
        setNewAmenities('');
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  async function syncAllData() {
    allNearbyTypes.map((row, i) =>
      row.is_common ? syncNearByDetails(row) : null
    );
  }

  async function syncNearByDetails(nearData) {
    if (data && data.gps_location) {
      setIsLoading(true);
      const payload = {
        url: `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${data.gps_lat},${data.gps_long}&radius=${nearData.radius}&type=${nearData.name}&name=${nearData.name}&key=${API_KEY}`,
      };

      let response = await XHR.post('getnearby', payload).then((response) => {
        if (response.data && response.data.results) {
          return response.data.results;
        }
        return [];
      });
      const locations = [];

      for (let i = 0; i < response.length; i++) {
        // let address = '';
        // const address_payload = {
        //   url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${response[i]['geometry']['location']['lat']},${response[i]['geometry']['location']['lng']}&key=${API_KEY}`,
        // };

        // let address_response = await XHR.post(
        //   'getnearby',
        //   address_payload
        // ).then((address_response) => {
        //   if (address_response.data && address_response.data.results) {
        //     return address_response.data.results[0];
        //   }
        //   return [];
        // });

        // const distance_payload = {
        //   url: `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${response[i]['geometry']['location']['lat']},${response[i]['geometry']['location']['lng']}&destinations=${data.gps_lat},${data.gps_long}&mode=driving&key=${API_KEY}`,
        // };

        // let distance_response = await XHR.post(
        //   'getnearby',
        //   distance_payload
        // ).then((dis_response) => {
        //   console.log('dis_response', dis_response);
        //   if (
        //     dis_response.data &&
        //     dis_response.data.rows &&
        //     dis_response.data.rows.length > 0
        //   ) {
        //     return {
        //       distance_text:
        //         dis_response.data.rows[0].elements[0].distance.text,
        //       distance_value:
        //         dis_response.data.rows[0].elements[0].distance.value,
        //       duration_text:
        //         dis_response.data.rows[0].elements[0].duration.text,
        //       duration_value:
        //         dis_response.data.rows[0].elements[0].duration.value,
        //     };
        //   }
        //   return [];
        // });

        locations.push({
          gps: response[i]['geometry']['location'],
          icon: response[i]['icon'],
          name: response[i]['name'],
          // address: address_response['formatted_address'],
          // ...distance_response,
        });
      }

      handleAddNewNearByType({
        ...nearData,
        locations: locations,
        total_cnt: locations.length,
        project_id: id,
      });

      setIsLoading(false);
    }
  }

  const handleSaveSalient = () => {
    setIsLoading(true);
    const payload = {
      name: newSalient,
      image: '',
      id: updateData ? updateData.id : 0,
    };
    XHR.post('realestate/salient/save', payload).then(
      (res) => {
        setIsLoading(false);
        getAllSalient();
        setUpdateData(null);
        setNewSalient('');
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const getAllBanks = () => {
    setIsLoading(true);
    const payload = {};
    XHR.post('realestate/banks', payload).then(
      (res) => {
        setIsLoading(false);
        setAllBanks((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getAllAmenities = () => {
    setIsLoading(true);
    const payload = {};
    XHR.post('realestate/amenities', payload).then(
      (res) => {
        setIsLoading(false);
        setAllAmenties((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getAllSalient = () => {
    setIsLoading(true);
    const payload = {};
    XHR.post('realestate/salient', payload).then(
      (res) => {
        setIsLoading(false);
        setAllSalient((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getAllNearby = () => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post('realestate/nearby/get', payload).then(
      (res) => {
        setIsLoading(false);
        setAllNearby((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getAllNearbyType = () => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post('realestate/nearbytypes/get', payload).then(
      (res) => {
        setIsLoading(false);
        setAllNearbyTypes((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getAllFAQ = () => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post('realestate/api/faq/get', payload).then(
      (res) => {
        setIsLoading(false);
        setFaq((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getAddedPlots = () => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post('realestate/plots/getplots', payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.plots) {
          setPlots(res.data.plots);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post('realestate/get', payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.data) {
          setData(res.data.data);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleChange = (key1, key2, value) => {
    setData({
      ...data,
      [key1]: {
        ...data[key1],
        [key2]: value,
      },
    });
  };

  const handleContactChange = (key1, key2, index, value) => {
    let contactCopy = data.contact
      ? JSON.parse(JSON.stringify(data.contact))
      : [];
    contactCopy[index][key2] = value;
    setData({
      ...data,
      contact: contactCopy,
    });
  };

  const handleAddNewContact = () => {
    let contactCopy = data.contact
      ? JSON.parse(JSON.stringify(data.contact))
      : [];
    contactCopy.push({ name: '', mobile: '', email: '' });
    setData({
      ...data,
      contact: contactCopy,
    });
  };

  const handleRemoveContact = (index) => {
    let contactCopy = data.contact
      ? JSON.parse(JSON.stringify(data.contact))
      : [];
    if (index > -1) {
      contactCopy.splice(index, 1);
    }
    setData({
      ...data,
      contact: contactCopy,
    });
  };

  const handleCheckbox = (key, value) => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    const index = dataCopy[key] && dataCopy[key].indexOf(value);
    if (index > -1) {
      dataCopy[key].splice(index, 1); // 2nd parameter means remove one item only
    } else {
      dataCopy[key].push(value);
    }
    setData({
      ...data,
      [key]: dataCopy[key],
    });
  };

  const updateProject = () => {
    setIsLoading(true);
    if (value === 0 || value === 1 || value === 2 || value === 3) {
      handleNext();
    } else {
      if (value === 21) {
        const payload = { selectedCityPLPId, project_id: id, mappedCityPLP };
        XHR.post('realestate/cityplp/updatemapping', payload).then(
          (res) => {
            setIsLoading(false);
            handleSnackClick();
          },
          (err) => {
            setIsLoading(false);
            console.log('err', err);
          }
        );
      } else {
        const payload = { ...data, id };
        XHR.post('realestate/updateinfo', payload).then(
          (res) => {
            setIsLoading(false);
            handleSnackClick();
          },
          (err) => {
            setIsLoading(false);
            console.log('err', err);
          }
        );
      }
    }
  };

  const handleAddNewNearBy = () => {
    setIsLoading(true);
    const payload = { ...updateData, project_id: id };
    XHR.post('realestate/nearby/add', payload).then(
      (res) => {
        setIsLoading(false);
        setIsNearby(false);
        setUpdateData(null);
        getAllNearby();
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const getAllStreetView = () => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post('realestate/streetview/get', payload).then(
      (res) => {
        setIsLoading(false);
        setAllStreetView((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleAddNewStreetView = () => {
    setIsLoading(true);
    const payload = { ...updateData, project_id: id };
    XHR.post('realestate/streetview/add', payload).then(
      (res) => {
        setIsLoading(false);
        setIsStreetView(false);
        setUpdateData(null);
        getAllStreetView();
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleAddNewNearByType = (rowData) => {
    setIsLoading(true);
    const payload = rowData
      ? rowData
      : {
          ...updateData,
          radius: updateData.radius ? updateData.radius : 2000,
          project_id: id,
        };
    XHR.post('realestate/nearbytypes/add', payload).then(
      (res) => {
        setIsLoading(false);
        setIsNearbyType(false);
        setUpdateData(null);
        getAllNearbyType();
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleAddFAQ = (rowData) => {
    setIsLoading(true);
    const payload = rowData
      ? rowData
      : {
          ...updateData,
          project_id: id,
        };
    XHR.post('realestate/api/faq/add', payload).then(
      (res) => {
        setIsLoading(false);
        setIsFaq(false);
        setUpdateData(null);
        getAllFAQ();
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadType(null);
    setUploadObj(null);
    setUploadStatus(null);
    setUploading(false);
  };

  const onFileChange = (event) => {
    setUploadStatus(null);
    setUploading(false);
    setUploadObj(event.target.files[0]);
  };

  const onCustomFileChange = (event, key1, key2) => {
    setIsLoading(true);
    const files = event.target.files[0];
    const config = {
      headers: {
        Accept: 'application/json',
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append('file', files);
    formData.append('type', 'I');
    XHR.post('ads/upload', formData, config).then(
      (res) => {
        const payload = { id: key2, image: (res.data && res.data.url) || '' };
        XHR.post(`realestate/${key1}/update`, payload).then(
          (res) => {
            setIsLoading(false);
            if (key1 === 'banks') {
              getAllBanks();
            } else if (key1 === 'salient') {
              getAllSalient();
            } else {
              getAllAmenities();
            }
          },
          (err) => {
            setIsLoading(false);
            console.log('err', err);
          }
        );
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const onNearbyFileChange = (event) => {
    setIsLoading(true);
    const files = event.target.files[0];
    const config = {
      headers: {
        Accept: 'application/json',
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append('file', files);
    formData.append('type', 'I');
    XHR.post('ads/upload', formData, config).then(
      (res) => {
        setIsLoading(false);
        setUpdateData({
          ...updateData,
          image: (res.data && res.data.url) || '',
        });
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleConfirmUpload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const config = {
      headers: {
        Accept: 'application/json',
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append('file', uploadObj);
    formData.append('type', uploadType === 'image' ? 'I' : 'S');
    XHR.post('ads/upload', formData, config).then(
      (res) => {
        setUploading(false);
        setUploadStatus(res.data || null);
      },
      (err) => {
        setUploading(false);
        console.log('err', err);
      }
    );
  };

  const handlePreview = () => {
    if (uploadStatus && uploadStatus.url) {
      window.open(uploadStatus.url, '_blank');
    }
  };

  const handleImagePreview = (url) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleRemoveGallery = (index, type) => {
    const assets =
      data && data.gallery && data.gallery.filter((el) => el.type === type);

    const allAssets =
      data && data.gallery && data.gallery.filter((el) => el.type !== type);

    const dataCopy = JSON.parse(JSON.stringify(data));
    assets.splice(index, 1); // 2nd parameter means remove one item only
    setData({
      ...data,
      gallery: allAssets.concat(assets),
    });
  };

  const handleCheckGallery = (checked, index, imageassets = []) => {
    const videoassets =
      (data &&
        data.gallery &&
        data.gallery.filter((el) => el.type !== 'image')) ||
      [];
    imageassets[index]['isBanner'] = checked;
    setData({
      ...data,
      gallery: videoassets ? imageassets.concat(videoassets) : imageassets,
    });
  };

  const handleSave = () => {
    if (uploadType !== 'logo' && uploadType !== 'layout') {
      const dataCopy = JSON.parse(JSON.stringify(data));
      let assets = dataCopy.gallery || [];
      assets.push({ type: uploadType, url: uploadStatus.url });
      setData({
        ...data,
        gallery: assets,
      });
    } else if (uploadType === 'logo') {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          new_logo: uploadStatus.url,
        },
      });
    } else {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          layout: uploadStatus.url,
        },
      });
    }

    handleUploadClose();
  };

  const onBrochureFileChange = (event) => {
    setIsLoading(true);
    const files = event.target.files[0];
    const config = {
      headers: {
        Accept: 'application/json',
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append('file', files);
    formData.append('type', 'I');
    XHR.post('ads/upload', formData, config).then(
      (res) => {
        setIsLoading(false);
        setData({
          ...data,
          general_information: {
            ...data.general_information,
            brochure: (res.data && res.data.url) || false,
          },
        });
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleLocationClick = (gps) => {
    if (gps.lat) {
      var url = 'https://maps.google.com/?q=' + gps.lat + ',' + gps.lng;
      window.open(url, '_blank');
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleTabChange = (event, newValue) => {
    setUpdateData(null);
    setNewAmenities('');
    setNewBank('');
    setValue(newValue);
  };

  const handleEditClick = (el, type) => {
    if (type === 'banks') {
      setNewBank(el.name);
    } else if (type === 'salient') {
      setNewSalient(el.name);
    } else {
      setNewAmenities(el.name);
    }
    setUpdateData(el);
  };

  const handleDeleteClick = (el, type) => {
    setUpdateData({ ...el, type });
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData.id };
    let url = '';
    if (updateData.type === 'banks') {
      url = 'realestate/banks/delete';
    } else if (updateData.type === 'amenities') {
      url = 'realestate/amenities/delete';
    } else if (updateData.type === 'salient') {
      url = 'realestate/salient/delete';
    } else if (updateData.type === 'nearby') {
      url = 'realestate/nearby/delete';
    } else if (updateData.type === 'nearbytype') {
      url = 'realestate/nearbytypes/delete';
    } else if (updateData.type === 'faq') {
      url = 'realestate/api/faq/delete';
    } else if (updateData.type === 'street_view') {
      url = 'realestate/streetview/delete';
    }
    XHR.post(url, payload).then(
      (res) => {
        setIsLoading(false);
        getAllAmenities();
        getAllSalient();
        getAllBanks();
        getAllNearby();
        getAllNearbyType();
        getAllFAQ();
        getAllStreetView();
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const onMapClick = (e) => {
    if (value === 0) {
      setData({
        ...data,
        gps_location: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      });
    } else {
      setUpdateData({
        ...updateData,
        gps_location: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      });
    }
  };

  const onZoomChanged = (zoom) => {
    setData({
      ...data,
      zoom_level: zoom,
    });
  };

  const handleParentChange = (key, value) => {
    if (key == 'plp_price' || key == 'plp_price_unit') {
      let price = '';
      let unit = '';

      if (key == 'plp_price') {
        price = value;
        unit = data.plp_price_unit;
      } else if (key == 'plp_price_unit') {
        price = data.plp_price;
        unit = value;
      }

      let cent_price = 0;
      let sqft_price = 0;
      if (unit && price) {
        if (unit == 1) {
          cent_price = price;
          sqft_price = price / 435.56;
        } else if (unit == 2) {
          cent_price = price * 435.56;
          sqft_price = price;
        }
      }
      setData({
        ...data,
        [key]: value,
        ['plp_price_sq_ft']: sqft_price,
        ['plp_price_cent']: cent_price,
      });
    } else {
      setData({
        ...data,
        [key]: value,
      });
    }
  };

  const convertPrice = (price, unit) => {
    let cent_price = 0;
    let sqft_price = 0;
    if (unit && price) {
      if (unit == 1) {
        cent_price = price;
        sqft_price = price * 435.56;
      } else if (unit == 2) {
        cent_price = price * 0.0023;
        sqft_price = price;
      }
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `${baseUrl}/Sample_Plots.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getDirectionId = (text) => {
    switch (text) {
      case 'N':
        return 3;
      case 'S':
        return 4;
      case 'E':
        return 1;
      case 'W':
        return 2;
      case 'NE':
        return 5;
      case 'NW':
        return 6;
      case 'SE':
        return 7;
      case 'SW':
        return 8;
      default:
        return 1;
    }
  };

  const getPlotStatusId = (text) => {
    switch (text) {
      case 'A':
        return 0;
      case 'S':
        return 1;
      case 'B':
        return 2;
      default:
        return '';
    }
  };

  const onExcelChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const excelData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });

      let plotsArr = JSON.parse(JSON.stringify(plots));
      for (let i = 1; i < excelData.length; i++) {
        if (excelData[i][0]) {
          plotsArr.push({
            sno: i + 1,
            name: excelData[i][0],
            area: excelData[i][1],
            per_s_feet: excelData[i][2],
            cost:
              excelData[i][2] !== '' ? excelData[i][1] * excelData[i][2] : 0,
            is_corner: excelData[i][3],
            direction: getDirectionId(excelData[i][4]),
            plot_status: getPlotStatusId(excelData[i][5]),
            no_of_sides: excelData[i][6],
            side1: excelData[i][7],
            side2: excelData[i][8],
            side3: excelData[i][9],
            side4: excelData[i][10],
            side5: excelData[i][11],
            side6: excelData[i][12],
            side7: excelData[i][13],
            side8: excelData[i][14],
            side9: excelData[i][15],
            side10: excelData[i][16],
            front_road: excelData[i][17],
            front_road_feet: excelData[i][18],
            back_road: excelData[i][19],
            back_road_feet: excelData[i][20],
            left_road: excelData[i][21],
            left_road_feet: excelData[i][22],
            right_road: excelData[i][23],
            right_road_feet: excelData[i][24],
          });
        }
      }
      setPlots(plotsArr);
    };
    reader.readAsBinaryString(file);
  };

  const handleNext = () => {
    let rowCopy = row;

    let add = {
      state: '',
      street: '',
      town: '',
      zipcode: '',
      add1: '',
      add2: '',
      city: '',
      country: '',
    };

    let userDetailsString = localStorage.getItem('realestate_user') || '';
    let userDetails =
      userDetailsString != '' ? JSON.parse(userDetailsString) : null;

    const addressComponents = (address && address.address_components) || [];

    const zipcode = addressComponents.find((el) =>
      el.types.includes('postal_code')
    );
    if (zipcode) {
      add.zipcode = zipcode.long_name || '';
    } else {
      add.zipcode = (data && data.zipcode) || '';
    }

    const country = addressComponents.find((el) =>
      el.types.includes('country')
    );
    if (country) {
      add.country = country.long_name || '';
    } else {
      add.country = (data && data.country) || '';
    }

    const state = addressComponents.find((el) =>
      el.types.includes('administrative_area_level_1')
    );
    if (state) {
      add.state = state.long_name || '';
    } else {
      add.state = (data && data.state) || '';
    }

    const city = addressComponents.find((el) =>
      el.types.includes('administrative_area_level_2')
    );
    if (city) {
      add.city = city.long_name || '';
    } else {
      add.city = (data && data.city) || '';
    }

    const town = addressComponents.find((el) => el.types.includes('locality'));
    if (town) {
      add.town = town.long_name || '';
    } else {
      add.town = (data && data.town) || '';
    }

    const add1 = addressComponents.find((el) =>
      el.types.includes('sublocality')
    );
    if (add1) {
      add.add1 = add1.long_name || '';
    } else {
      add.add1 = (data && data.add1) || '';
    }

    const street = addressComponents.find(
      (el) => el.types.includes('premise') || el.types.includes('route')
    );
    if (street) {
      add.street = street.long_name || '';
    } else {
      add.street = (data && data.street) || '';
    }

    if (data && data.id) {
      updateProjectData({
        ...data,
        ...add,
        user_id,
      });
    } else {
      addProject({
        ...data,
        ...add,
        user_id,
      });
    }
    // setData({...data, ...add});
  };

  const updateProjectData = (addData2) => {
    setIsLoading(true);
    const payload1 = JSON.parse(JSON.stringify({ ...addData2 }));

    XHR.post('realestate/update', payload1).then(
      (res) => {
        setIsLoading(false);
        handleSnackClick();
        loadData(id);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const updateZoom = () => {
    setIsLoading(true);
    const payload1 = {
      zoom_level: data.zoom_level,
      id: data.id,
    };
    XHR.post('realestate/updatezoom', payload1).then(
      (res) => {
        setIsLoading(false);
        handleSnackClick();
        loadData(id);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const addProject = (addData1) => {
    setIsLoading(true);
    const payload2 = JSON.parse(JSON.stringify({ ...addData1 }));
    XHR.post('realestate/add', payload2).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          setData({
            ...data,
            id: res.data && res.data.id,
          });
          handleSnackClick();
          loadData(res.data.id);
          history.push('updateclient', { row: { ...res.data, user_id } });
        } else {
          alert('Something went wrong');
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleSwitchChange = (event, key) => {
    setData({ ...data, [key]: event.target.checked ? 1 : 0 });
  };

  const handleChangeComplete = (color, key) => {
    setData({ ...data, [key]: color.hex });
    setShowLineColor(false);
    setShowFillColor(false);
  };

  const overlay = (e) => {
    const shape = e.overlay;
    shape.type = e.type;

    setShapes([...shapes, shape]);

    switch (e.type) {
      case 'marker':
        setData({
          ...data,
          gps_location: {
            lat: e.overlay.getPosition().lat(),
            lng: e.overlay.getPosition().lng(),
          },
        });
        break;
      case 'polyline':
        setData({
          ...data,
          polyline: getAllPolygon(e.overlay.getPath().getArray()),
        });
        break;
      case 'circle':
        setData({
          ...data,
          circleRadius: e.overlay.getRadius(),
          circleCenter: e.overlay.getCenter(),
        });
        break;
      case 'rectangle':
        setData({
          ...data,
          rectangle: e.overlay.getBounds(),
        });

        break;
      case 'polygon':
        setData({
          ...data,
          polygon: getAllPolygon(e.overlay.getPath().getArray()),
        });
        break;
    }
  };

  const getAllPolygon = (currentPolygon) => {
    let newPolygon = [];
    for (let i = 0; i < currentPolygon.length; i++) {
      newPolygon.push({
        lat: currentPolygon[i].lat(),
        lng: currentPolygon[i].lng(),
      });
    }
    return [{ gps: newPolygon }];
  };

  const handlePlotsChange = (key, index, value) => {
    const plotsArr = JSON.parse(JSON.stringify(plots));
    plotsArr[index][key] = value;
    if (key === 'area' || key === 'per_s_feet') {
      const area = plotsArr[index]['area'] || 0;
      const perPrice = plotsArr[index]['per_s_feet'] || 0;
      plotsArr[index]['cost'] = Math.round(area * perPrice);
    }
    setPlots(plotsArr);
  };

  const handleSavePlot = (row, i) => {
    if (row.id) {
      updateUploadedPlot(row, i);
    } else {
      addUploadedPlot(row, i);
    }
  };

  const handleUpdateMore = () => {
    handleSavePlot(updateMoreData, updateMoreData.index);
    const plotsArr = JSON.parse(JSON.stringify(plots));
    plotsArr[updateMoreData.index] = updateMoreData;
    setPlots(plotsArr);
  };

  const handleMoreDataChange = (key, value) => {
    setUpdateMoreData({
      ...updateMoreData,
      [key]: value,
    });
  };

  const updatePlotsId = (index, id) => {
    const plotsArr = JSON.parse(JSON.stringify(plots));
    plotsArr[index]['id'] = id;
    setPlots(plotsArr);
  };

  const addUploadedPlot = (addData, i) => {
    setIsLoading(true);
    const payload = { ...addData, project_id: id };
    XHR.post('realestate/plots/adduploaded', payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          handleSnackClick();
          updatePlotsId(i, res.data.id);
          setOpenMoreInfo(false);
        } else {
          alert('Something went wrong');
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const updateUploadedPlot = (addData, i) => {
    setIsLoading(true);
    const payload = { ...addData, project_id: id };
    XHR.post('realestate/plots/updateuploaded', payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          handleSnackClick();
          setOpenMoreInfo(false);
        } else {
          alert('Something went wrong');
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const deleteUploadedPlot = (el) => {
    setIsLoading(true);
    if (!el.id) {
      setIsLoading(false);
      return;
    }
    const payload = { id: el.id };
    XHR.post('realestate/plots/deleteuploaded', payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.id) {
          handleSnackClick();
        } else {
          alert('Something went wrong');
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleRemovePlots = (index, el) => {
    let plotsCopy = JSON.parse(JSON.stringify(plots));
    if (index > -1) {
      plotsCopy.splice(index, 1);
    }
    setPlots(plotsCopy);
    deleteUploadedPlot(el);
  };

  const getRupeesFormat = (amountValue = '') => {
    return (amountValue || '').toLocaleString('en-IN');
  };

  const handleToggle = (id, value) => () => {
    const currentIndex = mappedCityPLP.indexOf(id + '_' + value);
    const newMappedCityPLP = [...mappedCityPLP];

    if (currentIndex === -1) {
      newMappedCityPLP.push(id + '_' + value);
    } else {
      newMappedCityPLP.splice(currentIndex, 1);
    }
    setMappedCityPLP(newMappedCityPLP);
  };

  const dtcpNumberInfo = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={
              (data &&
                data.general_information &&
                data.general_information.dtcp_number) ||
              ''
            }
            onChange={(event) =>
              handleChange(
                'general_information',
                'dtcp_number',
                event.target.value
              )
            }
            label='DTCP Number'
            style={{ marginRight: 10 }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={
              (data &&
                data.general_information &&
                data.general_information.rera_no) ||
              ''
            }
            onChange={(event) =>
              handleChange('general_information', 'rera_no', event.target.value)
            }
            label='RERA Number'
            style={{ marginRight: 10 }}
          />
        </Grid>
      </Grid>
    );
  };

  const generalInformation = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-label'>
              Project Status
            </InputLabel>
            <Select
              fullWidth
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={
                data &&
                data.general_information &&
                data.general_information.project_status
              }
              onChange={(event) =>
                handleChange(
                  'general_information',
                  'project_status',
                  event.target.value
                )
              }
            >
              <MenuItem value={'ongoing'}>Ongoing</MenuItem>
              <MenuItem value={'completed'}>Completed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={
              (data &&
                data.general_information &&
                data.general_information.started_year) ||
              ''
            }
            onChange={(event) =>
              handleChange(
                'general_information',
                'started_year',
                event.target.value
              )
            }
            label='Started year'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            value={
              (data &&
                data.general_information &&
                data.general_information.description) ||
              ''
            }
            onChange={(event) =>
              handleChange(
                'general_information',
                'description',
                event.target.value
              )
            }
            rows='3'
            fullWidth
            label='Description'
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl component='fieldset'>
            <RadioGroup
              row
              aria-label='otp_url'
              name='otp_url'
              value={
                (data &&
                  data.general_information &&
                  data.general_information.otp_url) ||
                0
              }
              onChange={(e) =>
                handleChange(
                  'general_information',
                  'otp_url',
                  parseInt(e.target.value)
                )
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color='primary' />}
                label='OTP Url Yes'
              />
              <FormControlLabel
                value={0}
                control={<Radio color='primary' />}
                label='OTP Url No'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const amenities = () => {
    return (
      <>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          style={{ borderBottom: '1px solid #000', paddingBottom: 20 }}
        >
          <Grid item xs={4}>
            <TextField
              id='amenities_standard-basic'
              fullWidth
              value={newAmenities}
              onChange={(e) => {
                setNewAmenities(e.target.value);
              }}
              label='Amenities'
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              onClick={() => {
                handleSaveAmenities();
              }}
              color='secondary'
            >
              {updateData ? 'Update' : 'Add'}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} style={{ marginTop: 20 }}>
          {(allAmenities || []).map((el) => (
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  value={el.id}
                  control={
                    <Checkbox
                      checked={data && data.amenities.includes(String(el.id))}
                      onChange={(event) =>
                        handleCheckbox('amenities', String(el.id))
                      }
                      color='primary'
                    />
                  }
                  label={el.name}
                  labelPlacement='end'
                />
              </Grid>
              <Grid item xs={8}>
                <div style={{ display: 'flex' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleEditClick(el, 'ameneties')}
                    style={{ marginLeft: 10 }}
                    component='span'
                  >
                    Edit
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleDeleteClick(el, 'ameneties')}
                    style={{ marginLeft: 10 }}
                    component='span'
                  >
                    Delete
                  </Button>
                  <input
                    accept='image/*'
                    className={classes.input}
                    id={el.name}
                    onChange={(e) => onCustomFileChange(e, 'amenities', el.id)}
                    type='file'
                  />

                  <label htmlFor={el.name}>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                      style={{ marginLeft: 10 }}
                    >
                      Upload Image
                    </Button>
                  </label>
                  {el.image && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleImagePreview(el.image || '')}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Preview Image
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const renderSalient = () => {
    return (
      <>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          style={{ borderBottom: '1px solid #000', paddingBottom: 20 }}
        >
          <Grid item xs={4}>
            <TextField
              id='salient_standard-basic'
              fullWidth
              value={newSalient}
              onChange={(e) => {
                setNewSalient(e.target.value);
              }}
              label='Salient'
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              onClick={() => {
                handleSaveSalient();
              }}
              color='secondary'
            >
              {updateData ? 'Update' : 'Add'}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} style={{ marginTop: 20 }}>
          {(allSalient || []).map((el) => (
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  value={el.id}
                  control={
                    <Checkbox
                      checked={data && data.salient.includes(String(el.id))}
                      onChange={(event) =>
                        handleCheckbox('salient', String(el.id))
                      }
                      color='primary'
                    />
                  }
                  label={el.name}
                  labelPlacement='end'
                />
              </Grid>
              <Grid item xs={8}>
                <div style={{ display: 'flex' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleEditClick(el, 'salient')}
                    style={{ marginLeft: 10 }}
                    component='span'
                  >
                    Edit
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleDeleteClick(el, 'salient')}
                    style={{ marginLeft: 10 }}
                    component='span'
                  >
                    Delete
                  </Button>
                  <input
                    accept='image/*'
                    className={classes.input}
                    id={el.name}
                    onChange={(e) => onCustomFileChange(e, 'salient', el.id)}
                    type='file'
                  />

                  <label htmlFor={el.name}>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                      style={{ marginLeft: 10 }}
                    >
                      Upload Image
                    </Button>
                  </label>
                  {el.image && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleImagePreview(el.image || '')}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Preview Image
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const bankDetails = () => {
    return (
      <>
        <Grid
          container
          spacing={1}
          item
          xs={12}
          style={{ borderBottom: '1px solid #000', paddingBottom: 20 }}
        >
          <Grid item xs={4}>
            <TextField
              id='bank_standard-basic'
              fullWidth
              value={newBank}
              onChange={(e) => {
                setNewBank(e.target.value);
              }}
              label='Bank Name'
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              onClick={() => {
                handleSaveBank();
              }}
              color='secondary'
            >
              {updateData ? 'Update' : 'Add'}
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} style={{ marginTop: 20 }}>
          {(allBanks || []).map((el) => (
            <Grid container item xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  value={el.id}
                  control={
                    <Checkbox
                      checked={
                        data && data.bank_details.includes(String(el.id))
                      }
                      onChange={(event) =>
                        handleCheckbox('bank_details', String(el.id))
                      }
                      color='primary'
                    />
                  }
                  label={el.name}
                  labelPlacement='end'
                />
              </Grid>
              <Grid item xs={8}>
                <div style={{ display: 'flex' }}>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleEditClick(el, 'banks')}
                    style={{ marginLeft: 10 }}
                    component='span'
                  >
                    Edit
                  </Button>
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleDeleteClick(el, 'banks')}
                    style={{ marginLeft: 10 }}
                    component='span'
                  >
                    Delete
                  </Button>
                  <input
                    accept='image/*'
                    className={classes.input}
                    id={el.name}
                    onChange={(e) => onCustomFileChange(e, 'banks', el.id)}
                    type='file'
                  />

                  <label htmlFor={el.name}>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                      style={{ marginLeft: 10 }}
                    >
                      Upload Image
                    </Button>
                  </label>
                  {el.image && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleImagePreview(el.image || '')}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Preview Image
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const renderImages = () => {
    return (
      <>
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={2} md={2} lg={2}>
            <Button
              onClick={() => {
                setUploadType('image');
                setOpenUpload(true);
              }}
              variant='contained'
              color='primary'
              component='span'
            >
              Upload Image
            </Button>
          </Grid>
        </Grid>
        {renderPreviewGallery('image')}
      </>
    );
  };

  const renderVideos = () => {
    return (
      <>
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={6}>
            <TextField
              id='standard-basic'
              fullWidth
              value={(uploadStatus && uploadStatus.url) || ''}
              onChange={(e) => {
                setUploadType('youtube');
                setUploadStatus({ url: e.target.value });
              }}
              label='Youtube url'
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              variant='contained'
              onClick={() => {
                handleSave();
              }}
              color='primary'
            >
              Add
            </Button>
          </Grid>
        </Grid>
        {renderPreviewGallery('youtube')}
      </>
    );
  };

  const renderLayout = () => {
    return (
      <>
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={4} md={4} lg={4}>
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  setUploadType('layout');
                  setOpenUpload(true);
                }}
                variant='contained'
                color='primary'
                component='span'
                style={{ marginRight: 10 }}
              >
                Upload Layout Diagram
              </Button>
              {data &&
              data.general_information &&
              data.general_information.layout ? (
                <a
                  href={data.general_information.layout}
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    textDecoration: 'underline',
                  }}
                  target='_blank'
                >
                  View Layout
                </a>
              ) : null}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} style={{ marginTop: 20 }}>
          <Grid item xs={4} md={4} lg={4}>
            <div style={{ display: 'flex' }}>
              <label htmlFor='brochure-button-file'>
                <Button variant='contained' color='primary' component='span'>
                  Upload BROCHURE
                </Button>
              </label>
              <input
                accept='pdf/*'
                style={{ display: 'none' }}
                id='brochure-button-file'
                onChange={onBrochureFileChange}
                type='file'
              />
              {data &&
              data.general_information &&
              data.general_information.brochure ? (
                <a
                  href={data.general_information.brochure}
                  style={{
                    marginTop: 5,
                    marginLeft: 20,
                    marginBottom: 5,
                    textDecoration: 'underline',
                  }}
                  target='_blank'
                >
                  View Brochure
                </a>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1} item xs={12} style={{ marginTop: 20 }}>
          <Grid item xs={4} md={4} lg={4}>
            <div style={{ display: 'flex' }}>
              <Button
                onClick={() => {
                  setUploadType('logo');
                  setOpenUpload(true);
                }}
                variant='contained'
                color='primary'
                component='span'
                style={{ marginRight: 10 }}
              >
                Upload Logo
              </Button>
              {data &&
              data.general_information &&
              data.general_information.new_logo ? (
                <a
                  href={data.general_information.new_logo}
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    textDecoration: 'underline',
                  }}
                  target='_blank'
                >
                  View Logo
                </a>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderAboutCompany = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={
              (data && data.about_company && data.about_company.company_name) ||
              ''
            }
            onChange={(event) =>
              handleChange('about_company', 'company_name', event.target.value)
            }
            label='Company Name'
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            value={
              (data &&
                data.about_company &&
                data.about_company.no_of_projects) ||
              ''
            }
            onChange={(event) =>
              handleChange(
                'about_company',
                'no_of_projects',
                event.target.value
              )
            }
            label='Number of projects'
            style={{ marginRight: 10 }}
          />
        </Grid>
        <Grid item xs={3}>
          <div style={{ display: 'flex' }}>
            <Button
              onClick={() => {
                setUploadType('logo');
                setOpenUpload(true);
              }}
              variant='contained'
              color='primary'
              component='span'
            >
              Upload Logo
            </Button>
            {data && data.about_company && data.about_company.logo ? (
              <a
                href={data.about_company.logo}
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  textDecoration: 'underline',
                }}
                target='_blank'
              >
                View Logo
              </a>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows='5'
            value={
              (data && data.about_company && data.about_company.address) || ''
            }
            onChange={(event) =>
              handleChange('about_company', 'address', event.target.value)
            }
            fullWidth
            label='Address'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            rows='5'
            value={
              (data && data.about_company && data.about_company.description) ||
              ''
            }
            onChange={(event) =>
              handleChange('about_company', 'description', event.target.value)
            }
            fullWidth
            label='Description'
          />
        </Grid>
      </Grid>
    );
  };

  const renderNearByPlaces = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={3}>
          <Button
            onClick={() => {
              setUpdateData(null);
              setIsNearby(true);
            }}
            variant='contained'
            color='primary'
            component='span'
          >
            Add New
          </Button>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell align='left'>Name</TableCell>
                <TableCell align='left'>Type</TableCell>
                <TableCell align='left'>Location</TableCell>
                <TableCell align='left'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allNearby.map((row, i) => (
                <TableRow key={row.name}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell component='th' scope='row'>
                    {row.name}
                  </TableCell>
                  <TableCell align='left'>{row.type}</TableCell>
                  <TableCell align='left'>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleLocationClick(row.gps_location)}
                    >
                      Click to see
                    </div>
                  </TableCell>
                  <TableCell align='left'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setUpdateData(row);
                        setIsNearby(true);
                      }}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Edit
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleDeleteClick(row, 'nearby')}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderNearByTypes = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={3}>
          <Button
            onClick={() => {
              setUpdateData(null);
              setIsNearbyType(true);
            }}
            variant='contained'
            color='primary'
            component='span'
          >
            Add New
          </Button>
          <Button
            onClick={() => {
              syncAllData();
            }}
            variant='contained'
            color='primary'
            component='span'
            style={{ marginLeft: 20 }}
          >
            Sync All
          </Button>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell align='left'>Label</TableCell>
                <TableCell align='left'>Code</TableCell>
                <TableCell align='left'>Radius</TableCell>
                <TableCell align='left'>Show</TableCell>
                <TableCell align='left'>Count</TableCell>
                <TableCell align='left'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allNearbyTypes.map((row, i) => (
                <TableRow key={row.name}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell component='th' scope='row'>
                    {row.label}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {row.name}
                  </TableCell>
                  <TableCell align='left'>{row.radius}</TableCell>
                  <TableCell align='left'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={row && row.is_common}
                          onChange={(event) => {
                            handleAddNewNearByType({
                              ...row,
                              is_common: event.target.checked,
                              project_id: id,
                            });
                          }}
                          name='is_common'
                        />
                      }
                      label={row.is_common ? 'Yes' : 'No'}
                    />
                  </TableCell>
                  <TableCell align='left'>{row.total_cnt}</TableCell>
                  <TableCell align='left'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setUpdateData(row);
                        setIsNearbyType(true);
                      }}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Edit
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleDeleteClick(row, 'nearbytype')}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Delete
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        syncNearByDetails(row);
                      }}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Sync
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderAddPlots = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item container xs={6} style={{ marginBottom: 20 }}>
          <label htmlFor='excel-button-file'>
            <Button variant='contained' color='primary' component='span'>
              Upload Excel
            </Button>
          </label>
          <input
            accept='xlsx/*'
            style={{ display: 'none' }}
            id='excel-button-file'
            onChange={onExcelChange}
            type='file'
          />
          <Button
            variant='contained'
            color='primary'
            component='span'
            onClick={() => handleDownload()}
            style={{ marginLeft: 20 }}
          >
            Download Sample Excel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <FormControl component='fieldset'>
            <RadioGroup
              row
              aria-label='show_price'
              name='show_price'
              value={(data && data.show_price) || 0}
              onChange={(e) =>
                handleParentChange('show_price', parseInt(e.target.value))
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color='primary' />}
                label='Show Price'
              />
              <FormControlLabel
                value={0}
                control={<Radio color='primary' />}
                label='Hide Price'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={2} style={{ display: 'flex' }}>
            <span style={{ marginRight: 10, width: 50 }}>S.No</span>
            <span style={{ marginRight: 10 }}>Name</span>
          </Grid>
          <Grid item xs={2}>
            <span style={{ marginRight: 10 }}>Total Area (in sfeet)</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Per Sq Feet</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Total Cost</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Is Corner Plot?</span>
          </Grid>
          <Grid item xs={2}>
            <span style={{ marginRight: 10 }}>Plot Status</span>
          </Grid>
          <Grid item xs={2}>
            <span style={{ marginRight: 10 }}>Direction Facing</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Action</span>
          </Grid>
        </Grid>
        {plots &&
          plots.map((el, i) => (
            <Grid item container xs={12} style={{ marginTop: 20 }}>
              <Grid item xs={2} style={{ paddingRight: 20, display: 'flex' }}>
                <span
                  style={{ marginRight: 10, width: 55, alignSelf: 'center' }}
                >
                  {i + 1}
                </span>
                <TextField
                  name='name'
                  value={el.name || ''}
                  onChange={(event) =>
                    handlePlotsChange('name', i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <TextField
                  name='area'
                  value={Math.round(el.area) || ''}
                  onChange={(event) =>
                    handlePlotsChange('area', i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name='per_s_feet'
                  value={Math.round(el.per_s_feet) || ''}
                  onChange={(event) =>
                    handlePlotsChange('per_s_feet', i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <span style={{ marginRight: 10, width: 100 }}>
                  ₹{getRupeesFormat(el.cost || 0)}
                </span>
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <Select
                  labelId='demo-simple-select-label'
                  fullWidth
                  onChange={(event) =>
                    handlePlotsChange('is_corner', i, event.target.value)
                  }
                  value={el.is_corner || ''}
                >
                  <MenuItem value={'yes'}>Yes</MenuItem>
                  <MenuItem value={'no'}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <Select
                  labelId='demo-simple-select-label'
                  fullWidth
                  onChange={(event) =>
                    handlePlotsChange('plot_status', i, event.target.value)
                  }
                  value={el.plot_status}
                >
                  <MenuItem value={1}>Sold</MenuItem>
                  <MenuItem value={0}>Unsold</MenuItem>
                  <MenuItem value={2}>Booked</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <Select
                  labelId='demo-simple-select-label'
                  value={parseInt(el.direction) || ''}
                  fullWidth
                  onChange={(event) =>
                    handlePlotsChange('direction', i, event.target.value)
                  }
                >
                  <MenuItem value={1}>East</MenuItem>
                  <MenuItem value={2}>West</MenuItem>
                  <MenuItem value={3}>North</MenuItem>
                  <MenuItem value={4}>South</MenuItem>
                  <MenuItem value={5}>North East</MenuItem>
                  <MenuItem value={6}>North West</MenuItem>
                  <MenuItem value={7}>South East</MenuItem>
                  <MenuItem value={8}>South West</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <SaveIcon
                  title={'Save'}
                  onClick={() => handleSavePlot(el, i)}
                  style={{
                    fontSize: 20,
                    marginRight: 10,
                    marginTop: 5,
                    cursor: 'pointer',
                    color: el.id ? 'red' : '',
                  }}
                />
                {el.id && (
                  <ReorderIcon
                    title={'Update More'}
                    onClick={() => {
                      setUpdateMoreData({
                        ...el,
                        index: i,
                      });
                      setOpenMoreInfo(true);
                    }}
                    style={{
                      fontSize: 20,
                      marginRight: 10,
                      marginTop: 5,
                      cursor: 'pointer',
                      color: el.id ? 'red' : '',
                    }}
                  />
                )}

                <span
                  onClick={() => handleRemovePlots(i, el)}
                  style={{ cursor: 'pointer' }}
                >
                  X
                </span>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  };

  const renderSeoConfig = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <span style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
            Meta tag
          </span>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            value={(data && data.seo && data.seo.title) || ''}
            onChange={(event) =>
              handleChange('seo', 'title', event.target.value)
            }
            rows='3'
            fullWidth
            label='Title'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            value={(data && data.seo && data.seo.description) || ''}
            onChange={(event) =>
              handleChange('seo', 'description', event.target.value)
            }
            rows='3'
            fullWidth
            label='Description'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            value={(data && data.seo && data.seo.keywords) || ''}
            onChange={(event) =>
              handleChange('seo', 'keywords', event.target.value)
            }
            rows='3'
            fullWidth
            label='Keywords'
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <span style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
            URL Pattern
          </span>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={(data && data.seo && data.seo.state) || ''}
            onChange={(event) =>
              handleChange('seo', 'state', event.target.value)
            }
            fullWidth
            label='State'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={(data && data.seo && data.seo.district) || ''}
            onChange={(event) =>
              handleChange('seo', 'district', event.target.value)
            }
            fullWidth
            label='District'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={(data && data.seo && data.seo.taluk) || ''}
            onChange={(event) =>
              handleChange('seo', 'taluk', event.target.value)
            }
            fullWidth
            label='Taluk'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={(data && data.seo && data.seo.area1) || ''}
            onChange={(event) =>
              handleChange('seo', 'area1', event.target.value)
            }
            fullWidth
            label='Area Name 1'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={(data && data.seo && data.seo.area2) || ''}
            onChange={(event) =>
              handleChange('seo', 'area2', event.target.value)
            }
            fullWidth
            label='Area Name 2 (Optional)'
          />
        </Grid>
      </Grid>
    );
  };

  const renderEMI = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <FormControl component='fieldset' style={{ marginTop: 10 }}>
            <FormLabel component='legend'>EMI Available</FormLabel>
            <RadioGroup
              row
              aria-label='available'
              name='available'
              value={(data && data.emi && data.emi.available) || 'no'}
              onChange={(e) => handleChange('emi', 'available', e.target.value)}
            >
              <FormControlLabel
                value='yes'
                control={<Radio color='primary' />}
                label='Yes'
              />
              <FormControlLabel
                value='no'
                control={<Radio color='primary' />}
                label='No'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {data && data.emi && data.emi.available === 'yes' ? (
          <Grid item xs={4}>
            <TextField
              value={(data && data.emi && data.emi.amount) || 0}
              onChange={(event) =>
                handleChange('emi', 'amount', event.target.value)
              }
              fullWidth
              label='Amount'
            />
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const renderView = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={4}>
          <Checkbox
            checked={data.is_map_view_enable || false}
            onChange={(e) =>
              handleParentChange('is_map_view_enable', e.target.checked)
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          Map View
        </Grid>
        <Grid item xs={4}>
          <Checkbox
            checked={data.is_image_view_enable || false}
            onChange={(e) =>
              handleParentChange('is_image_view_enable', e.target.checked)
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          Image View
        </Grid>
        <Grid item xs={4}>
          <Checkbox
            checked={data.is_table_view_enable || false}
            onChange={(e) =>
              handleParentChange('is_table_view_enable', e.target.checked)
            }
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          Table View
        </Grid>
      </Grid>
    );
  };

  const renderContact = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={3}>
          <Button
            onClick={() => handleAddNewContact()}
            variant='contained'
            color='primary'
            component='span'
          >
            Add New
          </Button>
        </Grid>
        {data.contact &&
          data.contact.map((el, i) => (
            <Grid container spacing={1} item xs={12} key={i}>
              <Grid item xs={3}>
                <TextField
                  value={el.name || ''}
                  onChange={(event) =>
                    handleContactChange(
                      'contact',
                      'name',
                      i,
                      event.target.value
                    )
                  }
                  fullWidth
                  label='Name'
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={el.mobile || ''}
                  onChange={(event) =>
                    handleContactChange(
                      'contact',
                      'mobile',
                      i,
                      event.target.value
                    )
                  }
                  fullWidth
                  label='Mobile'
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  value={el.email || ''}
                  onChange={(event) =>
                    handleContactChange(
                      'contact',
                      'email',
                      i,
                      event.target.value
                    )
                  }
                  fullWidth
                  label='Email'
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  onClick={() => handleRemoveContact(i)}
                  variant='contained'
                  color='primary'
                  component='span'
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  };

  const renderFAQ = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={3}>
          <Button
            onClick={() => {
              setUpdateData(null);
              setIsFaq(true);
            }}
            variant='contained'
            color='primary'
            component='span'
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <FormControl component='fieldset'>
            <RadioGroup
              row
              aria-label='show_pdp_faq'
              name='show_pdp_faq'
              value={(data && data.show_pdp_faq) || 0}
              onChange={(e) =>
                handleParentChange('show_pdp_faq', parseInt(e.target.value))
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color='primary' />}
                label='Show In PDP'
              />
              <FormControlLabel
                value={0}
                control={<Radio color='primary' />}
                label='Hide In PDP'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell align='left'>Question</TableCell>
                <TableCell align='left'>Answer</TableCell>
                <TableCell align='left'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {faq.map((row, i) => (
                <TableRow key={row.id}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell component='th' scope='row'>
                    {row.question}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {row.answer}
                  </TableCell>
                  <TableCell align='left'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setUpdateData(row);
                        setIsFaq(true);
                      }}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Edit
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleDeleteClick(row, 'faq')}
                      style={{ margin: 10 }}
                      component='span'
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderPreviewGallery = (previewType) => {
    const assets =
      data &&
      data.gallery &&
      data.gallery.filter((el) => el.type === previewType);
    return (
      <>
        {assets && assets.length > 0 && (
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <span
              style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}
            >
              Preview Gallery{' '}
              {previewType === 'image'
                ? '(Checked images display in Banner section)'
                : ''}
            </span>
            <div>
              {(assets || []).map((el, i) => (
                <div
                  key={i}
                  style={{ display: 'flex', margin: 10, alignItems: 'center' }}
                >
                  {previewType === 'image' && (
                    <Checkbox
                      checked={el.isBanner || false}
                      onChange={(e) =>
                        handleCheckGallery(e.target.checked, i, assets)
                      }
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  )}
                  <a
                    href={el.url}
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      marginRight: 10,
                      textDecoration: 'underline',
                    }}
                    target='_blank'
                  >
                    {i + 1}.{previewType === 'youtube' ? el.url : el.type}
                  </a>
                  <span
                    onClick={() => handleRemoveGallery(i, previewType)}
                    style={{ cursor: 'pointer', marginTop: 5, marginBottom: 5 }}
                  >
                    Remove
                  </span>
                </div>
              ))}
            </div>
          </Grid>
        )}
      </>
    );
  };

  const chooseLocation = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <TextField
            value={(data && data.name) || ''}
            style={{ width: '100%' }}
            name='name'
            label='Project Name'
            onChange={(e) => handleParentChange('name', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <AutoComplete
            setLocation={(val) => {
              setLocation(val && val.coor);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {data && data.gps_location && (
            <Map
              gps_location={
                (data && data.gps_location) || {
                  lat: 13.086289405322379,
                  lng: 80.29239116173903,
                }
              }
              isProject={true}
              onMapClick={onMapClick}
              onZoomChanged={onZoomChanged}
              zoom_level={(data && data.zoom_level) || 18}
              drawingControlEnabled={false}
              polyline={(data && data.polyline) || []}
              show_boundary={data && data.show_boundary}
              boundary_color={data && data.boundary_color}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {data && data.gps_location && (
            <Button
              variant='contained'
              onClick={() => updateZoom()}
              color='primary'
            >
              Set Zoom Level
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const verifyAddress = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.street) || ''}
                  style={{ width: '80%' }}
                  name='street'
                  label='Street'
                  onChange={(e) => handleParentChange('street', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.add1) || ''}
                  style={{ width: '80%' }}
                  name='add1'
                  label='Address Line 1'
                  onChange={(e) => handleParentChange('add1', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.add2) || ''}
                  style={{ width: '80%' }}
                  name='add2'
                  label='Address Line 2'
                  onChange={(e) => handleParentChange('add2', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.town) || ''}
                  style={{ width: '80%' }}
                  label='village/Town name'
                  name='town'
                  onChange={(e) => handleParentChange('town', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.city) || ''}
                  style={{ width: '80%' }}
                  label='City'
                  name='city'
                  onChange={(e) => handleParentChange('city', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.taluk) || ''}
                  style={{ width: '80%' }}
                  label='Taluk'
                  name='taluk'
                  onChange={(e) => handleParentChange('taluk', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.district) || ''}
                  style={{ width: '80%' }}
                  label='District'
                  name='district'
                  onChange={(e) =>
                    handleParentChange('district', e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.state) || ''}
                  style={{ width: '80%' }}
                  label='State'
                  name='state'
                  onChange={(e) => handleParentChange('state', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.zipcode) || ''}
                  style={{ width: '80%' }}
                  label='zipcode'
                  name='zipcode'
                  onChange={(e) =>
                    handleParentChange('zipcode', e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.country) || ''}
                  style={{ width: '80%' }}
                  label='Country'
                  name='country'
                  onChange={(e) =>
                    handleParentChange('country', e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div>
                <TextField
                  value={(data && data.landmark) || ''}
                  style={{ width: '80%' }}
                  label='Landmark'
                  name='landmark'
                  onChange={(e) =>
                    handleParentChange('landmark', e.target.value)
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} style={{ marginTop: 10 }}>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleLocationClick(data.gps_location)}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                  }}
                >
                  Mapped Location:{' '}
                  {data && data.gps_location
                    ? `${data.gps_location.lat}, ${data.gps_location.lng}`
                    : ''}
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                value={(data && data.fulladdress) || ''}
                onChange={(e) =>
                  handleParentChange('fulladdress', e.target.value)
                }
                rows='3'
                fullWidth
                label='Full Address'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={(data && data.plp_address) || ''}
                onChange={(e) =>
                  handleParentChange('plp_address', e.target.value)
                }
                fullWidth
                label='PLP Address'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderLayoutStreetView = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <span style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
            Layout Street View URL
          </span>
        </Grid>
        <Grid item xs={6}>
          <TextField
            multiline
            value={(data && data.street_view_url) || ''}
            onChange={(e) =>
              handleParentChange('street_view_url', e.target.value)
            }
            rows='3'
            fullWidth
            label='URL'
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={3} style={{ marginTop: 10 }}>
          <Button
            onClick={() => {
              setUpdateData(null);
              setIsStreetView(true);
            }}
            variant='contained'
            color='primary'
            component='span'
          >
            Add New
          </Button>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell align='left'>Place Name</TableCell>
                <TableCell align='left'>Place Image ID</TableCell>
                <TableCell align='left'>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allStreetView.map((row, i) => (
                <TableRow key={row.name}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell component='th' scope='row'>
                    {row.place_name}
                  </TableCell>
                  <TableCell align='left'>{row.place_image_id}</TableCell>
                  <TableCell align='left'>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setUpdateData(row);
                        setIsStreetView(true);
                      }}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Edit
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={() => handleDeleteClick(row, 'street_view')}
                      style={{ marginLeft: 10 }}
                      component='span'
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderHeaderFooterView = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <span style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
            Header Footer View
          </span>
        </Grid>
        <Grid item xs={12}>
          <AutocompleteComponent
            multiple
            id='tags-standard'
            options={cityPlpList}
            value={cityPlpList.filter((el) =>
              selectedCityPLPId.includes(el.id)
            )}
            onChange={(event, newValue) => {
              setSelectedCityPLPId(newValue.map((el) => el.id));
              setSelectedCityPLPData(newValue.map((el) => el));
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                placeholder='City PLP'
              />
            )}
          />
        </Grid>
        <Grid container xs={12} style={{ marginTop: 10 }}>
          {selectedCityPLPData.map((data) => {
            return (
              <Grid item xs={3} style={{ marginTop: 10 }}>
                <List
                  subheader={
                    <ListSubheader component='div' id='nested-list-subheader'>
                      {data.name}
                    </ListSubheader>
                  }
                >
                  {[
                    'Plots',
                    'Villas',
                    'Row Houses',
                    'Farm House',
                    'Farm Land',
                  ].map((value, ind) => {
                    const labelId = `checkbox-list-label-${value}`;
                    return (
                      <ListItem
                        key={value}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(data.id, ind)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge='start'
                            checked={
                              mappedCityPLP.indexOf(data.id + '_' + ind) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value}`} />
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    );
  };

  const updateProjectDetails = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                value={(data && data.name) || ''}
                style={{ width: '100%' }}
                name='name'
                label='Project Name'
                onChange={(e) => handleParentChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                style={{ width: '100%' }}
                value={(data && data.total_area) || ''}
                onChange={(e) =>
                  handleParentChange('total_area', e.target.value)
                }
                label='Total Area'
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel id='demo-simple-select-label'>Area Type</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={(data && data.total_area_type) || ''}
                  onChange={(e) =>
                    handleParentChange('total_area_type', e.target.value)
                  }
                >
                  <MenuItem value={1}>In Acres</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                value={(data && data.total_plots) || ''}
                onChange={(e) =>
                  handleParentChange('total_plots', e.target.value)
                }
                label='Total Plots'
              />
            </Grid>
            {/* <Grid item xs={8}>
              <TextField
                style={{ width: "100%" }}
                value={(data && data.plp_price) || ""}
                onChange={(e) =>
                  handleParentChange("plp_price", e.target.value)
                }
                label="Enter Starting Price"
              />
            </Grid>
            <Grid item xs={4} style={{ marginTop: 16 }}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Enter Unit
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={(data && data.plp_price_unit) || ""}
                  onChange={(e) =>
                    handleParentChange("plp_price_unit", e.target.value)
                  }
                >
                  <MenuItem value={1}>cent</MenuItem>
                  <MenuItem value={2}>sq. ft.</MenuItem>
                  <MenuItem value={3}>sq. m</MenuItem>
                  <MenuItem value={4}>sq. yard</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                style={{ width: '100%' }}
                value={
                  data && data.gps_location
                    ? `${data.gps_location.lat}, ${data.gps_location.lng}`
                    : ''
                }
                disabled={true}
                onChange={(e) =>
                  handleParentChange('gps_location', e.target.value)
                }
                label='GPS Location'
              />
            </Grid>
            <Grid item xs={6}>
              <span>Boundary color</span>
              <div
                onClick={() => setShowLineColor(true)}
                style={{
                  padding: 10,
                  width: 50,
                  background:
                    data && data.boundary_color ? data.boundary_color : '#fff',
                  border: `1px solid ${
                    data && data.boundary_color ? data.boundary_color : '#000'
                  }`,
                }}
              />

              {showLineColor && (
                <SketchPicker
                  color={
                    data && data.boundary_color ? data.boundary_color : '#000'
                  }
                  onChangeComplete={(val) =>
                    handleChangeComplete(val, 'boundary_color')
                  }
                />
              )}
            </Grid>

            <Grid item xs={6}>
              <span>Show Boundary</span>
              <Switch
                checked={data && data.show_boundary ? data.show_boundary : 0}
                onChange={(event) => handleSwitchChange(event, 'show_boundary')}
                name='show_boundary'
                inputProps={{ 'aria-label': 'show_boundary' }}
              />
            </Grid>
            <Grid container item xs={12} spacing={1}>
              <FormControl
                sx={{ m: 3 }}
                style={{ marginTop: 20 }}
                component='fieldset'
                variant='standard'
              >
                <FormLabel style={{ color: 'black' }}>Property Type</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          data &&
                          data.property_type &&
                          data.property_type.includes(String(1))
                        }
                        onChange={(event) =>
                          handleCheckbox('property_type', String(1))
                        }
                        color='primary'
                        name='1'
                      />
                    }
                    label='Residential plots'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          data &&
                          data.property_type &&
                          data.property_type.includes(String(2))
                        }
                        onChange={(event) =>
                          handleCheckbox('property_type', String(2))
                        }
                        color='primary'
                        name='2'
                      />
                    }
                    label='Villas'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          data &&
                          data.property_type &&
                          data.property_type.includes(String(3))
                        }
                        onChange={(event) =>
                          handleCheckbox('property_type', String(3))
                        }
                        color='primary'
                        name='3'
                      />
                    }
                    label='Farm Lands'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          data &&
                          data.property_type &&
                          data.property_type.includes(String(4))
                        }
                        onChange={(event) =>
                          handleCheckbox('property_type', String(4))
                        }
                        color='primary'
                        name='4'
                      />
                    }
                    label='Farm House'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          data &&
                          data.property_type &&
                          data.property_type.includes(String(5))
                        }
                        onChange={(event) =>
                          handleCheckbox('property_type', String(5))
                        }
                        color='primary'
                        name='5'
                      />
                    }
                    label='Gated Community'
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          data &&
                          data.property_type &&
                          data.property_type.includes(String(6))
                        }
                        onChange={(event) =>
                          handleCheckbox('property_type', String(6))
                        }
                        color='primary'
                        name='6'
                      />
                    }
                    label='Commercial Land'
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Map
            gps_location={(data && data.gps_location) || null}
            zoom_level={(data && data.zoom_level) || 18}
            isProject={true}
            onMapClick={onMapClick}
            onZoomChanged={() => {}}
            overlay={overlay}
            polyline={(data && data.polyline) || null}
            show_boundary={(data && data.show_boundary) || 0}
            boundary_color={(data && data.boundary_color) || ''}
          />
        </Grid>
      </Grid>
    );
  };

  const updatePlotPrice = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <span
                style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}
              >
                Plot Price & Unit
              </span>
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                value={(data && data.plp_price) || ''}
                onChange={(e) => {
                  handleParentChange('plp_price', e.target.value);
                }}
                label='Enter Plot Price'
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 16 }}>
              <FormControl className={classes.formControl}>
                <InputLabel id='demo-simple-select-label'>
                  Enter Unit
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={(data && data.plp_price_unit) || ''}
                  onChange={(e) => {
                    handleParentChange('plp_price_unit', e.target.value);
                  }}
                >
                  <MenuItem value={1}>cent</MenuItem>
                  <MenuItem value={2}>sq. ft.</MenuItem>
                  {/* <MenuItem value={3}>sq. m</MenuItem>
                  <MenuItem value={4}>sq. yard</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginTop: 20 }}>
              <span
                style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}
              >
                Price in sq.ft : ₹
                {(data &&
                  parseFloat(data.plp_price_sq_ft).toLocaleString('en-IN')) ||
                  0}
              </span>
            </Grid>
            <Grid item xs={6} style={{ marginTop: 20 }}>
              <span
                style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}
              >
                Price in cent : ₹
                {(data &&
                  parseFloat(data.plp_price_cent).toLocaleString('en-IN')) ||
                  0}
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <span
                style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}
              >
                Plot Min & Max Price
              </span>
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                value={(data && data.plot_min_price) || ''}
                onChange={(e) =>
                  handleParentChange('plot_min_price', e.target.value)
                }
                label='Enter Min Plot Price'
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                style={{ width: '100%' }}
                value={(data && data.plot_max_price) || ''}
                onChange={(e) =>
                  handleParentChange('plot_max_price', e.target.value)
                }
                label='Enter Max Plot Price'
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          <span style={{ fontWeight: 'bold', fontSize: 20 }}>
            Project - {name}
          </span>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right', marginBottom: 10 }}>
          <Button
            variant='contained'
            style={{ marginRight: 10 }}
            onClick={() => history.replace('/', {})}
            color='primary'
          >
            Back
          </Button>
          <Button
            variant='contained'
            onClick={() => updateProject()}
            color='primary'
          >
            Update Details
          </Button>
        </Grid>
        <Grid container spacing={1}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={value}
            onChange={handleTabChange}
            aria-label='Vertical tabs example'
            className={classes.tabs}
            style={{ height: 900, overflowY: 'scroll' }}
          >
            <Tab label='1. Choose Location' {...a11yProps(0)} />
            <Tab label='2. Verify Address' {...a11yProps(1)} />
            <Tab label='3. Project Details' {...a11yProps(2)} />
            <Tab label='4. Plot price' {...a11yProps(3)} />
            <Tab label='5. Add Plots' {...a11yProps(4)} />
            <Tab label='6. General information' {...a11yProps(5)} />
            <Tab label='7. DTCP Number' {...a11yProps(6)} />
            <Tab label='8. Amenities' {...a11yProps(7)} />
            <Tab label='9. Salient' {...a11yProps(8)} />
            <Tab label='10. Bank details' {...a11yProps(9)} />
            <Tab label='11. Images' {...a11yProps(10)} />
            <Tab label='12. Videos' {...a11yProps(11)} />
            <Tab label='13. Layout diagram' {...a11yProps(12)} />
            {/* <Tab label="13. About the company" {...a11yProps(12)} /> */}
            <Tab label='14. Contact' {...a11yProps(13)} />
            <Tab label='15. Near By Places' {...a11yProps(14)} />
            <Tab label='16. Near By Types' {...a11yProps(15)} />
            <Tab label='17. SEO Config' {...a11yProps(16)} />
            <Tab label='18. EMI' {...a11yProps(17)} />
            <Tab label='19. View Type' {...a11yProps(18)} />
            <Tab label='20. FAQ' {...a11yProps(19)} />
            <Tab label='21. Layout Street View' {...a11yProps(20)} />
            <Tab label='22. Header Footer' {...a11yProps(21)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            {chooseLocation()}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {verifyAddress()}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {updateProjectDetails()}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {updatePlotPrice()}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {renderAddPlots()}
          </TabPanel>
          <TabPanel value={value} index={5}>
            {generalInformation()}
          </TabPanel>
          <TabPanel value={value} index={6}>
            {dtcpNumberInfo()}
          </TabPanel>
          <TabPanel value={value} index={7}>
            {amenities()}
          </TabPanel>
          <TabPanel value={value} index={8}>
            {renderSalient()}
          </TabPanel>
          <TabPanel value={value} index={9}>
            {bankDetails()}
          </TabPanel>
          <TabPanel value={value} index={10}>
            {renderImages()}
          </TabPanel>
          <TabPanel value={value} index={11}>
            {renderVideos()}
          </TabPanel>
          <TabPanel value={value} index={12}>
            {renderLayout()}
          </TabPanel>
          {/* <TabPanel value={value} index={12}>
            {renderAboutCompany()}
          </TabPanel> */}
          <TabPanel value={value} index={13}>
            {renderContact()}
          </TabPanel>
          <TabPanel value={value} index={14}>
            {renderNearByPlaces()}
          </TabPanel>
          <TabPanel value={value} index={15}>
            {renderNearByTypes()}
          </TabPanel>
          <TabPanel value={value} index={16}>
            {renderSeoConfig()}
          </TabPanel>
          <TabPanel value={value} index={17}>
            {renderEMI()}
          </TabPanel>
          <TabPanel value={value} index={18}>
            {renderView()}
          </TabPanel>
          <TabPanel value={value} index={19}>
            {renderFAQ()}
          </TabPanel>
          <TabPanel value={value} index={20}>
            {renderLayoutStreetView()}
          </TabPanel>
          <TabPanel value={value} index={21}>
            {renderHeaderFooterView()}
          </TabPanel>
        </Grid>
      </Grid>
      <Dialog
        open={openUpload}
        size='md'
        fullWidth
        onClose={handleUploadClose}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Upload</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {(uploadType === 'image' ||
              uploadType === 'logo' ||
              uploadType === 'layout') && (
              <Grid item xs={12}>
                <input
                  accept='image/*'
                  className={classes.input}
                  id='image-button-file'
                  onChange={onFileChange}
                  type='file'
                />
                {!uploadObj ? (
                  <label htmlFor='image-button-file'>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                    >
                      Choose Image
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10 }}>
                      {uploadObj.name || 'Image'}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant='contained'
                          color='primary'
                          component='span'
                          onClick={handleConfirmUpload}
                          style={{ marginRight: 10 }}
                        >
                          {uploading ? 'Uploading' : 'Upload Image'}
                        </Button>
                        <label htmlFor='image-button-file'>
                          <Button
                            variant='contained'
                            color='primary'
                            component='span'
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component='span'
                        >
                          Preview Image
                        </Button>
                        <label htmlFor='image-button-file'>
                          <Button
                            variant='contained'
                            color='primary'
                            component='span'
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
            {uploadType === 'audio' && (
              <Grid item xs={12}>
                <input
                  accept='audio/*'
                  className={classes.input}
                  id='audio-button-file'
                  onChange={onFileChange}
                  type='file'
                />

                {!uploadObj ? (
                  <label htmlFor='audio-button-file'>
                    <Button
                      variant='contained'
                      color='primary'
                      component='span'
                    >
                      Choose Audio
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10 }}>
                      {uploadObj.name || 'Audio'}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handleConfirmUpload}
                          component='span'
                          style={{ marginRight: 10 }}
                        >
                          {uploading ? 'Uploading' : 'Upload Audio'}
                        </Button>
                        <label htmlFor='audio-button-file'>
                          <Button
                            variant='contained'
                            color='primary'
                            component='span'
                          >
                            Choose New Audio
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component='span'
                        >
                          Preview Audio
                        </Button>
                        <label htmlFor='audio-button-file'>
                          <Button
                            variant='contained'
                            color='primary'
                            component='span'
                          >
                            Choose New Audio
                          </Button>
                        </label>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadClose} color='primary'>
            Close
          </Button>
          {uploadStatus && (
            <Button onClick={handleSave} color='primary'>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDelete}
        size='md'
        fullWidth
        onClose={() => {
          setUpdateData(null);
        }}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Confirmation</DialogTitle>
        <DialogContent>Delete this item?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDelete(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => handleConfirmDelete()} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message='Success'
        variant='success'
      />
      <Dialog
        open={isNearby}
        fullWidth
        fullScreen
        TransitionComponent={Transition}
        onClose={() => {
          setIsNearby(false);
          setUpdateData(null);
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setIsNearby(false);
                setUpdateData(null);
              }}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Add/Update Place
            </Typography>
            <Button
              autoFocus
              color='inherit'
              onClick={() => {
                handleAddNewNearBy();
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs='6'>
              <TextField
                fullWidth
                value={updateData && updateData.name}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    name: e.target.value,
                  })
                }
                id='place-basic'
                label='Code'
              />
            </Grid>
            <Grid item xs='6'>
              <TextField
                fullWidth
                value={updateData && updateData.type}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    type: e.target.value,
                  })
                }
                id='type-basic'
                label='Place Type'
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <AutoComplete
                setLocation={(val) => {
                  setLocation(val && val.coor);
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              {updateData && updateData.gps_location && (
                <Map
                  gps_location={
                    (updateData && updateData.gps_location) || {
                      lat: 13.086289405322379,
                      lng: 80.29239116173903,
                    }
                  }
                  isProject={true}
                  onMapClick={onMapClick}
                  zoom_level={18}
                  drawingControlEnabled={false}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isStreetView}
        fullWidth
        size='md'
        onClose={() => {
          setIsStreetView(false);
          setUpdateData(null);
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setIsStreetView(false);
                setUpdateData(null);
              }}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Add/Update Street View
            </Typography>
            <Button
              autoFocus
              color='inherit'
              onClick={() => {
                handleAddNewStreetView();
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs='12'>
              <TextField
                fullWidth
                value={updateData && updateData.place_name}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    place_name: e.target.value,
                  })
                }
                id='place-basic'
                label='Place Name'
              />
            </Grid>
            <Grid item xs='12'>
              <TextField
                fullWidth
                value={updateData && updateData.place_image_id}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    place_image_id: e.target.value,
                  })
                }
                id='type-basic'
                label='Place Image Id'
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isNearbyType}
        fullWidth
        size='md'
        // TransitionComponent={Transition}
        onClose={() => {
          setIsNearbyType(false);
          setUpdateData(null);
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setIsNearbyType(false);
                setUpdateData(null);
              }}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Add/Update Type
            </Typography>
            <Button
              autoFocus
              color='inherit'
              onClick={() => {
                handleAddNewNearByType(false);
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ height: 300 }}>
          <Grid container spacing={1}>
            <Grid item xs='6'>
              <TextField
                fullWidth
                value={updateData && updateData.label}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    label: e.target.value,
                  })
                }
                id='label-basic'
                label='Label'
              />
            </Grid>
            <Grid item xs='6'>
              <TextField
                fullWidth
                value={updateData && updateData.name}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    name: e.target.value,
                  })
                }
                id='place-basic'
                label='Place Name'
              />
            </Grid>
            <Grid item xs='6'>
              <TextField
                fullWidth
                value={(updateData && updateData.radius) || 2000}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    radius: parseInt(e.target.value),
                  })
                }
                label='Radius'
              />
            </Grid>
            <Grid item xs='6'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={updateData && updateData.is_common}
                    onChange={(event) =>
                      setUpdateData({
                        ...updateData,
                        is_common: event.target.checked,
                      })
                    }
                    name='is_common'
                  />
                }
                label='Display'
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <div style={{ display: 'flex' }}>
                <input
                  accept='image/*'
                  className={classes.input}
                  id={'nearbytype'}
                  onChange={(e) => onNearbyFileChange(e)}
                  type='file'
                />

                <label htmlFor={'nearbytype'}>
                  <Button variant='contained' color='primary' component='span'>
                    {isLoading ? 'Uploading' : 'Upload Image'}
                  </Button>
                </label>
                {updateData && updateData.image && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleImagePreview(updateData.image || '')}
                    style={{ marginLeft: 10 }}
                    component='span'
                  >
                    Preview Image
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isFaq}
        fullWidth={true}
        size='md'
        onClose={() => {
          setIsFaq(false);
          setUpdateData(null);
        }}
        className='faq-dialog'
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => {
                setIsFaq(false);
                setUpdateData(null);
              }}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' className={classes.title}>
              Add/Update FAQ
            </Typography>
            <Button
              autoFocus
              color='inherit'
              onClick={() => {
                handleAddFAQ(false);
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ height: 300 }}>
          <Grid container spacing={1}>
            <Grid item xs='12'>
              <TextField
                multiline
                value={updateData && updateData.question}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    question: e.target.value,
                  })
                }
                rows='3'
                fullWidth
                label='Question'
                inputProps={{ maxLength: 120 }}
              />
            </Grid>
            <Grid item xs='12'>
              <TextField
                multiline
                value={updateData && updateData.answer}
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    answer: e.target.value,
                  })
                }
                rows='3'
                fullWidth
                label='Answer'
                inputProps={{ maxLength: 300 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openMoreInfo}
        size='md'
        fullWidth
        onClose={() => {
          setUpdateMoreData(null);
        }}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Update More info</DialogTitle>
        <DialogContent>
          <Grid spacing={1} container xs={12}>
            <Grid item xs={12} style={{}}>
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#0000FF',
                  fontSize: 18,
                }}
              >
                NO OF SIDES
              </span>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(updateMoreData && updateMoreData.no_of_sides) || ''}
                margin='dense'
                id='no_of_sides'
                label='Number of Sides'
                type='no_of_sides'
                fullWidth
                onChange={(e) =>
                  handleMoreDataChange('no_of_sides', e.target.value)
                }
              />
            </Grid>
            {updateMoreData && updateMoreData.no_of_sides > 0 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side1) || ''}
                  margin='dense'
                  id='direction'
                  label='Side A'
                  type='side1'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side1', e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 1 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side2) || ''}
                  margin='dense'
                  id='direction'
                  label='Side B'
                  type='side2'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side2', e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 2 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side3) || ''}
                  margin='dense'
                  id='direction'
                  label='Side C'
                  type='side3'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side3', e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 3 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side4) || ''}
                  margin='dense'
                  id='direction'
                  label='Side D'
                  type='side4'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side4', e.target.value)
                  }
                />
              </Grid>
            )}

            {updateMoreData && updateMoreData.no_of_sides > 4 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side5) || ''}
                  margin='dense'
                  id='direction'
                  label='Side E'
                  type='side5'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side5', e.target.value)
                  }
                />
              </Grid>
            )}

            {updateMoreData && updateMoreData.no_of_sides > 5 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side6) || ''}
                  margin='dense'
                  id='direction'
                  label='Side F'
                  type='side6'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side6', e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 6 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side7) || ''}
                  margin='dense'
                  id='direction'
                  label='Side G'
                  type='side7'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side7', e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 7 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side8) || ''}
                  margin='dense'
                  id='direction'
                  label='Side H'
                  type='side8'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side8', e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 8 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side9) || ''}
                  margin='dense'
                  id='direction'
                  label='Side I'
                  type='side9'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side9', e.target.value)
                  }
                />
              </Grid>
            )}
            {updateMoreData && updateMoreData.no_of_sides > 9 && (
              <Grid item xs={6}>
                <TextField
                  value={(updateMoreData && updateMoreData.side10) || ''}
                  margin='dense'
                  id='direction'
                  label='Side J'
                  type='side10'
                  fullWidth
                  onChange={(e) =>
                    handleMoreDataChange('side10', e.target.value)
                  }
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: '1px solid #c1c1c1',
                paddingTop: 10,
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#0000FF',
                  fontSize: 18,
                }}
              >
                ROAD
              </span>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component='fieldset' style={{ marginTop: 10 }}>
                  <FormLabel component='legend'>Road in Front</FormLabel>
                  <RadioGroup
                    row
                    aria-label='front_road'
                    name='front_road'
                    value={
                      updateMoreData && updateMoreData.front_road
                        ? updateMoreData.front_road
                        : 'no'
                    }
                    onChange={(e) =>
                      handleMoreDataChange('front_road', e.target.value)
                    }
                  >
                    <FormControlLabel
                      value='yes'
                      control={<Radio color='primary' />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='no'
                      control={<Radio color='primary' />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.front_road === 'yes' && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.front_road_feet) || ''
                    }
                    margin='dense'
                    id='direction'
                    label='Front Road Length (In Feet)'
                    type='front_road_feet'
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange('front_road_feet', e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component='fieldset' style={{ marginTop: 10 }}>
                  <FormLabel component='legend'>Road in Back</FormLabel>
                  <RadioGroup
                    row
                    aria-label='back_road'
                    name='back_road'
                    value={
                      updateMoreData && updateMoreData.back_road
                        ? updateMoreData.back_road
                        : 'no'
                    }
                    onChange={(e) =>
                      handleMoreDataChange('back_road', e.target.value)
                    }
                  >
                    <FormControlLabel
                      value='yes'
                      control={<Radio color='primary' />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='no'
                      control={<Radio color='primary' />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.back_road === 'yes' && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.back_road_feet) || ''
                    }
                    margin='dense'
                    id='direction'
                    label='Back Road Length (In Feet)'
                    type='back_road_feet'
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange('back_road_feet', e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component='fieldset' style={{ marginTop: 10 }}>
                  <FormLabel component='legend'>Road in Left</FormLabel>
                  <RadioGroup
                    row
                    aria-label='left_road'
                    name='left_road'
                    value={
                      updateMoreData && updateMoreData.left_road
                        ? updateMoreData.left_road
                        : 'no'
                    }
                    onChange={(e) =>
                      handleMoreDataChange('left_road', e.target.value)
                    }
                  >
                    <FormControlLabel
                      value='yes'
                      control={<Radio color='primary' />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='no'
                      control={<Radio color='primary' />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.left_road === 'yes' && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.left_road_feet) || ''
                    }
                    margin='dense'
                    id='direction'
                    label='Left Road Length (In Feet)'
                    type='left_road_feet'
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange('left_road_feet', e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl component='fieldset' style={{ marginTop: 10 }}>
                  <FormLabel component='legend'>Road in Right</FormLabel>
                  <RadioGroup
                    row
                    aria-label='right_road'
                    name='right_road'
                    value={
                      updateMoreData && updateMoreData.right_road
                        ? updateMoreData.right_road
                        : 'no'
                    }
                    onChange={(e) =>
                      handleMoreDataChange('right_road', e.target.value)
                    }
                  >
                    <FormControlLabel
                      value='yes'
                      control={<Radio color='primary' />}
                      label='Yes'
                    />
                    <FormControlLabel
                      value='no'
                      control={<Radio color='primary' />}
                      label='No'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {updateMoreData && updateMoreData.right_road === 'yes' && (
                <Grid item xs={6}>
                  <TextField
                    value={
                      (updateMoreData && updateMoreData.right_road_feet) || ''
                    }
                    margin='dense'
                    id='direction'
                    label='Right Road Length (In Feet)'
                    type='right_road_feet'
                    fullWidth
                    onChange={(e) =>
                      handleMoreDataChange('right_road_feet', e.target.value)
                    }
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                marginTop: 10,
                borderTop: '1px solid #c1c1c1',
                paddingTop: 10,
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#0000FF',
                  fontSize: 18,
                }}
              >
                PLOT STATUS
              </span>
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Plot Status</InputLabel>
              <Select
                style={{ width: '100%' }}
                value={(updateMoreData && updateMoreData.plot_status) || 0}
                onChange={(e) =>
                  handleMoreDataChange('plot_status', e.target.value)
                }
              >
                <MenuItem value={1}>Sold</MenuItem>
                <MenuItem value={0}>Unsold</MenuItem>
                <MenuItem value={2}>Booked</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMoreInfo(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => handleUpdateMore()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: '45%',
            left: '50%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress thickness={'3'} size='4rem' />
        </div>
      )}
    </>
  );
}
