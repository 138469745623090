import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import XHR from "../../util/xhr";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default function Chatbot() {
  const history = useHistory();

  const [botId, setBotId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [toast, setToast] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  useEffect(() => {
    getBotId();
  }, []);

  const getBotId = () => {
    setIsLoading(true);
    const payload = {};
    XHR.post("chatbot/get", payload).then(
      (res) => {
        setIsLoading(false);
        setBotId((res.data && res.data.bot_id) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const getUserId = () => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;
    return (userDetails && userDetails.user_id) || companyId;
  };

  const handleSave = () => {
    if (!botId) {
      setToast({
        message: "Enter bot id",
        variant: "error",
      });
      handleSnackClick();
      return;
    }
    setIsLoading(true);
    const payload = { bot_id: botId, user_id: getUserId() };
    XHR.post("chatbot/update", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          setUpdateData(null);
          handleSnackClick();
          setToast({
            message: "Success",
            variant: "success",
          });
        } else {
          setToast({
            message: "Not updated",
            variant: "error",
          });
          handleSnackClick();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "right", marginBottom: 10 }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => history.replace("/", {})}
          color="primary"
        >
          Back
        </Button>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg={12} style={{ marginTop: 10 }}>
          <TextField
            value={botId || ""}
            margin="dense"
            id="bot_id"
            label="Bot Id"
            type="text"
            fullWidth
            onChange={(e) => setBotId(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ marginTop: 10 }}>
          <Button
            onClick={() => handleSave()}
            variant="contained"
            style={{ marginRight: 10 }}
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message={(toast && toast.message) || "Success"}
        variant={(toast && toast.variant) || "success"}
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </Grid>
  );
}
