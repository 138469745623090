import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import SaveIcon from "@material-ui/icons/Save";
import ReorderIcon from "@material-ui/icons/Reorder";

import { baseUrl } from "../../util/config";
import XHR from "../../util/xhr";
import * as XLSX from "xlsx";

export default function UploadRera() {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const [plots, setPlots] = useState([]);

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const onExcelChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const excelData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
      let plotsArr = [];
      for (let i = 1; i < excelData.length; i++) {
        if (excelData[i][0]) {
          plotsArr.push({
            rera_id: excelData[i][0],
            reg_date: excelData[i][1],
            project_name: excelData[i][2],
            project_details: excelData[i][3],
            address: excelData[i][4],
            village: excelData[i][5],
            taluk: excelData[i][6],
            district: excelData[i][7],
            no_of_plots: excelData[i][8],
            promoter_address: excelData[i][9],
            geo_location: excelData[i][10],
          });
        }
      }
      setPlots(plotsArr);
    };
    reader.readAsBinaryString(file);
  };

  const handlePlotsChange = (key, index, value) => {
    const plotsArr = JSON.parse(JSON.stringify(plots));
    plotsArr[index][key] = value;
    setPlots(plotsArr);
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = `${baseUrl}/Rera_Upload_Sample_Excel.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderAddPlots = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item container xs={6} style={{ marginBottom: 20 }}>
          <label htmlFor="excel-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload Excel
            </Button>
          </label>
          <input
            accept="xlsx/*"
            style={{ display: "none" }}
            id="excel-button-file"
            onChange={onExcelChange}
            type="file"
          />
          <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={() => handleDownload()}
            style={{ marginLeft: 20 }}
          >
            Download Sample Excel
          </Button>
          <span
            style={{
              fontSize: 20,
              marginLeft: 20,
              marginTop: 6,
              color: "green",
              fontWeight: "bolder",
            }}
          >
            {message && message}
          </span>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={2} style={{ display: "flex" }}>
            <span style={{ marginRight: 10, width: 50 }}>S.No</span>
            <span style={{ marginRight: 10 }}>RERA ID</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Reg Date</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Project Name</span>
          </Grid>
          <Grid item xs={2}>
            <span style={{ marginRight: 10 }}>Address</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Village</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Taluk</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>District</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>No of Plots</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Promoter Address</span>
          </Grid>
          <Grid item xs={1}>
            <span style={{ marginRight: 10 }}>Geo Location</span>
          </Grid>
        </Grid>
        {plots &&
          plots.map((el, i) => (
            <Grid item container xs={12} style={{ marginTop: 20 }}>
              <Grid item xs={2} style={{ paddingRight: 20, display: "flex" }}>
                <span
                  style={{ marginRight: 10, width: 55, alignSelf: "center" }}
                >
                  {i + 1}
                </span>
                <TextField
                  name="rera_id"
                  value={el.rera_id || ""}
                  onChange={(event) =>
                    handlePlotsChange("rera_id", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="reg_date"
                  value={el.reg_date || ""}
                  onChange={(event) =>
                    handlePlotsChange("reg_date", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="project_name"
                  value={el.project_name || ""}
                  onChange={(event) =>
                    handlePlotsChange("project_name", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={2} style={{ paddingRight: 20 }}>
                <TextField
                  name="address"
                  value={el.address || ""}
                  onChange={(event) =>
                    handlePlotsChange("address", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="village"
                  value={el.village || ""}
                  onChange={(event) =>
                    handlePlotsChange("village", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="taluk"
                  value={el.taluk || ""}
                  onChange={(event) =>
                    handlePlotsChange("taluk", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="district"
                  value={el.district || ""}
                  onChange={(event) =>
                    handlePlotsChange("district", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="no_of_plots"
                  value={el.no_of_plots || ""}
                  onChange={(event) =>
                    handlePlotsChange("no_of_plots", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="promoter_address"
                  value={el.promoter_address || ""}
                  onChange={(event) =>
                    handlePlotsChange("promoter_address", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={1} style={{ paddingRight: 20 }}>
                <TextField
                  name="geo_location"
                  value={el.geo_location || ""}
                  onChange={(event) =>
                    handlePlotsChange("geo_location", i, event.target.value)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          ))}
      </Grid>
    );
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const updateProject = () => {
    setIsLoading(true);
    const payload = {
      rera: plots,
    };
    XHR.post("realestate/rera/excelupdate", payload).then(
      (res) => {
        console.log("res", res);
        setIsLoading(false);
        if (res && res.data && res.data.status) {
          setMessage(
            `Total : ${res.data.totalCount} / Added : ${res.data.insertCount} / Updated : ${res.data.updateCount}`
          );
          handleSnackClick();
          setPlots([]);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", marginBottom: 10 }}>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => history.replace("/rera", {})}
            color="primary"
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => updateProject()}
            color="primary"
          >
            Update
          </Button>
        </Grid>
        <Grid container spacing={1}>
          {renderAddPlots()}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message={message}
        variant="success"
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </>
  );
}
