import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { redirectUrl } from "../../util/config";

import XHR from "../../util/xhr";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory();

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [companyId, setCompanyId] = React.useState(
    localStorage.getItem("company") || "A"
  );
  const [allCompanies, setAllCompanies] = React.useState([]);
  const [userData, setUserData] = React.useState([]);

  const [showPublish, setShowPublish] = React.useState(false);
  const [switchPromotor, setSwitchPromotor] = React.useState(false);

  const [publishValue, setPublishValue] = React.useState(1);
  const [publishId, setPublishId] = React.useState(0);

  const [switchPromotorValue, setSwitchPromotorValue] = React.useState(0);
  const [switchPromotorDisplay, setSwitchPromotorDisplay] =
    React.useState(null);
  const [switchPromotorId, setSwitchPromotorId] = React.useState(0);

  const [promotorAutoComplete, setPromotorAutoComplete] = React.useState([]);

  const [toast, setToast] = useState(null);

  useEffect(() => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;
    console.log("userDetails", userDetails);
    setUserData(userDetails);
    if (userDetails && userDetails.user_type === "S") {
      getAllCompanies();
    }
  }, []);

  useEffect(() => {
    getAllProjects();
  }, [companyId]);

  const getAllCompanies = () => {
    setIsLoading(true);
    const payload = {
      onlyPromoters: true,
    };
    XHR.post("realestate/company/list", payload).then(
      (res) => {
        setIsLoading(false);
        setAllCompanies((res.data && res.data.companies) || []);
        let promotorData = [];
        if (res.data.companies.length > 0) {
          res.data.companies.forEach((data) => {
            promotorData.push({
              label: data.name + " (" + data.email + ")",
              id: data,
            });
          });
        }
        setPromotorAutoComplete(promotorData);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAllProjects = () => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;

    const payload = {
      user_id:
        companyId === "A" ? userDetails && userDetails.user_id : companyId,
    };
    if (payload.user_id) {
      setIsLoading(true);
      XHR.post("realestate/list", payload).then(
        (res) => {
          setIsLoading(false);
          setProjects((res.data && res.data.projects) || []);
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
    }
  };

  const getUserId = () => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;
    return companyId === "A" ? userDetails && userDetails.user_id : companyId;
  };

  const handleDeleteClick = (el) => {
    setUpdateData(el);
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData };
    XHR.post("realestate/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handlePublish = () => {
    setIsLoading(true);
    const payload = { id: publishId, status: publishValue };
    XHR.post("realestate/publish", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
          setShowPublish(false);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleSwitchPromotor = () => {
    setIsLoading(true);
    const payload = { id: switchPromotorId, promotor_id: switchPromotorValue };
    if (switchPromotorValue !== 0) {
      XHR.post("realestate/switch", payload).then(
        (res) => {
          setIsLoading(false);
          if (res.data && res.data.status) {
            setToast({
              message: "Success",
              variant: "success",
            });
            handleSnackClick();
            getAllProjects();
            setSwitchPromotor(false);
          }
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
    } else {
      setIsLoading(false);
      setToast({
        message: "Please Select the promotor",
        variant: "error",
      });
      handleSnackClick();
    }
  };

  const handlePartialPublish = (id, status) => {
    setIsLoading(true);
    const payload = { id, status };
    XHR.post("realestate/partialpublish", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handlePlpPublish = (id, show_plp) => {
    setIsLoading(true);
    const payload = { id, show_plp };
    XHR.post("realestate/plppublish", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const openPromotors = (
    district,
    state,
    promotors,
    company_hash,
    company_userid
  ) => {
    let path = `promoter-pdp/residential_plots_${
      state != "" ? state.replace(/\s+/g, "").toLowerCase() : "state"
    }/residential_plots_${
      district != "" ? district.replace(/\s+/g, "").toLowerCase() : "district"
    }/${promotors.replace(/\s+/g, "").toLowerCase()}/${company_hash}`;
    if (company_hash != "") {
      window.open(`${redirectUrl}/${path}`, "_blank");
    }
  };

  const handleProjectClick = (
    district = "",
    state = "",
    promotors = "",
    company_hash = "",
    company_userid = "",
    name = ""
  ) => {
    let path = `pdp/residential_plots_${
      state != "" ? state.replace(/\s+/g, "").toLowerCase() : "state"
    }/residential_plots_${
      district != "" ? district.replace(/\s+/g, "").toLowerCase() : "district"
    }/${promotors.replace(/\s+/g, "").toLowerCase()}/${name
      .replace(/\s+/g, "")
      .toLowerCase()}/${company_hash}`;
    if (company_hash != "") {
      window.open(`${redirectUrl}/${path}`, "_blank");
    }
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleCompanyChange = (event) => {
    localStorage.setItem("company", event.target.value);
    setCompanyId(event.target.value);
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 1:
        return "Not Published";
      case 2:
        return "Published";
      case 3:
        return "Partial";
      default:
        return "Not Published";
    }
  };

  const handleChange = (event) => {
    setPublishValue(parseInt(event.target.value));
  };

  const isAdmin = userData && userData.user_type === "S";

  const onAutoCompleteChange = (value) => {
    console.log("value", value);
    if (value) {
      setSwitchPromotorValue(parseInt(value.id.id));
      setSwitchPromotorDisplay(value.id);
    } else {
      setSwitchPromotorValue(0);
      setSwitchPromotorDisplay(null);
    }
  };

  const openPDP = (
    e,
    state,
    district,
    taluk,
    area1,
    area2,
    promotors,
    company_hash,
    company_userid,
    name,
    data
  ) => {
    e.stopPropagation();

    let path = "/pdp";

    if (state) {
      path = `${path}/${state.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (district) {
      path = `${path}/${district.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (taluk) {
      path = `${path}/${taluk.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area1) {
      path = `${path}/${area1.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (area2) {
      path = `${path}/${area2.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (promotors) {
      path = `${path}/${promotors.replace(/\s+/g, "").toLowerCase()}`;
    }

    if (name) {
      path = `${path}/${name.replace(/\s+/g, "").toLowerCase()}`;
    }

    path = `${path}/${company_hash}`;

    if (company_hash != "") {
      window.open(`${redirectUrl}${path}`, "_blank");
    }
  };

  return (
    <Grid container>
      {isAdmin ? (
        <>
          <Grid item xs={12} style={{ textAlign: "left", marginBottom: 10 }}>
            <span style={{ fontSize: 18, fontWeight: "bold", marginRight: 10 }}>
              No of Promoters: {allCompanies.length}
            </span>
            <span style={{ fontSize: 18, fontWeight: "bold" }}>
              No of Layouts: 12
            </span>
          </Grid>{" "}
          <Grid item xs={4} style={{ textAlign: "left", marginBottom: 10 }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">
                Select Company
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={companyId}
                onChange={handleCompanyChange}
              >
                <MenuItem value={"A"}>Admin</MenuItem>
                {allCompanies.map((el, i) => (
                  <MenuItem key={i} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </>
      ) : null}

      <Grid
        item
        xs={isAdmin ? 8 : 12}
        style={{ textAlign: "right", marginBottom: 10 }}
      >
        {companyId && companyId !== "A" && (
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => routeTo("promoterdetails", { id: companyId })}
            color="primary"
          >
            Promoter Details
          </Button>
        )}
        {companyId && companyId == "A" && (
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => routeTo("cityplp", {})}
            color="primary"
          >
            City Specific PLP
          </Button>
        )}
        {companyId && companyId == "A" && (
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => routeTo("chatbot", {})}
            color="primary"
          >
            Chatbot
          </Button>
        )}
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() =>
            routeTo("updateclient", {
              row: { user_id: getUserId() },
            })
          }
          color="primary"
        >
          Add Project
        </Button>
        {isAdmin ? (
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => routeTo("omtmember", {})}
            color="primary"
          >
            OMT Member
          </Button>
        ) : null}
        {isAdmin ? (
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => routeTo("rera", {})}
            color="primary"
          >
            RERA
          </Button>
        ) : null}
        {isAdmin ? (
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => routeTo("companies", {})}
            color="primary"
          >
            Show Users
          </Button>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S NO</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Promoter</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Per Square Feet</TableCell>
                <TableCell>Total Plots</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(!projects || projects.length === 0) && (
                <TableRow>
                  <TableCell>No Data found.</TableCell>
                </TableRow>
              )}
              {projects.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell
                    className={classes.cursor}
                    style={{ textDecoration: "underline" }}
                    onClick={
                      (e) => {
                        let seo_details = row.seo ? row.seo : {};
                        openPDP(
                          e,
                          (seo_details && seo_details.state) || "",
                          (seo_details && seo_details.district) || "",
                          (seo_details && seo_details.taluk) || "",
                          (seo_details && seo_details.area1) || "",
                          (seo_details && seo_details.area2) || "",
                          row.company_promotors,
                          row.hash_key,
                          row.id,
                          row.name,
                          row
                        );
                      }
                      // handleProjectClick(
                      //   row.company_district,
                      //   row.company_state,
                      //   row.company_promotors,
                      //   row.hash_key,
                      //   row.id,
                      //   row.name
                      // )
                    }
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    className={classes.cursor}
                    style={{ textDecoration: "underline" }}
                    onClick={() =>
                      openPromotors(
                        row.company_district,
                        row.company_state,
                        row.company_promotors,
                        row.company_hash,
                        row.company_userid
                      )
                    }
                    component="th"
                    scope="row"
                  >
                    {row.company_promotors}
                  </TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell>{row.price_per_sqft}</TableCell>
                  <TableCell>{row.total_plots}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => routeTo("drawplots", { ...row, row })}
                      color="primary"
                    >
                      Draw Plots
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() =>
                        routeTo("updateclient", {
                          row,
                        })
                      }
                      color="primary"
                    >
                      Edit Project
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => routeTo("viewproject", { row })}
                      color="primary"
                    >
                      View Project
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => handleDeleteClick(row.id)}
                      color="primary"
                    >
                      Delete Project
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => routeTo("drawimage", { ...row })}
                      color="primary"
                    >
                      Draw With Fabric
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setPublishId(row.id);
                        setShowPublish(true);
                        setPublishValue(parseInt(row.status));
                      }}
                      color={row.status != 1 ? "secondary" : "primary"}
                    >
                      {getStatusLabel(row.status)}
                    </Button>
                    {/* <Button
                      variant="contained"
                      style={{ marginRight: 10, marginTop: 10 }}
                      onClick={() =>
                        handlePartialPublish(
                          row.id,
                          row.is_partial === 1 ? 0 : 1
                        )
                      }
                      color={row.is_partial === 1 ? "secondary" : "primary"}
                    >
                      {row.is_partial === 1 ? "No Partial" : "Partial"}
                    </Button> */}
                    <Button
                      variant="contained"
                      style={{ marginRight: 10, marginTop: 10 }}
                      onClick={() =>
                        handlePlpPublish(row.id, row.show_plp === 1 ? 0 : 1)
                      }
                      color={row.show_plp === 1 ? "secondary" : "primary"}
                    >
                      {row.show_plp === 1 ? "Hide in PLP" : "Show in PLP"}
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10, marginTop: 10 }}
                      onClick={() => routeTo("queries", { ...row })}
                      color="primary"
                    >
                      Show Queries
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() =>
                        routeTo("visitorshistory", {
                          row,
                        })
                      }
                      color="primary"
                    >
                      Visitors History
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() =>
                        routeTo("sitevisit", {
                          row,
                        })
                      }
                      color="primary"
                    >
                      Site Visit
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setSwitchPromotorId(row.id);
                        setSwitchPromotor(true);
                        setSwitchPromotorValue(0);
                        setSwitchPromotorDisplay(null);
                      }}
                      color="primary"
                    >
                      Switch Promotors
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() =>
                        routeTo("analytics", {
                          row,
                        })
                      }
                      color="primary"
                    >
                      Analytics
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() =>
                        routeTo("intermediaryhistory", {
                          row,
                        })
                      }
                      color="primary"
                    >
                      intermediary
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={isDelete}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
          <DialogContent>Delete this item?</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmDelete()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isDelete}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Bot Details</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  value={(updateData && updateData.name) || ""}
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmDelete()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showPublish}
          size="md"
          fullWidth
          onClose={() => {
            setShowPublish(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Status</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="publishValue"
                name="publishValue"
                value={publishValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Un Publish"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Publish"
                />
                <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label="Partial Publish"
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowPublish(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handlePublish()} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={switchPromotor}
          size="md"
          fullWidth
          onClose={() => {
            setSwitchPromotor(false);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Switch Promotor</DialogTitle>
          <DialogContent style={{ minHeight: 300 }}>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={promotorAutoComplete}
                getOptionLabel={(option) => option.label}
                onChange={(e, v) => onAutoCompleteChange(v)}
                sx={{ width: 500 }}
                renderInput={(params) => (
                  <TextField {...params} label="Promotor" />
                )}
              />
            </Grid>
            {switchPromotorDisplay && (
              <div style={{ marginTop: 50 }}>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Promotor Name: {switchPromotorDisplay.name}
                  </span>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Email: {switchPromotorDisplay.email}
                  </span>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    Mobile No: {switchPromotorDisplay.mobile}
                  </span>
                </Grid>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSwitchPromotor(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleSwitchPromotor()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message={(toast && toast.message) || "Success"}
          variant={(toast && toast.variant) || "success"}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "45%",
              left: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress thickness={"3"} size="4rem" />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
