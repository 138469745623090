import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

// import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextField from "@material-ui/core/TextField";

import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import XHR from "../../util/xhr";

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Queries() {
  const classes = useStyles();
  const history = useHistory();

  const {
    id,
    gps_location,
    zoom_level = 17,
    polyline = [],
  } = history.location.state;

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [companyId, setCompanyId] = React.useState("");
  const [details, setDetails] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);

  const [companyDetails, setCompanyDetails] = useState(null);
  const [userType, setUserType] = useState("N");
  const [userDetails, setUserDetails] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState(null);

  const [columns] = useState([
    { name: "sno", title: "S.No" },
    { name: "date", title: "Date" },
    { name: "time", title: "Time" },
    { name: "promoter_name", title: "Promoter Name" },
    { name: "plot_no", title: "Plot No" },
    { name: "last_chat", title: "Last Reply From" },
    { name: "user_email", title: "Created By" },
    { name: "action", title: "Action" },
  ]);

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const [data, setData] = useState({
    show_pdp_qa: 0,
  });

  useEffect(() => {
    getAllQueries();
    if (id) {
      getPlotDetails(id);
    }
  }, []);

  const getAllQueries = () => {
    setIsLoading(true);

    const payload = {
      project_id: id,
    };
    XHR.post("realestate/query/list", payload).then(
      (res) => {
        setIsLoading(false);
        setProjects((res.data && res.data.queries) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getAllMessages = (queryId) => {
    setIsLoading(true);
    const payload = { query_id: queryId };
    XHR.post("realestate/messages/get", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.queries) {
          setMessages(res.data.queries);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/get", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.data) {
          setData(res.data.data);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleDeleteClick = (el) => {
    setUpdateData(el);
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData };
    XHR.post("realestate/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllQueries(updateData);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleRoute = (event, path, props) => {
    event.preventDefault();
    history.push({
      pathname: path,
      state: props,
    });
  };

  const viewBtn = ({ row }, viewClick) => (
    <div>
      <Tooltip title="View">
        <VisibilityIcon
          title={"View"}
          onClick={() => {
            viewClick(row);
          }}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    </div>
  );

  const viewClick = (data) => {
    getAllMessages(data.id);
    setDetails(data);
    setOpenDetails(true);
  };

  const handleDetailsClose = () => {
    setDetails(null);
    setOpenDetails(false);
  };

  const handleDetailsSubmit = () => {
    if (!message) {
      return;
    }
    setIsLoading(true);
    const payload = { query_id: details.id, message, type: 2, user_id: 1 };
    XHR.post("realestate/messages/save", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          setMessage("");
          getAllMessages(details.id);
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleParentChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const updateProject = () => {
    setIsLoading(true);
    const payload = { ...data, id };
    XHR.post("realestate/updateinfo", payload).then(
      (res) => {
        setIsLoading(false);
        handleSnackClick();
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  return (
    <>
      <Grid container style={{ margin: 10, width: "unset" }}>
        <Grid item xs={10} style={{ textAlign: "right", marginBottom: 10 }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="show_pdp_qa"
              name="show_pdp_faq"
              value={(data && data.show_pdp_qa) || 0}
              onChange={(e) =>
                handleParentChange("show_pdp_qa", parseInt(e.target.value))
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Show In PDP"
              />
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label="Hide In PDP"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right", marginBottom: 10 }}>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => history.replace("/", {})}
            color="primary"
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => updateProject()}
            color="primary"
          >
            Update Details
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <DataGrid rows={projects} columns={columns}>
              <SortingState
                defaultSorting={[]}
                columnExtensions={sortingStateColumnExtensions}
              />
              <IntegratedSorting />
              <FilteringState
                defaultFilters={[]}
                columnExtensions={filteringStateColumnExtensions}
              />
              <IntegratedFiltering />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <IntegratedPaging />
              <DataTypeProvider
                for={["action"]}
                formatterComponent={(row) => {
                  return viewBtn(row, viewClick);
                }}
              />
              <Table />
              <TableHeaderRow showSortingControls />
              <PagingPanel pageSizes={pageSizes} />
              <TableFilterRow />
            </DataGrid>
          </Paper>
          <Dialog
            open={isDelete}
            size="md"
            fullWidth
            onClose={() => {
              setUpdateData(null);
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
            <DialogContent>Delete this item?</DialogContent>
            <DialogActions>
              <button
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                type="submit"
                style={{ padding: 10 }}
                onClick={() => setIsDelete(false)}
              >
                Cancel
              </button>
              <button
                className="btn theme-btn-1 btn-effect-1 text-uppercase"
                type="submit"
                style={{ padding: 10 }}
                onClick={() => handleConfirmDelete()}
              >
                Yes
              </button>
            </DialogActions>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={snackOpen}
            autoHideDuration={2000}
            onClose={handleSnackClose}
            message="Success"
            variant="success"
          />
          {isLoading ? (
            <div
              className="cv-spinner"
              style={{ position: "fixed", left: "50%", top: "0%" }}
            >
              <span className="spinner"></span>
            </div>
          ) : null}
        </Grid>
      </Grid>
      <Dialog
        open={openDetails}
        fullWidth
        size="md"
        onClose={handleDetailsClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12}>
              <h2>Your Query</h2>
              <div
                className="input-item input-item-name"
                style={{ fontSize: 18, padding: 10, background: "#74747429" }}
              >
                {(details && details.query) || ""}
              </div>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <h2>Messages</h2>
              <div
                className="input-item input-item-name"
                style={{
                  fontSize: 18,
                  padding: 10,
                  background: "#74747429",
                  height: 200,
                  overflowY: "auto",
                }}
              >
                {(messages || []).map((el) => {
                  return (
                    <div
                      style={{
                        display: "grid",
                        textAlign: `${el.type === 1 ? "left" : "right"}`,
                      }}
                    >
                      <span>{el.message}</span>
                      <span style={{ fontSize: 10 }}>
                        {el.date} | {el.time}
                      </span>
                    </div>
                  );
                })}
              </div>
            </Grid>

            <Grid container item xs={12} style={{ marginTop: 10 }}>
              <Grid item xs={8} style={{ textAlign: "left" }}>
                <TextField
                  id="standard-basic"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  label="Enter Reply"
                  fullWidth
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right", marginTop: 10 }}>
                <Button
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => handleDetailsSubmit()}
                  color="primary"
                >
                  Reply
                </Button>
                <Button
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => handleDetailsClose()}
                  color="primary"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
