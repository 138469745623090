import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import ViewComfyIcon from "@material-ui/icons/ViewComfy";

import XHR from "../../util/xhr";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default function DataEntriesPlotsList() {
  const classes = useStyles();
  const history = useHistory();

  const { id, name } = history.location.state;

  const [plots, setPlots] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [popupDetails, setPopupDetails] = useState(null);
  const [snackOpen, setSnackOpen] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const [openDetails, setOpenDetails] = useState(false);

  const [toast, setToast] = useState(null);

  const columns = [
    { name: "sno", title: "S.No" },
    { name: "name", title: "Plot Name" },
    { name: "plot_status", title: "Plots Status" },
  ];

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "plot_status", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "plot_status", sortingEnabled: false },
  ];

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);

  useEffect(() => {
    getAllProjects();
  }, []);

  const getAllProjects = () => {
    setIsLoading(true);
    const payload = { project_id: id };
    XHR.post("realestate/dataentries/plots", payload).then(
      (res) => {
        setIsLoading(false);
        setPlots((res.data && res.data.plots) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleConfirmUpdate = () => {
    setIsUpdate(false);
    setIsLoading(true);
    const payload = { ...updateData };
    XHR.post("realestate/plots/updatestatus", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleChange = (uploaded_id, value) => {
    setUpdateData({
      plot_status: value,
      uploaded_id,
    });
    setIsUpdate(true);
  };

  const getUserId = () => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;
    return (userDetails && userDetails.user_id) || companyId;
  };

  const getRupeesFormat = (amountValue = "") => {
    return (amountValue || "").toLocaleString("en-IN");
  };

  const getPlotType = (type) => {
    switch (type) {
      case 1:
        return "Street";
      case 2:
        return "Plot";
      case 3:
        return "Shop";
      case 4:
        return "Park";
      case 5:
        return "Road";
      case 6:
        return "Commercial";
      default:
        return "";
    }
  };

  const getSoldStatus = (type) => {
    switch (type) {
      case 1:
        return "Sold";
      case 2:
        return "Booked";
      case 0:
        return "Un Sold";
      case 1:
        return "";
    }
  };

  const getDirection = (type) => {
    switch (parseInt(type)) {
      case 1:
        return "East";
      case 2:
        return "West";
      case 3:
        return "North";
      case 4:
        return "South";
      case 5:
        return "North East";
      case 6:
        return "North West";
      case 7:
        return "South East";
      case 8:
        return "South West";
      default:
        return "";
    }
  };

  const viewName = ({ row }) => (
    <div
      onClick={() => {
        setPopupDetails(row);
        setOpenDetails(true);
      }}
    >
      <span style={{ cursor: "pointer", textDecoration: "underline" }}>
        {row.name}
      </span>
    </div>
  );

  const viewBtn = ({ row }) => (
    <div>
      <Select
        style={{ width: "100%" }}
        value={(row && row.plot_status) || 0}
        onChange={(e) => handleChange(row.uploaded_id, e.target.value)}
      >
        <MenuItem value={1}>Sold</MenuItem>
        <MenuItem value={0}>Unsold</MenuItem>
        <MenuItem value={2}>Booked</MenuItem>
      </Select>
    </div>
  );
  return (
    <Grid container>
      <Grid item xs={6} style={{ textAlign: "left", marginBottom: 10 }}>
        <span style={{ fontSize: 24 }}>
          Data Entry Plots | Project Name: {name}
        </span>
      </Grid>
      <Grid item xs={6} style={{ textAlign: "right", marginBottom: 10 }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => history.goBack()}
          color="primary"
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid rows={plots} columns={columns}>
            <SortingState
              defaultSorting={[]}
              columnExtensions={sortingStateColumnExtensions}
            />
            <IntegratedSorting />
            <FilteringState
              defaultFilters={[]}
              columnExtensions={filteringStateColumnExtensions}
            />
            <IntegratedFiltering />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <DataTypeProvider
              for={["name"]}
              formatterComponent={(row) => {
                return viewName(row);
              }}
            />
            <DataTypeProvider
              for={["plot_status"]}
              formatterComponent={(row) => {
                return viewBtn(row);
              }}
            />
            <Table />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={pageSizes} />
            <TableFilterRow />
          </DataGrid>
        </Paper>
        <Dialog
          open={isUpdate}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs="12">
                Update this plot?
              </Grid>
              <Grid item xs="12">
                <span style={{ color: "red" }}>Below fields are Optional</span>
              </Grid>
              <Grid item xs="6">
                <TextField
                  fullWidth
                  value={updateData && updateData.buyer_name}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      buyer_name: e.target.value,
                    })
                  }
                  id="type-buyer_name"
                  label="Buyer Name"
                />
              </Grid>
              <Grid item xs="6">
                <TextField
                  fullWidth
                  value={updateData && updateData.buyer_mobile}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      buyer_mobile: e.target.value,
                    })
                  }
                  id="type-buyer_mobile"
                  label="Buyer Mobile"
                />
              </Grid>
              <Grid item xs="12">
                <TextField
                  fullWidth
                  value={updateData && updateData.buyer_email}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      buyer_email: e.target.value,
                    })
                  }
                  id="type-buyer_email"
                  label="Buyer Email"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsUpdate(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmUpdate()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDetails}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title"> Plots Details</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                Plot Type:
                <span style={{ fontWeight: "bold", margin: 10 }}>
                  {getPlotType(popupDetails && popupDetails.plot_type)}
                </span>
              </Grid>
              <Grid item xs={6}>
                Plot Status:
                <span style={{ fontWeight: "bold", margin: 10 }}>
                  {getSoldStatus(popupDetails && popupDetails.plot_status)}
                </span>
              </Grid>
              {popupDetails && parseInt(popupDetails.plot_type) != 5 && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                      borderTop: "1px solid #c1c1c1",
                      paddingTop: 10,
                    }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 18 }}>
                      NO OF SIDES
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    No of Sides:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.no_of_sides) || "4"}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    Side A:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side1) || ""}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    Side B:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side2) || ""}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    Side C:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side3) || ""}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    Side D:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.side4) || ""}
                    </span>
                  </Grid>

                  {popupDetails && popupDetails.no_of_sides > 4 && (
                    <Grid item xs={6}>
                      Side E:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.side5) || ""}
                      </span>
                    </Grid>
                  )}

                  {popupDetails && popupDetails.no_of_sides > 5 && (
                    <Grid item xs={6}>
                      Side F:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.side6) || ""}
                      </span>
                    </Grid>
                  )}
                  {popupDetails && popupDetails.no_of_sides > 6 && (
                    <Grid item xs={6}>
                      Side G:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.side7) || ""}
                      </span>
                    </Grid>
                  )}
                  {popupDetails && popupDetails.no_of_sides > 7 && (
                    <Grid item xs={6}>
                      Side H:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.side8) || ""}
                      </span>
                    </Grid>
                  )}
                  {popupDetails && popupDetails.no_of_sides > 8 && (
                    <Grid item xs={6}>
                      Side I:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.side9) || ""}
                      </span>
                    </Grid>
                  )}
                  {popupDetails && popupDetails.no_of_sides > 9 && (
                    <Grid item xs={6}>
                      Side J:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.side10) || ""}
                      </span>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                      borderTop: "1px solid #c1c1c1",
                      paddingTop: 10,
                    }}
                  >
                    <span style={{ fontWeight: "bold", fontSize: 18 }}>
                      ROAD
                    </span>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={6}>
                      Road in Front:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.front_road) || "No"}
                      </span>
                    </Grid>
                    {popupDetails && popupDetails.front_road === "yes" && (
                      <Grid item xs={6}>
                        Front Road Length (In Feet):
                        <span style={{ fontWeight: "bold", margin: 10 }}>
                          {(popupDetails.cost &&
                            popupDetails.front_road_feet) ||
                            ""}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={6}>
                      Road in Back:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.back_road) || "No"}
                      </span>
                    </Grid>
                    {popupDetails && popupDetails.back_road === "yes" && (
                      <Grid item xs={6}>
                        Back Road Length (In Feet):
                        <span style={{ fontWeight: "bold", margin: 10 }}>
                          {(popupDetails && popupDetails.back_road_feet) ||
                            "No"}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={6}>
                      Road in Left:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.left_road) || "No"}
                      </span>
                    </Grid>
                    {popupDetails && popupDetails.left_road === "yes" && (
                      <Grid item xs={6}>
                        Left Road Length (In Feet):
                        <span style={{ fontWeight: "bold", margin: 10 }}>
                          {(popupDetails && popupDetails.left_road_feet) || ""}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={6}>
                      Road in Right:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {(popupDetails && popupDetails.right_road) || "No"}
                      </span>
                    </Grid>
                    {popupDetails && popupDetails.right_road === "yes" && (
                      <Grid item xs={6}>
                        Right Road Length (In Feet):
                        <span style={{ fontWeight: "bold", margin: 10 }}>
                          {(popupDetails && popupDetails.right_road_feet) || ""}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: 10,
                      borderTop: "1px solid #c1c1c1",
                      paddingTop: 10,
                    }}
                  />
                  <Grid item xs={6}>
                    Is Corner Plot:
                    <span style={{ fontWeight: "bold", margin: 10 }}>
                      {(popupDetails && popupDetails.is_corner) || "No"}
                    </span>
                  </Grid>
                  {popupDetails && popupDetails.is_corner !== "yes" ? null : (
                    <Grid item xs={6}>
                      Direction Facing:
                      <span style={{ fontWeight: "bold", margin: 10 }}>
                        {getDirection(popupDetails && popupDetails.direction) ||
                          ""}
                      </span>
                    </Grid>
                  )}
                </>
              )}
              <Grid
                item
                xs={12}
                style={{
                  marginTop: 10,
                  borderTop: "1px solid #c1c1c1",
                  paddingTop: 10,
                }}
              >
                <span style={{ fontWeight: "bold", fontSize: 18 }}>AREA</span>
              </Grid>
              <Grid item xs={6}>
                Plot No:
                <span style={{ fontWeight: "bold", margin: 10 }}>
                  {(popupDetails && popupDetails.name) || ""}
                </span>
              </Grid>
              <Grid item xs={6}>
                Total Area in sq feet:
                <span style={{ fontWeight: "bold", margin: 10 }}>
                  ₹{Math.round(popupDetails && popupDetails.area) || ""}
                </span>
              </Grid>
              <Grid item xs={6}>
                Rate Per sq feet:
                <span style={{ fontWeight: "bold", margin: 10 }}>
                  ₹
                  {getRupeesFormat(popupDetails && popupDetails.per_s_feet) ||
                    ""}
                </span>
              </Grid>
              <Grid item xs={6}>
                Total Cost:
                <span style={{ fontWeight: "bold", margin: 10 }}>
                  ₹{getRupeesFormat(popupDetails && popupDetails.cost)}
                </span>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDetails(false);
                setPopupDetails(null);
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message={(toast && toast.message) || "Success"}
          variant={(toast && toast.variant) || "success"}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "45%",
              left: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress thickness={"3"} size="4rem" />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
