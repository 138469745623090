import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AppsIcon from "@material-ui/icons/Apps";

import XHR from "../../util/xhr";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default function DataEntriesProjectList() {
  const classes = useStyles();
  const history = useHistory();

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [toast, setToast] = useState(null);

  const columns = [
    { name: "sno", title: "S.No" },
    { name: "name", title: "Project Name" },
    { name: "total_plots", title: "Total Plots" },
    { name: "address", title: "Address" },
    { name: "action", title: "Action" },
  ];

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "address", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "address", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    getAllProjects();
  }, []);

  const getAllProjects = () => {
    setIsLoading(true);

    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;

    if (userDetails && userDetails.user_id) {
      const payload = { user_id: (userDetails && userDetails.user_id) || 0 };
      XHR.post("realestate/dataentries/projects", payload).then(
        (res) => {
          setIsLoading(false);
          setProjects((res.data && res.data.projects) || []);
        },
        (err) => {
          setIsLoading(false);
          console.log("err", err);
        }
      );
    }
  };

  const handleViewClick = (el) => {
    setUpdateData(el);
    setShowAction(true);
  };

  const handleDeleteClick = (el) => {
    setUpdateData(el);
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData };
    XHR.post("realestate/company/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleSave = (id, type) => {
    if (
      !updateData ||
      !updateData.name ||
      !updateData.email ||
      !updateData.contact ||
      !updateData.ori_password
    ) {
      setToast({
        message: "Enter All Fields",
        variant: "error",
      });
      handleSnackClick();
      return;
    }
    setIsLoading(true);
    const payload = { ...updateData, user_id: getUserId() };
    XHR.post("realestate/promoters/useradd", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          setShowAction(false);
          setUpdateData(null);
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleChange = (key, value) => {
    setUpdateData({
      ...updateData,
      [key]: value,
    });
  };

  const getUserId = () => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;
    return (userDetails && userDetails.user_id) || companyId;
  };

  const getAddress = (product) => {
    let add = "";
    if (product && product.street) {
      add = `${product.street}`;
    }

    if (product && product.add1) {
      add = `${add}, ${product.add1}`;
    }

    if (product && product.add2) {
      add = `${add}, ${product.add2}`;
    }

    if (product && product.town) {
      add = `${add}, ${product.town}`;
    }

    if (product && product.city) {
      add = `${add}, ${product.city}`;
    }

    if (product && product.taluk) {
      add = `${add}, ${product.taluk}`;
    }

    if (product && product.district) {
      add = `${add}, ${product.district}`;
    }

    if (product && product.state) {
      add = `${add}, ${product.state}`;
    }

    if (product && product.zipcode) {
      add = `${add}, ${product.zipcode}`;
    }

    if (product && product.country) {
      add = `${add}, ${product.country}`;
    }

    if (product && product.landmark) {
      add = `${add}, ${product.landmark}`;
    }

    return add;
  };

  const handleLocationClick = (gps) => {
    if (gps.lat) {
      var url = "https://maps.google.com/?q=" + gps.lat + "," + gps.lng;
      window.open(url, "_blank");
    }
  };

  const viewAddress = ({ row }) => (
    <div onClick={() => handleLocationClick(row && row.gps_location)}>
      <span
        style={{ whiteSpace: "pre-wrap", cursor: "pointer" }}
      >
        {getAddress(row)}
      </span>
    </div>
  );

  const viewBtn = ({ row }) => (
    <div>
      <Tooltip title="View Plots">
        <VisibilityIcon
          onClick={() => routeTo("/dataentries/plots", { ...row })}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
      <Tooltip title="Visitors History">
        <AppsIcon
          onClick={() => routeTo("visitorshistory", {
            row,
          })}
          style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
        />
      </Tooltip>
    </div>
  );
  return (
    <Grid container>
      <Grid item xs={6} style={{ textAlign: "left", marginBottom: 10 }}>
        <span style={{ fontSize: 24 }}>Data Entry Mapped Projects</span>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <DataGrid rows={projects} columns={columns}>
            <SortingState
              defaultSorting={[]}
              columnExtensions={sortingStateColumnExtensions}
            />
            <IntegratedSorting />
            <FilteringState
              defaultFilters={[]}
              columnExtensions={filteringStateColumnExtensions}
            />
            <IntegratedFiltering />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <DataTypeProvider
              for={["action"]}
              formatterComponent={(row) => {
                return viewBtn(row);
              }}
            />
            <DataTypeProvider
              for={["address"]}
              formatterComponent={(row) => {
                return viewAddress(row);
              }}
            />

            <Table />
            <TableHeaderRow showSortingControls />
            <PagingPanel pageSizes={pageSizes} />
            <TableFilterRow />
          </DataGrid>
        </Paper>
        <Dialog
          open={isDelete}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
          <DialogContent>Delete this item?</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmDelete()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showAction}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">User Details</DialogTitle>
          <DialogContent dividers>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  value={(updateData && updateData.name) || ""}
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  value={(updateData && updateData.email) || ""}
                  margin="dense"
                  id="email"
                  label="Email"
                  type="text"
                  fullWidth
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  value={(updateData && updateData.contact) || ""}
                  margin="dense"
                  id="contact"
                  label="Mobile"
                  type="text"
                  fullWidth
                  onChange={(e) => handleChange("contact", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <TextField
                  value={(updateData && updateData.ori_password) || ""}
                  margin="dense"
                  id="ori_password"
                  label="Password"
                  type="text"
                  fullWidth
                  onChange={(e) => handleChange("ori_password", e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAction(false)} color="primary">
              Close
            </Button>
            <Button onClick={() => handleSave()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message={(toast && toast.message) || "Success"}
          variant={(toast && toast.variant) || "success"}
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "45%",
              left: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress thickness={"3"} size="4rem" />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
