import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { redirectUrl } from "../../util/config";

import XHR from "../../util/xhr";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const history = useHistory();

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [updateData, setUpdateData] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const { user_id = "" } =
    (history && history.location && history.location.state) || {};

  useEffect(() => {
    getAllProjects();
  }, []);

  const getAllProjects = () => {
    setIsLoading(true);

    const payload = { user_id };
    XHR.post("realestate/list", payload).then(
      (res) => {
        setIsLoading(false);
        setProjects((res.data && res.data.projects) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleDeleteClick = (el) => {
    setUpdateData(el);
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData };
    XHR.post("realestate/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handlePublish = (id, status) => {
    setIsLoading(true);
    const payload = { id, status };
    XHR.post("realestate/publish", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllProjects();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleDrawImage = (id) => {
    window.open(`${redirectUrl}/overlay/?id=${id}`, "_blank");
  };

  const handleProjectClick = (path) => {
    window.open(`${redirectUrl}/pdp/${path}`, "_blank");
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: "right", marginBottom: 10 }}>
        <Button
          variant="contained"
          style={{ marginRight: 10 }}
          onClick={() => history.goBack()}
          color="primary"
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S NO</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Per Square Feet</TableCell>
                <TableCell>Total Plots</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell
                    className={classes.cursor}
                    onClick={() => handleProjectClick(row.hash_key)}
                    component="th"
                    scope="row"
                  >
                    {row.name}
                  </TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell>{row.price_per_sqft}</TableCell>
                  <TableCell>{row.total_plots}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => routeTo("drawplots", row)}
                      color="primary"
                    >
                      Draw Plots
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => routeTo("updateclient", { row })}
                      color="primary"
                    >
                      Edit Project
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => routeTo("viewproject", { row })}
                      color="primary"
                    >
                      View Project
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => handleDeleteClick(row.id)}
                      color="primary"
                    >
                      Delete Project
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10 }}
                      onClick={() => routeTo("drawimage", { ...row })}
                      color="primary"
                    >
                      Draw With Fabric
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10, marginTop: 10 }}
                      onClick={() => handleDrawImage(row.id)}
                      color="primary"
                    >
                      Draw with Image
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginRight: 10, marginTop: 10 }}
                      onClick={() =>
                        handlePublish(row.id, row.status === 1 ? 2 : 1)
                      }
                      color={row.status === 2 ? "secondary" : "primary"}
                    >
                      {row.status === 2 ? "Un Publish" : "Publish"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={isDelete}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
          <DialogContent>Delete this item?</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmDelete()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message="Success"
          variant="success"
        />
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "45%",
              left: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress thickness={"3"} size="4rem" />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
