import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import * as XLSX from 'xlsx';

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import ReplayIcon from '@material-ui/icons/Replay';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import XHR from '../../util/xhr';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function mapTabProps(index) {
  return {
    id: `map-tab-${index}`,
    'aria-controls': `map-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: 'pointer',
  },
}));

export default function CityPlp() {
  const classes = useStyles();
  const history = useHistory();

  const [cityPlpList, setCityPlpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isView, setIsView] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const [actionType, setActionType] = useState(null);

  const [showAction, setShowAction] = useState(false);
  const [toast, setToast] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [value, setValue] = React.useState(0);
  const [tabValue, setTabValue] = React.useState(0);

  const [updateDetailsData, setUpdateDetailsData] = useState(null);
  const [cityExcel, setCityExcel] = useState([]);

  const proColumns = [
    { name: 'sno', title: 'S.No' },
    { name: 'name', title: 'City Name' },
    { name: 'location_type', title: 'Location Type' },
    { name: 'primary_location', title: 'Primary Location' },
    { name: 'ts', title: 'Created On' },
    { name: 'action', title: 'Action' },
  ];

  const [columns, setColumns] = useState(proColumns);

  const filteringStateColumnExtensions = [
    { columnName: 'sno', filteringEnabled: false },
    { columnName: 'action', filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: 'sno', sortingEnabled: false },
    { columnName: 'action', sortingEnabled: false },
  ];

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);

  useEffect(() => {
    getAllCityPlp();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getAllCityPlp = () => {
    setIsLoading(true);

    const payload = {};
    XHR.post('realestate/cityplp/list', payload).then(
      (res) => {
        setIsLoading(false);
        setCityPlpList((res.data && res.data.cityPlp) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleViewClick = (el) => {
    setUpdateData(el);
    setUpdateDetailsData(el?.details || {});
    setTabValue(0);
    setIsView(true);
    setActionType('V');
  };

  const handleEditClick = (el) => {
    setUpdateData(el);
    setShowAction(true);
    setActionType('E');
  };

  const handleAddClick = () => {
    setUpdateData(null);
    setUpdateDetailsData(null);
    setShowAction(true);
    setActionType('A');
  };

  const handleDeleteClick = (el) => {
    setUpdateData(el);
    setIsDelete(true);
    setActionType('D');
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData };
    XHR.post('realestate/cityplp/delete', payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          handleSnackClick();
          getAllCityPlp();
          setToast({
            message: 'Success',
            variant: 'success',
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackOpen(false);
  };

  const getData = () => {
    return cityPlpList || [];
  };

  const handleInputChange = (key, value) => {
    setUpdateData({
      ...updateData,
      [key]: value,
    });
  };

  const handleModalInputChange = (type, key, value) => {
    setUpdateDetailsData((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [key]: value,
      },
    }));
  };

  const handleRadioChange = (e) => {
    console.log('@@@@ e', e.target.name, e.target.value);
    if (e.target.name == 'location_type') {
      setUpdateData({
        ...updateData,
        location_type: e.target.value,
      });
    } else if (e.target.name == 'primary_location') {
      setUpdateData({
        ...updateData,
        primary_location: e.target.value,
      });
    }
  };

  const handleSave = () => {
    if (!updateData || !updateData.name) {
      setToast({
        message: 'Enter All Fields',
        variant: 'error',
      });
      handleSnackClick();
      return;
    }
    setIsLoading(true);
    const payload = { ...updateData, details: updateDetailsData };
    XHR.post('realestate/cityplp/update', payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          setShowAction(false);
          setUpdateData(null);
          handleSnackClick();
          getAllCityPlp();
          setToast({
            message: 'Success',
            variant: 'success',
          });
        } else {
          setToast({
            message: 'Already Exists',
            variant: 'error',
          });
          handleSnackClick();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log('err', err);
      }
    );
  };

  const viewBtn = ({ row }) => (
    <div>
      <Tooltip title='Map City PLP'>
        <VisibilityIcon
          onClick={() => handleViewClick(row)}
          style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
        />
      </Tooltip>
      <Tooltip title='Delete City PLP'>
        <DeleteIcon
          onClick={() => handleDeleteClick(row.id)}
          style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
        />
      </Tooltip>
      <Tooltip title={'Edit City PLP'}>
        <EditIcon
          onClick={() => handleEditClick(row)}
          style={{ fontSize: 20, marginLeft: 10, cursor: 'pointer' }}
        />
      </Tooltip>
    </div>
  );

  const renderTab = (data) => {
    return (
      <Paper>
        <DataGrid rows={data} columns={columns}>
          <SortingState
            defaultSorting={[]}
            columnExtensions={sortingStateColumnExtensions}
          />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={filteringStateColumnExtensions}
          />
          <IntegratedFiltering />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <DataTypeProvider
            for={['action']}
            formatterComponent={(row) => {
              return viewBtn(row);
            }}
          />
          <Table />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} />
          <TableFilterRow />
        </DataGrid>
      </Paper>
    );
  };

  const renderTabData = (type) => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <TextField
            value={
              (updateDetailsData &&
                updateDetailsData[type] &&
                updateDetailsData[type].url) ||
              ''
            }
            margin='dense'
            id='url'
            label='URL Pattern'
            type='text'
            fullWidth
            onChange={(e) =>
              handleModalInputChange(type, 'url', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <TextField
            value={
              (updateDetailsData &&
                updateDetailsData[type] &&
                updateDetailsData[type].heading) ||
              ''
            }
            margin='dense'
            id='heading'
            label='Page Heading'
            type='text'
            fullWidth
            onChange={(e) =>
              handleModalInputChange(type, 'heading', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <TextField
            value={
              (updateDetailsData &&
                updateDetailsData[type] &&
                updateDetailsData[type].description) ||
              ''
            }
            margin='dense'
            id='description'
            label='Page Description'
            type='text'
            fullWidth
            onChange={(e) =>
              handleModalInputChange(type, 'description', e.target.value)
            }
            multiline
            rows={2}
            maxRows={4}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <TextField
            value={
              (updateDetailsData &&
                updateDetailsData[type] &&
                updateDetailsData[type].meta_title) ||
              ''
            }
            margin='dense'
            id='meta_title'
            label='Meta Title'
            type='text'
            fullWidth
            onChange={(e) =>
              handleModalInputChange(type, 'meta_title', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <TextField
            value={
              (updateDetailsData &&
                updateDetailsData[type] &&
                updateDetailsData[type].meta_description) ||
              ''
            }
            margin='dense'
            id='meta_description'
            label='Meta Description'
            type='text'
            fullWidth
            onChange={(e) =>
              handleModalInputChange(type, 'meta_description', e.target.value)
            }
            multiline
            rows={2}
            maxRows={4}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <TextField
            value={
              (updateDetailsData &&
                updateDetailsData[type] &&
                updateDetailsData[type].meta_keyword) ||
              ''
            }
            margin='dense'
            id='meta_keyword'
            label='Meta Keywords'
            type='text'
            fullWidth
            onChange={(e) =>
              handleModalInputChange(type, 'meta_keyword', e.target.value)
            }
          />
        </Grid>
      </Grid>
    );
  };

  const onExcelChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const excelData = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
      let cityArr = [];
      for (let i = 1; i < excelData.length; i++) {
        if (excelData[i][0]) {
          cityArr.push({
            name: excelData[i][0],
            location_type: excelData[i][1],
            primary_location: excelData[i][2],
          });
        }
      }
      if (cityArr.length > 0) {
        setIsLoading(true);
        const payload = { excelRequest: cityArr };
        XHR.post('realestate/cityplp/excelupdate', payload).then(
          (res) => {
            setIsLoading(false);
            if (res.data.status) {
              setShowAction(false);
              setUpdateData(null);
              handleSnackClick();
              getAllCityPlp();
              setToast({
                message: 'Success',
                variant: 'success',
              });
            } else {
              setToast({
                message: 'Already Exists',
                variant: 'error',
              });
              handleSnackClick();
            }
          },
          (err) => {
            setIsLoading(false);
            console.log('err', err);
          }
        );
      } else {
        setToast({
          message: 'Invalid Excel Data',
          variant: 'error',
        });
      }
    };
    reader.readAsBinaryString(file);
  };

  console.log('@@@@ cityExcel', cityExcel);

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: 'right', marginBottom: 10 }}>
        <Button
          variant='contained'
          style={{ marginRight: 10 }}
          onClick={() => handleAddClick()}
          color='primary'
        >
          Add City PLP
        </Button>
        <label htmlFor='excel-button-file'>
          <Button
            variant='contained'
            color='primary'
            component='span'
            style={{ marginRight: 10 }}
          >
            Upload Excel
          </Button>
        </label>
        <input
          accept='xlsx/*'
          style={{ display: 'none' }}
          id='excel-button-file'
          onChange={onExcelChange}
          type='file'
        />
        <Button
          variant='contained'
          style={{ marginRight: 10 }}
          onClick={() => history.replace('/', {})}
          color='primary'
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={12}>
        <AppBar position='static'>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='simple tabs example'
          >
            <Tab
              label={`City PLP (${getData('O').length})`}
              {...a11yProps(0)}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {renderTab(getData('O'))}
        </TabPanel>

        <Dialog
          open={isDelete}
          size='md'
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Confirmation</DialogTitle>
          <DialogContent>Delete this item?</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => handleConfirmDelete()} color='primary'>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message={(toast && toast.message) || 'Success'}
          variant={(toast && toast.variant) || 'success'}
        />
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              top: '45%',
              left: '50%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress thickness={'3'} size='4rem' />
          </div>
        )}
      </Grid>
      <Dialog
        open={showAction}
        size='md'
        fullWidth
        onClose={() => {
          setUpdateData(null);
        }}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>City PLP</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TextField
                value={(updateData && updateData.name) || ''}
                margin='dense'
                id='name'
                label='City Name'
                type='text'
                fullWidth
                onChange={(e) => handleInputChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl>
                <FormLabel id='demo-row-radio-buttons-group-label'>
                  Location Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  style={{ padding: 10 }}
                  onChange={handleRadioChange}
                  name='location_type'
                  value={(updateData && updateData.location_type) || ''}
                >
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={'District'}
                    control={<Radio />}
                    label='District'
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={'Taluk'}
                    control={<Radio />}
                    label='Taluk'
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={'City'}
                    control={<Radio />}
                    label='City'
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={'Area'}
                    control={<Radio />}
                    label='Area'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <FormControl>
                <FormLabel id='demo-row-radio-buttons-group-label'>
                  Primary Location
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  style={{ padding: 10 }}
                  onChange={handleRadioChange}
                  name='primary_location'
                  value={(updateData && updateData.primary_location) || ''}
                >
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={'YES'}
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    value={'NO'}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAction(false)} color='primary'>
            Close
          </Button>
          <Button onClick={() => handleSave()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isView}
        fullWidth={true}
        maxWidth={'md'}
        onClose={() => {
          setUpdateData(null);
          setUpdateDetailsData(null);
        }}
        aria-labelledby='form-dialog-title'
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label='Map tabs'
              >
                <Tab label={`Plots`} {...mapTabProps(0)} />
                <Tab label={`Villas`} {...mapTabProps(1)} />
                <Tab label={`Row Houses`} {...mapTabProps(2)} />
                <Tab label={`Farm House`} {...mapTabProps(3)} />
                <Tab label={`Farm Land`} {...mapTabProps(4)} />
              </Tabs>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
              <TabPanel value={tabValue} index={0}>
                {renderTabData('plots')}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {renderTabData('villas')}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                {renderTabData('row_houses')}
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                {renderTabData('farm_house')}
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                {renderTabData('farm_land')}
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsView(false)} color='primary'>
            Close
          </Button>
          <Button onClick={() => handleSave()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
