import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Snackbar from "@material-ui/core/Snackbar";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import { Link } from "react-router-dom";
import DrawMaps from "../../components/MapsNew";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
const API_KEY = "AIzaSyBtRJ59UWCyTVENScWckaaqJas16Ymuuic";

import { baseUrl, baseUrlPlotsApi } from "../../util/config";

import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid as DataGrid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-material-ui";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from "@material-ui/icons/Replay";
import EditIcon from "@material-ui/icons/Edit";

import XHR from "../../util/xhr";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  cursor: {
    cursor: "pointer",
  },
}));

const Map = (props) => (
  // console.log("props", props)
  <DrawMaps
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `500px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    gps_location={props.gps_location}
    onClick={props.onMapClick}
    isProject={props.isProject}
    zoom_level={props.zoom_level}
    onZoomChanged={props.onZoomChanged}
    drawingControlEnabled={props.drawingControlEnabled}
    overlay={props.overlay}
    polyline={props.polyline}
    show_boundary={props.show_boundary}
    boundary_color={props.boundary_color}
    type="roadmap"
    appLocation={props.appLocation}
    exactLocations={props.exactLocations}
    onMarkerClick={props.onMarkerClick}
  />
);

export default function Rera() {
  const classes = useStyles();
  const history = useHistory();

  const [rera, setRera] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toast, setToast] = useState(null);

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [value, setValue] = React.useState(0);

  const [stateDetails, setStateDetails] = useState([]);
  const [districtDetails, setDistrictDetails] = useState([]);

  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);

  const [appproximateLocations, setAppproximateLocations] = useState([]);
  const [exactlocations, setExactLocations] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [advanceFilter, setAdvanceFilter] = useState({
    state_name: "",
    district_name: "",
  });

  const [userData, setUserData] = React.useState([]);

  const handleStateChange = (selectedOption) => {
    setStateOption(selectedOption);
    localStorage.setItem("state_filter", "");
    setAdvanceFilter({
      ...advanceFilter,
      state_name: "",
    });
    if (selectedOption && selectedOption.state_id) {
      setStateOption(selectedOption);
      localStorage.setItem("state_filter", JSON.stringify(selectedOption));
      getDistrictDetails(selectedOption.state_id);
      setAdvanceFilter({
        ...advanceFilter,
        state_name: selectedOption.state_title,
      });
    }
  };

  const handleDistrictChange = (selectedOption) => {
    setDistrictOption(selectedOption);
    localStorage.setItem("district_filter", "");
    setAdvanceFilter({
      ...advanceFilter,
      district_name: "",
    });
    if (selectedOption && selectedOption.districtid) {
      localStorage.setItem("district_filter", JSON.stringify(selectedOption));
      setDistrictOption(selectedOption);
      setAdvanceFilter({
        ...advanceFilter,
        district_name: selectedOption.district_title,
      });
    } else if (selectedOption && selectedOption.state_id) {
      getDistrictDetails(selectedOption.state_id);
    }
  };

  useEffect(() => {
    let userDetailsString = localStorage.getItem("realestate_user") || "";
    let userDetails =
      userDetailsString != "" ? JSON.parse(userDetailsString) : null;
    console.log("userDetails", userDetails);
    setUserData(userDetails);

    const state_filter = localStorage.getItem("state_filter") || "";
    if (state_filter !== "") {
      setStateOption(JSON.parse(state_filter));
    }

    const district_filter = localStorage.getItem("district_filter") || "";
    if (district_filter !== "") {
      setDistrictOption(JSON.parse(district_filter));
    }
  }, []);

  useEffect(() => {
    if (
      stateOption &&
      districtOption &&
      stateOption.state_title &&
      districtOption.district_title
    ) {
      setAdvanceFilter({
        ...advanceFilter,
        state_name: stateOption.state_title,
        district_name: districtOption.district_title,
      });
    }
  }, [stateOption, districtOption]);

  useEffect(() => {
    if (advanceFilter.state_name != "" || advanceFilter.district_name != "") {
      submitSearch();
    }
  }, [advanceFilter]);

  const [zoom, setZoom] = useState(18);

  const onZoomChanged = (zoom) => {
    setZoom(zoom);
  };

  const proColumns = [
    { name: "sno", title: "S.No" },
    { name: "rera_id", title: "RERA ID" },
    { name: "project_name", title: "Project Name" },
    { name: "district", title: "District" },
    { name: "no_of_plots", title: "No of plots" },
    { name: "promoter_name", title: "Promoter Name" },
    { name: "action", title: "Action" },
  ];

  const [columns, setColumns] = useState(proColumns);

  const filteringStateColumnExtensions = [
    { columnName: "sno", filteringEnabled: false },
    { columnName: "action", filteringEnabled: false },
  ];

  const sortingStateColumnExtensions = [
    { columnName: "sno", sortingEnabled: false },
    { columnName: "action", sortingEnabled: false },
  ];

  const [pageSizes] = useState([5, 10, 15, 25, 50, 100, 500]);

  useEffect(() => {
    // getAllrera();
    getStateDetails();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getAllrera = () => {
    setIsLoading(true);
    const payload = {};
    XHR.post("realestate/rera/getall", payload).then(
      (res) => {
        setIsLoading(false);
        setRera((res.data && res.data.rera) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleEditClick = (el) => {
    routeTo("updaterera", {
      row: el,
    });
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const getData = () => {
    return rera || [];
  };

  const handleLocationClick = (gps) => {
    if (gps.lat) {
      var url = "https://www.google.com/maps/search/" + gps.lat + "," + gps.lng;
      window.open(url, "_blank");
    }
  };

  const viewBtn = ({ row }) => (
    <div>
      {console.log("row", row)}
      {userData && userData.user_type !== "F" && (
        <>
          <Tooltip title={"Edit Rera"}>
            <EditIcon
              onClick={() => handleEditClick(row)}
              style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
            />
          </Tooltip>
          <Tooltip title="Delete Rera">
            <DeleteIcon
              onClick={() => handleDeleteClick(row.id)}
              style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
            />
          </Tooltip>
        </>
      )}
      {row && row.approved_geo_location != "" && (
        <Tooltip title="View Rera Details">
          <CheckCircleIcon
            style={{
              fontSize: 20,
              marginLeft: 10,
              cursor: "pointer",
              color: "green",
            }}
          />
        </Tooltip>
      )}
    </div>
  );

  const [updateData, setUpdateData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [toolTipContent, setToolTipContent] = React.useState(null);

  const handleDeleteClick = (el) => {
    setUpdateData(el);
    setIsDelete(true);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData };
    XHR.post("realestate/rera/delete", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.status) {
          handleSnackClick();
          getAllrera();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const renderTab = (data) => {
    return (
      <Paper>
        <DataGrid rows={data} columns={columns}>
          <SortingState
            defaultSorting={[]}
            columnExtensions={sortingStateColumnExtensions}
          />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={filteringStateColumnExtensions}
          />
          <IntegratedFiltering />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <DataTypeProvider
            for={["action"]}
            formatterComponent={(row) => {
              return viewBtn(row);
            }}
          />
          <Table />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={pageSizes} />
          <TableFilterRow />
        </DataGrid>
      </Paper>
    );
  };

  const getStateDetails = () => {
    const payload = {};
    XHR.post(`${baseUrlPlotsApi}/common/state`, payload).then(
      (res) => {
        if (res.data && res.data.state) {
          setStateDetails(res.data.state || []);
        }
      },
      (err) => {
        setStateDetails([]);
        console.log("err", err);
      }
    );
  };

  const getDistrictDetails = (state_id = "") => {
    const payload = {
      state_id,
    };
    XHR.post(`${baseUrlPlotsApi}/common/district`, payload).then(
      (res) => {
        if (res.data && res.data.district) {
          setDistrictDetails(res.data.district || []);
        }
      },
      (err) => {
        setDistrictDetails([]);
        console.log("err", err);
      }
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitSearch = () => {
    let XHRUrl = `${baseUrl}`;
    if (advanceFilter.state_name != "" || advanceFilter.district_name != "") {
      const payload = { ...advanceFilter };
      XHR.post(
        `${XHRUrl}/realestate/rera/getbydistrict`,
        payload
      ).then(
        (res) => {
          if (res.data && res.data.rera) {
            const results = res.data.rera;
            setRera(results);
            const appLocation = [];
            const extactLocation = [];
            for (const element of results) {
              if (element["approved_geo_location"] != "") {
                extactLocation.push({
                  gps: element["approved_geo_location"],
                  icon: "https://storage.googleapis.com/am-songs/images/20220820063053_project_view.png",
                  data: element,
                });
              } else if (element["geo_location"] != "") {
                appLocation.push({
                  gps: element["geo_location"],
                  icon: "https://storage.googleapis.com/am-songs/images/20220911025940_map_icon_partial.png",
                  data: element,
                });
              }
            }
            setAppproximateLocations(appLocation);
            setExactLocations(extactLocation);
          } else {
            setAppproximateLocations([]);
            setExactLocations([]);
            setRera([]);
          }
        },
        (err) => {
          // setStateDetails([]);
          console.log("err", err);
        }
      );
    } else {
      setToast({
        message: "Please select state and district",
        variant: "error",
      });
      handleSnackClick();
    }
  };

  const renderHeaderOption = () => {
    return (
      <>
        <Grid container style={{ padding: 10 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            style={{ padding: 6 }}
          >
            <Select
              isClearable={true}
              options={stateDetails}
              getOptionLabel={(option) => option.state_title}
              getOptionValue={(option) => option.state_id}
              onChange={handleStateChange}
              value={stateOption}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            style={{ padding: 6 }}
          >
            <Select
              isClearable={true}
              options={districtDetails}
              getOptionLabel={(option) => option.district_title}
              getOptionValue={(option) => option.districtid}
              onChange={handleDistrictChange}
              value={districtOption}
            />
          </Grid>
          {/* <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={3}
              xl={4}
              style={{ padding: 6 }}
            >
              <Button
                variant="contained"
                style={{ margin: 4 }}
                onClick={() => submitSearch()}
                color="primary"
              >
                Search
              </Button>
            </Grid> */}
        </Grid>
        <Grid container style={{ paddingLeft: 20 }}>
          {rera.length > 0 && (
            <>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                style={{ marginTop: 12 }}
              >
                <b>{`Total Count : ${rera.length}`}</b>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                style={{ marginTop: 12 }}
              >
                <b>{`Total Visited : ${
                  (exactlocations && exactlocations.length) || 0
                }`}</b>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                style={{ marginTop: 12 }}
              >
                <b>{`Yet to Visit : ${
                  (appproximateLocations && appproximateLocations.length) || 0
                }`}</b>
              </Grid>
            </>
          )}
        </Grid>
      </>
    );
  };

  const renderTableView = () => {
    return (
      <Paper>
        <div>
          {renderHeaderOption()}
          <Grid container style={{ paddingLeft: 10 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: 6 }}
            >
              {rera.length > 0 && (
                <DataGrid rows={rera} columns={columns}>
                  <SortingState
                    defaultSorting={[]}
                    columnExtensions={sortingStateColumnExtensions}
                  />
                  <IntegratedSorting />
                  <FilteringState
                    defaultFilters={[]}
                    columnExtensions={filteringStateColumnExtensions}
                  />
                  <IntegratedFiltering />
                  <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                  />
                  <IntegratedPaging />
                  <DataTypeProvider
                    for={["action"]}
                    formatterComponent={(row) => {
                      return viewBtn(row);
                    }}
                  />
                  <Table />
                  <TableHeaderRow showSortingControls />
                  <PagingPanel pageSizes={pageSizes} />
                  <TableFilterRow />
                </DataGrid>
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  };

  const renderMapView = () => {
    return (
      <Paper>
        <div>
          {renderHeaderOption()}
          <Grid container style={{ padding: 10 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: 6 }}
            >
              {(appproximateLocations.length > 0 ||
                exactlocations.length > 0) && (
                <Map
                  gps_location={
                    (appproximateLocations[0] &&
                      appproximateLocations[0].gps) ||
                    (exactlocations[0] && exactlocations[0].gps)
                  }
                  appLocation={appproximateLocations}
                  exactLocations={exactlocations}
                  isProject={true}
                  onMapClick={() => console.log("onMapClick")}
                  onZoomChanged={onZoomChanged}
                  zoom_level={zoom || 18}
                  drawingControlEnabled={false}
                  polyline={[]}
                  show_boundary={false}
                  boundary_color={false}
                  onMarkerClick={(plot) => {
                    console.log("plot", plot);
                    setToolTipContent(plot || []);
                    setOpen(true);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  };

  console.log("rera", rera);

  return (
    <Grid container>
      {userData && userData.user_type !== "F" && (
        <Grid item xs={12} style={{ textAlign: "right", marginBottom: 10 }}>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => {
              localStorage.setItem("state_filter", "");
              localStorage.setItem("district_filter", "");
              routeTo("updaterera", {
                row: {},
              });
            }}
            color="primary"
          >
            Add Rera
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => {
              localStorage.setItem("state_filter", "");
              localStorage.setItem("district_filter", "");
              routeTo("uploadrera", {
                row: {},
              });
            }}
            color="primary"
          >
            Upload Rera & GPS
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => {
              localStorage.setItem("state_filter", "");
              localStorage.setItem("district_filter", "");
              routeTo("projects", {});
            }}
            color="primary"
          >
            Back
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label={`Grid View`} {...a11yProps(0)} />
            <Tab label={`Map View`} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {renderTableView()}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {renderMapView()}
        </TabPanel>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
          message={(toast && toast.message) || "Success"}
          variant={(toast && toast.variant) || "success"}
        />

        <Dialog
          open={isDelete}
          size="md"
          fullWidth
          onClose={() => {
            setUpdateData(null);
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
          <DialogContent>Delete this item?</DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDelete(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleConfirmDelete()} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          fullWidth
          size="md"
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ background: "#ff5a3c" }}
            onClose={handleClose}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ color: "#ffffff" }}
            >
              <div>
                RERA ID:
                <span style={{ fontWeight: "bold", margin: 10 }}>
                  {(toolTipContent && toolTipContent.rera_id) || ""}
                </span>
              </div>
              <IconButton
                aria-label="close"
                style={{ color: "#ffffff" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                Registration Date:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.reg_date) || ""}
                </span>{" "}
              </Grid>
              <Grid item xs={12}>
                Project Name:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.project_name) || ""}
                </span>{" "}
              </Grid>
              <Grid item xs={12}>
                Project address:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.address) || ""}
                </span>{" "}
              </Grid>
              <Grid item xs={12}>
                Village:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.village) || ""}
                </span>{" "}
              </Grid>
              <Grid item xs={12}>
                Taluk:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.taluk) || ""}
                </span>{" "}
              </Grid>
              <Grid item xs={12}>
                District:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.district) || ""}
                </span>{" "}
              </Grid>
              <Grid item xs={12}>
                No of plots:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.no_of_plots) || ""}
                </span>{" "}
              </Grid>
              <Grid item xs={12}>
                Promoter Address:
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {(toolTipContent && toolTipContent.promoter_address) || ""}
                </span>{" "}
              </Grid>
              {toolTipContent && toolTipContent.approved_geo_location == "" && (
                <Grid item xs={12}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleLocationClick(toolTipContent.geo_location)
                    }
                  >
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "blue",
                      }}
                    >
                      Approx. Geo Location:{" "}
                    </span>
                    <span
                      style={{
                        fontSize: 12,
                        textDecoration: "underline",
                      }}
                    >
                      {toolTipContent && toolTipContent.geo_location
                        ? `https://www.google.com/maps/search/${toolTipContent.geo_location.lat},${toolTipContent.geo_location.lng}`
                        : ""}
                    </span>
                  </div>
                </Grid>
              )}
              {toolTipContent && toolTipContent.approved_geo_location && (
                <Grid item xs={12}>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleLocationClick(toolTipContent.approved_geo_location)
                    }
                  >
                    <span
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "blue",
                      }}
                    >
                      Actual Geo Location:{" "}
                    </span>
                    <span
                      style={{
                        fontSize: 12,
                        textDecoration: "underline",
                      }}
                    >
                      {toolTipContent && toolTipContent.approved_geo_location
                        ? `https://www.google.com/maps/search/${toolTipContent.approved_geo_location.lat},${toolTipContent.approved_geo_location.lng}`
                        : ""}
                    </span>
                  </div>
                </Grid>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              top: "45%",
              left: "50%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress thickness={"3"} size="4rem" />
          </div>
        )}
      </Grid>
    </Grid>
  );
}
