import { ADD_USER, UPDATE_USER, DELETE_USER, GET_ALL_USER } from "./actionType";

export const initialState = {
  status: "",
  msg: "",
  totalRecords: 0,
  data: [],
};

const userReducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_ALL_USER: {
      const newState = {
        ...state,
        status: true,
        msg: "Success",
        data: data.rows,
        totalRecords: data.total,
      };
      return newState;
    }
    case ADD_USER:
      return {
        ...state,
        data: [...state.data, data],
      };
    case UPDATE_USER:
      return {
        ...state,
        data: state.data.map((record) => (record.id === data.id ? data : record)),
      };
    case DELETE_USER:
      return {
        ...state,
        data: state.data.filter((element) => element.id !== data.id),
      };
    default:
      return state;
  }
};

export default userReducer;
