import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import XHR from "../../util/xhr";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import Switch from "@material-ui/core/Switch";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Geocode from "react-geocode";
import API_KEY from "../../util/config";

import AutoComplete from "../../components/AutoComplete";
import DrawMaps from "../../components/DrawMapsNew";
import Slide from "@material-ui/core/Slide";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { SketchPicker } from "react-color";
import SaveIcon from "@material-ui/icons/Save";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import "./Map.css";

Geocode.setApiKey(API_KEY);
Geocode.enableDebug();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  input: {
    display: "none",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "80%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function PromotersAddress() {
  const classes = useStyles();

  const history = useHistory();
  const { id = 0 } = history.location.state;
  //   const { id = 0 } = row;
  //   console.log("id", id);

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0);
  const [updateData, setUpdateData] = useState(null);

  const [data, setData] = useState({
    user_id: "",
    general_information: {
      door_no: "",
      street: "",
      address1: "",
      address2: "",
      town: "",
      city: "",
      taluk: "",
      district: "",
      state: "",
      pincode: "",
      country: "",
      landmark: "",
      no_of_projects: "",
      about_company: "",
    },
    board_of_directors: [],
    team: [],
    feedback: [],
    gallery: [],
  });

  console.log("data", data);

  const [openUpload, setOpenUpload] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [uploadObj, setUploadObj] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleUploadClose = () => {
    setOpenUpload(false);
    setUploadType(null);
    setUploadObj(null);
    setUploadStatus(null);
    setUploading(false);
  };

  const onFileChange = (event) => {
    setUploadStatus(null);
    setUploading(false);
    setUploadObj(event.target.files[0]);
  };

  const handleConfirmUpload = () => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const config = {
      headers: {
        Accept: "application/json",
        "content-type": "multipart/form-data",
      },
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      },
    };
    const formData = new FormData();
    formData.append("file", uploadObj);
    formData.append("type", uploadType === "image" ? "I" : "S");
    XHR.post("ads/upload", formData, config).then(
      (res) => {
        setUploading(false);
        setUploadStatus(res.data || null);
      },
      (err) => {
        setUploading(false);
        console.log("err", err);
      }
    );
  };

  const handlePreview = () => {
    if (uploadStatus && uploadStatus.url) {
      window.open(uploadStatus.url, "_blank");
    }
  };

  const handleSave = () => {
    console.log("uploadType", uploadType);
    if (uploadType === "image" || uploadType === "youtube") {
      const dataCopy = JSON.parse(JSON.stringify(data));
      let assets = dataCopy.gallery || [];
      assets.push({ type: uploadType, url: uploadStatus.url });
      console.log("assets", assets);
      setData({
        ...data,
        gallery: assets,
      });
    } else if (
      uploadType === "boardPhoto" ||
      uploadType === "teamPhoto" ||
      uploadType === "feedbackPhoto"
    ) {
      setUpdateData({
        ...updateData,
        photo: uploadStatus.url,
      });
    } else if (uploadType === "logo") {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          logo: uploadStatus.url,
        },
      });
    } else {
      setData({
        ...data,
        general_information: {
          ...data.general_information,
          layout: uploadStatus.url,
        },
      });
    }

    handleUploadClose();
  };

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [promotionData, setPromotionData] = useState([]);

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  useEffect(() => {
    getAllPromotorsData();
    getPlotDetails(id);
  }, []);

  const getAllPromotorsData = () => {
    setIsLoading(true);
    const payload = { promoters_id: id };
    XHR.post("realestate/promoters/getdata", payload).then(
      (res) => {
        setIsLoading(false);
        setPromotionData((res.data && res.data.list) || []);
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { company_id: id };
    XHR.post("realestate/promoters/get", payload).then(
      (res) => {
        setIsLoading(false);
        setData((res.data && res.data.companies) || {});
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const updateProject = () => {
    setIsLoading(true);
    const payload = { ...data, company_id: id };
    XHR.post("realestate/promoters/update", payload).then(
      (res) => {
        setIsLoading(false);
        handleSnackClick();
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleChange = (key1, key2, value) => {
    setData({
      ...data,
      [key1]: {
        ...data[key1],
        [key2]: value,
      },
    });
  };

  const handleParentChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const [location, setLocation] = useState(null);

  const onMapClick = (e) => {
    if (value === 0) {
      setData({
        ...data,
        ["general_information"]: {
          ...data["general_information"],
          gps_location: {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          },
        },
      });
    } else {
      setUpdateData({
        ...updateData,
        ["general_information"]: {
          ...updateData["general_information"],
          gps_location: {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
          },
        },
      });
    }
  };

  const onZoomChanged = (zoom) => {
    setData({
      ...data,
      zoom_level: zoom,
    });
  };

  // console.log("data", data);

  const renderGeneralInfo = () => {
    return (
      <>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Company info
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.company_name) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "company_name",
                  event.target.value
                )
              }
              label="Company Name"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.email) ||
                ""
              }
              onChange={(event) =>
                handleChange("general_information", "email", event.target.value)
              }
              label="Company Email"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.website) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "website",
                  event.target.value
                )
              }
              label="Company Website"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.contact) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "contact",
                  event.target.value
                )
              }
              label="Contact No"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Infographic
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.no_of_years) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "no_of_years",
                  event.target.value
                )
              }
              label="No of Years"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.no_of_cities_covered) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "no_of_cities_covered",
                  event.target.value
                )
              }
              label="No of the Cities Covered"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.no_of_project_completed) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "no_of_project_completed",
                  event.target.value
                )
              }
              label="No of Project Completed"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.no_of_project_on_going) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "no_of_project_on_going",
                  event.target.value
                )
              }
              label="No of Project On-going"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Address
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.door_no) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "door_no",
                  event.target.value
                )
              }
              label="Door No"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.street) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "street",
                  event.target.value
                )
              }
              label="Street"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.address1) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "address1",
                  event.target.value
                )
              }
              label="Address 1"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.address2) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "address2",
                  event.target.value
                )
              }
              label="Address 2"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.town) ||
                ""
              }
              onChange={(event) =>
                handleChange("general_information", "town", event.target.value)
              }
              label="village/Town name"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.city) ||
                ""
              }
              onChange={(event) =>
                handleChange("general_information", "city", event.target.value)
              }
              label="City"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.taluk) ||
                ""
              }
              onChange={(event) =>
                handleChange("general_information", "taluk", event.target.value)
              }
              label="Taulk"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.district) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "district",
                  event.target.value
                )
              }
              label="District"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.state) ||
                ""
              }
              onChange={(event) =>
                handleChange("general_information", "state", event.target.value)
              }
              label="State"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.pincode) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "pincode",
                  event.target.value
                )
              }
              label="Pincode"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.country) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "country",
                  event.target.value
                )
              }
              label="Country"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={
                (data &&
                  data.general_information &&
                  data.general_information.landmark) ||
                ""
              }
              onChange={(event) =>
                handleChange(
                  "general_information",
                  "landmark",
                  event.target.value
                )
              }
              label="Landmark"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Logo & Location
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 24 }}>
            <div style={{ display: "flex" }}>
              <Button
                onClick={() => {
                  setUploadType("logo");
                  setOpenUpload(true);
                }}
                variant="contained"
                color="primary"
                component="span"
              >
                Upload Logo
              </Button>
              {data &&
              data.general_information &&
              data.general_information.logo ? (
                <img
                  src={data.general_information.logo}
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    marginLeft: 14,
                  }}
                  height={40}
                />
              ) : null}
              {data &&
              data.general_information &&
              data.general_information.logo ? (
                <a
                  href={data.general_information.logo}
                  style={{
                    marginTop: 20,
                    marginBottom: 5,
                    marginLeft: 14,
                    textDecoration: "underline",
                  }}
                  target="_blank"
                >
                  View Logo
                </a>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={12} style={{ paddingTop: 24 }}>
            <AutoComplete
              setLocation={(val) => {
                setLocation(val && val.coor);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            {data &&
              data.general_information &&
              data.general_information.gps_location && (
                <Map
                  gps_location={
                    (data &&
                      data.general_information &&
                      data.general_information.gps_location) || {
                      lat: 13.086289405322379,
                      lng: 80.29239116173903,
                    }
                  }
                  isProject={true}
                  onMapClick={onMapClick}
                  onZoomChanged={onZoomChanged}
                  zoom_level={(data && data.zoom_level) || 18}
                  drawingControlEnabled={false}
                  polyline={(data && data.polyline) || []}
                  show_boundary={data && data.show_boundary}
                  boundary_color={data && data.boundary_color}
                />
              )}
          </Grid>
        </Grid>
      </>
    );
  };

  const renderAboutUs = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={12}>
          <TextField
            multiline
            rows="4"
            fullWidth
            value={
              (data &&
                data.general_information &&
                data.general_information.about_us) ||
              ""
            }
            onChange={(event) =>
              handleChange(
                "general_information",
                "about_us",
                event.target.value
              )
            }
            label="About Us"
          />
        </Grid>
      </Grid>
    );
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");

  const handleDeleteClick = (el, type) => {
    setUpdateData({ ...el, type });
    setIsDelete(true);
  };

  const [isDelete, setIsDelete] = useState(false);

  const renderBoardOfDirectors = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={3}>
          <Button
            onClick={() => {
              setUpdateData(null);
              setIsDialogOpen(true);
              setDialogType("board_of_directors");
            }}
            variant="contained"
            color="primary"
            component="span"
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="show_pdp_directors"
              name="show_pdp_directors"
              value={(data && data.show_pdp_directors) || 0}
              onChange={(e) =>
                handleParentChange(
                  "show_pdp_directors",
                  parseInt(e.target.value)
                )
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Show In PDP"
              />
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label="Hide In PDP"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Photo</TableCell>
                <TableCell align="left">Job Title</TableCell>
                <TableCell align="left">Email Id</TableCell>
                <TableCell align="left">Contact No</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promotionData &&
                promotionData
                  .filter((el) => el.type === "board_of_directors")
                  .map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        {row.photo && (
                          <img
                            src={row.photo}
                            style={{
                              marginLeft: 24,
                            }}
                            alt="#"
                            height={40}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">{row.job_title}</TableCell>
                      <TableCell align="left">{row.email_id}</TableCell>
                      <TableCell align="left">{row.contact_no}</TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setUpdateData(row);
                            setIsDialogOpen(true);
                            setDialogType("board_of_directors");
                          }}
                          style={{ marginLeft: 10 }}
                          component="span"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDeleteClick(row, "board")}
                          style={{ marginLeft: 10 }}
                          component="span"
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderTeam = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={3}>
          <Button
            onClick={() => {
              setUpdateData(null);
              setIsDialogOpen(true);
              setDialogType("team");
            }}
            variant="contained"
            color="primary"
            component="span"
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="show_pdp_team"
              name="show_pdp_team"
              value={(data && data.show_pdp_team) || 0}
              onChange={(e) =>
                handleParentChange("show_pdp_team", parseInt(e.target.value))
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Show In PDP"
              />
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label="Hide In PDP"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Photo</TableCell>
                <TableCell align="left">Email Id</TableCell>
                <TableCell align="left">Job Title</TableCell>
                <TableCell align="left">Contact No</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promotionData &&
                promotionData
                  .filter((el) => el.type === "team")
                  .map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        {row.photo && (
                          <img
                            src={row.photo}
                            style={{
                              marginLeft: 24,
                            }}
                            alt="#"
                            height={40}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">{row.job_title}</TableCell>
                      <TableCell align="left">{row.email_id}</TableCell>
                      <TableCell align="left">{row.contact_no}</TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setUpdateData(row);
                            setIsDialogOpen(true);
                            setDialogType("team");
                          }}
                          style={{ marginLeft: 10 }}
                          component="span"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDeleteClick(row, "team")}
                          style={{ marginLeft: 10 }}
                          component="span"
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderFeedback = () => {
    return (
      <Grid container spacing={1} item xs={12}>
        <Grid item xs={3}>
          <Button
            onClick={() => {
              setUpdateData(null);
              setIsDialogOpen(true);
              setDialogType("feedback");
            }}
            variant="contained"
            color="primary"
            component="span"
          >
            Add New
          </Button>
        </Grid>
        <Grid item xs={6}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="show_pdp_feedback"
              name="show_pdp_feedback"
              value={(data && data.show_pdp_feedback) || 0}
              onChange={(e) =>
                handleParentChange(
                  "show_pdp_feedback",
                  parseInt(e.target.value)
                )
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Show In PDP"
              />
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label="Hide In PDP"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Photo</TableCell>
                <TableCell align="left">Feedback</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promotionData &&
                promotionData
                  .filter((el) => el.type === "feedback")
                  .map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        {row.photo && (
                          <img
                            src={row.photo}
                            style={{
                              marginLeft: 24,
                            }}
                            alt="#"
                            height={40}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">{row.feedback}</TableCell>
                      <TableCell align="left">{row.location}</TableCell>
                      <TableCell align="left">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setUpdateData(row);
                            setIsDialogOpen(true);
                            setDialogType("feedback");
                          }}
                          style={{ marginLeft: 10 }}
                          component="span"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDeleteClick(row, "feedback")}
                          style={{ marginLeft: 10 }}
                          component="span"
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  };

  const renderSeoConfig = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <TextField
            multiline
            value={(data && data.seo && data.seo.title) || ""}
            onChange={(event) =>
              handleChange("seo", "title", event.target.value)
            }
            rows="3"
            fullWidth
            label="Title"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            value={(data && data.seo && data.seo.description) || ""}
            onChange={(event) =>
              handleChange("seo", "description", event.target.value)
            }
            rows="3"
            fullWidth
            label="Description"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            value={(data && data.seo && data.seo.keywords) || ""}
            onChange={(event) =>
              handleChange("seo", "keywords", event.target.value)
            }
            rows="3"
            fullWidth
            label="Keywords"
          />
        </Grid>
      </Grid>
    );
  };

  const handleAddMultipleData = (type) => {
    console.log("updateData", updateData);
    const payload = { ...updateData, promoters_id: id, type: type };
    XHR.post("realestate/promoters/updatedata", payload).then(
      (res) => {
        console.log("res.status", res);
        if (res.data.status) {
          setIsLoading(false);
          setIsDialogOpen(false);
          setDialogType("");
          setUpdateData(null);
          getAllPromotorsData();
        } else {
          setIsDialogOpen(false);
          setDialogType("");
          alert("Something went wrong");
        }
      },
      (err) => {
        setIsLoading(false);
        alert("Something went wrong");
        console.log("err", err);
      }
    );
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setUpdateData(null);
    setValue(newValue);
  };

  const handleConfirmDelete = () => {
    setIsDelete(false);
    setIsLoading(true);
    const payload = { id: updateData.id };
    let url = "realestate/promoters/deletedata";
    XHR.post(url, payload).then(
      (res) => {
        setIsLoading(false);
        getAllPromotorsData();
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const renderImages = () => {
    return (
      <>
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={2} md={2} lg={2}>
            <Button
              onClick={() => {
                setUploadType("image");
                setOpenUpload(true);
              }}
              variant="contained"
              color="primary"
              component="span"
            >
              Upload Image
            </Button>
          </Grid>
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="show_pdp_image"
                name="show_pdp_image"
                value={(data && data.show_pdp_image) || 0}
                onChange={(e) =>
                  handleParentChange("show_pdp_image", parseInt(e.target.value))
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="Show In PDP"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio color="primary" />}
                  label="Hide In PDP"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {renderPreviewGallery("image")}
      </>
    );
  };

  const renderVideos = () => {
    return (
      <>
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              fullWidth
              value={(uploadStatus && uploadStatus.url) || ""}
              onChange={(e) => {
                setUploadType("youtube");
                setUploadStatus({ url: e.target.value });
              }}
              label="Youtube url"
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              onClick={() => {
                handleSave();
              }}
              color="primary"
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={3}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="show_pdp_videos"
                name="show_pdp_videos"
                value={(data && data.show_pdp_videos) || 0}
                onChange={(e) =>
                  handleParentChange(
                    "show_pdp_videos",
                    parseInt(e.target.value)
                  )
                }
              >
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="Show In PDP"
                />
                <FormControlLabel
                  value={0}
                  control={<Radio color="primary" />}
                  label="Hide In PDP"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {renderPreviewGallery("youtube")}
      </>
    );
  };

  const handleRemoveGallery = (index, type) => {
    const assets =
      data && data.gallery && data.gallery.filter((el) => el.type === type);

    const allAssets =
      data && data.gallery && data.gallery.filter((el) => el.type !== type);

    const dataCopy = JSON.parse(JSON.stringify(data));
    assets.splice(index, 1); // 2nd parameter means remove one item only
    setData({
      ...data,
      gallery: allAssets.concat(assets),
    });
  };

  const renderPreviewGallery = (previewType) => {
    const assets =
      data &&
      data.gallery &&
      data.gallery.filter((el) => el.type === previewType);
    return (
      <>
        {assets && assets.length > 0 && (
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <span
              style={{ fontWeight: "bold", fontSize: 14, marginBottom: 10 }}
            >
              Preview Gallery{" "}
              {/* {previewType === "image"
                ? "(Checked images display in Banner section)"
                : ""} */}
            </span>
            <div>
              {(assets || []).map((el, i) => (
                <div
                  key={i}
                  style={{ display: "flex", margin: 10, alignItems: "center" }}
                >
                  {/* {previewType === "image" && (
                    <Checkbox
                      checked={el.isBanner || false}
                      onChange={(e) =>
                        handleCheckGallery(e.target.checked, i, assets)
                      }
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  )} */}
                  <a
                    href={el.url}
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      marginRight: 10,
                      textDecoration: "underline",
                    }}
                    target="_blank"
                  >
                    {i + 1}.{previewType === "youtube" ? el.url : el.type}
                  </a>
                  <span
                    onClick={() => handleRemoveGallery(i, previewType)}
                    style={{ cursor: "pointer", marginTop: 5, marginBottom: 5 }}
                  >
                    Remove
                  </span>
                </div>
              ))}
            </div>
          </Grid>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={10} style={{ textAlign: "right", marginBottom: 10 }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="enable_promotor_page"
              name="enable_promotor_page"
              value={(data && data.enable_promotor_page) || 0}
              onChange={(e) =>
                handleParentChange(
                  "enable_promotor_page",
                  parseInt(e.target.value)
                )
              }
            >
              <FormControlLabel
                value={1}
                control={<Radio color="primary" />}
                label="Enable Promoter Page"
              />
              <FormControlLabel
                value={0}
                control={<Radio color="primary" />}
                label="Disable Promoter Page"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={2} style={{ textAlign: "right", marginBottom: 10 }}>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => history.goBack()}
            color="primary"
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => updateProject()}
            color="primary"
          >
            Update Details
          </Button>
        </Grid>
        <Grid container spacing={1} style={{ margin: 10 }}>
          {/* {verifyAddress()} */}
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="1. General information" {...a11yProps(0)} />
            <Tab label="2. Board of Directors" {...a11yProps(1)} />
            <Tab label="3. Team" {...a11yProps(2)} />
            <Tab label="4. Customer Feedback" {...a11yProps(3)} />
            <Tab label="5. About us" {...a11yProps(4)} />
            <Tab label="6. Images" {...a11yProps(5)} />
            <Tab label="7. Videos" {...a11yProps(6)} />
            <Tab label="8. SEO Config" {...a11yProps(7)} />
          </Tabs>

          <TabPanel value={value} index={0}>
            {renderGeneralInfo()}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {renderBoardOfDirectors()}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {renderTeam()}
          </TabPanel>
          <TabPanel value={value} index={3}>
            {renderFeedback()}
          </TabPanel>
          <TabPanel value={value} index={4}>
            {renderAboutUs()}
          </TabPanel>
          <TabPanel value={value} index={5}>
            {renderImages()}
          </TabPanel>
          <TabPanel value={value} index={6}>
            {renderVideos()}
          </TabPanel>
          <TabPanel value={value} index={7}>
            {renderSeoConfig()}
          </TabPanel>
        </Grid>
      </Grid>

      <Dialog
        open={openUpload}
        size="md"
        fullWidth
        onClose={handleUploadClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Upload</DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            {(uploadType === "image" ||
              uploadType === "logo" ||
              uploadType === "layout" ||
              uploadType === "boardPhoto" ||
              uploadType === "teamPhoto" ||
              uploadType === "feedbackPhoto") && (
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="image-button-file"
                  onChange={onFileChange}
                  type="file"
                />
                {!uploadObj ? (
                  <label htmlFor="image-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Choose Image
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10 }}>
                      {uploadObj.name || "Image"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          onClick={handleConfirmUpload}
                          style={{ marginRight: 10 }}
                        >
                          {uploading ? "Uploading" : "Upload Image"}
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                        >
                          Preview Image
                        </Button>
                        <label htmlFor="image-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Image
                          </Button>
                        </label>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
            {uploadType === "audio" && (
              <Grid item xs={12}>
                <input
                  accept="audio/*"
                  className={classes.input}
                  id="audio-button-file"
                  onChange={onFileChange}
                  type="file"
                />

                {!uploadObj ? (
                  <label htmlFor="audio-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Choose Audio
                    </Button>
                  </label>
                ) : (
                  <>
                    <Typography style={{ marginBottom: 10 }}>
                      {uploadObj.name || "Audio"}
                    </Typography>
                    {!uploadStatus ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleConfirmUpload}
                          component="span"
                          style={{ marginRight: 10 }}
                        >
                          {uploading ? "Uploading" : "Upload Audio"}
                        </Button>
                        <label htmlFor="audio-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Audio
                          </Button>
                        </label>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handlePreview}
                          style={{ marginRight: 10 }}
                          component="span"
                        >
                          Preview Audio
                        </Button>
                        <label htmlFor="audio-button-file">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Choose New Audio
                          </Button>
                        </label>
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadClose} color="primary">
            Close
          </Button>
          {uploadStatus && (
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDialogOpen}
        fullWidth
        maxWidth={"lg"}
        TransitionComponent={Transition}
        onClose={() => {
          setIsDialogOpen(false);
          setUpdateData(null);
          setDialogType("");
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setIsDialogOpen(false);
                setUpdateData(null);
                setDialogType("");
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Add/Update{" "}
              {dialogType === "board_of_directors"
                ? "Board of Directors"
                : dialogType === "team"
                ? "Team"
                : dialogType === "feedback"
                ? "Feedback"
                : ""}
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                handleAddMultipleData(
                  dialogType === "board_of_directors"
                    ? "board_of_directors"
                    : dialogType === "team"
                    ? "team"
                    : dialogType === "feedback"
                    ? "feedback"
                    : ""
                );
              }}
            >
              save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Grid container spacing={1}>
            {dialogType === "board_of_directors" ? (
              <>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        name: e.target.value,
                      })
                    }
                    id="name-basic"
                    label="Name"
                  />
                </Grid>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.job_title}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        job_title: e.target.value,
                      })
                    }
                    id="job-basic"
                    label="Job Title"
                  />
                </Grid>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.email_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        email_id: e.target.value,
                      })
                    }
                    id="email-basic"
                    label="Email Id"
                  />
                </Grid>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.contact_no}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        contact_no: e.target.value,
                      })
                    }
                    id="contact-basic"
                    label="Contact No"
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 24, paddingLeft: 24 }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        setUploadType("boardPhoto");
                        setOpenUpload(true);
                      }}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Photo
                    </Button>
                    {updateData && updateData.photo ? (
                      <img
                        src={updateData.photo}
                        style={{
                          marginLeft: 24,
                        }}
                        alt="#"
                        height={40}
                      />
                    ) : null}
                  </div>
                </Grid>{" "}
              </>
            ) : dialogType === "team" ? (
              <>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        name: e.target.value,
                      })
                    }
                    id="name-basic"
                    label="Name"
                  />
                </Grid>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.email_id}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        email_id: e.target.value,
                      })
                    }
                    id="email-basic"
                    label="Email Id"
                  />
                </Grid>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.contact_no}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        contact_no: e.target.value,
                      })
                    }
                    id="contact-basic"
                    label="Contact No"
                  />
                </Grid>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.job_title}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        job_title: e.target.value,
                      })
                    }
                    id="job-basic"
                    label="Job Title"
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 24, paddingLeft: 24 }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        setUploadType("teamPhoto");
                        setOpenUpload(true);
                      }}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Photo
                    </Button>
                    {updateData && updateData.photo ? (
                      <img
                        src={updateData.photo}
                        style={{
                          marginLeft: 24,
                        }}
                        alt="#"
                        height={40}
                      />
                    ) : null}
                  </div>
                </Grid>{" "}
              </>
            ) : dialogType === "feedback" ? (
              <>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.name}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        name: e.target.value,
                      })
                    }
                    id="name-basic"
                    label="Name"
                  />
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 24, paddingLeft: 24 }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => {
                        setUploadType("feedbackPhoto");
                        setOpenUpload(true);
                      }}
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload Photo
                    </Button>
                    {updateData && updateData.photo ? (
                      <img
                        src={updateData.photo}
                        style={{
                          marginLeft: 24,
                        }}
                        alt="#"
                        height={40}
                      />
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs="6">
                  <TextField
                    multiline
                    value={updateData && updateData.feedback}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        feedback: e.target.value,
                      })
                    }
                    rows="3"
                    fullWidth
                    label="Feedback"
                  />
                </Grid>
                <Grid item xs="6">
                  <TextField
                    fullWidth
                    value={updateData && updateData.location}
                    onChange={(e) =>
                      setUpdateData({
                        ...updateData,
                        location: e.target.value,
                      })
                    }
                    style={{ marginTop: 22 }}
                    id="location-basic"
                    label="Location"
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDelete}
        size="md"
        fullWidth
        onClose={() => {
          setUpdateData(null);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
        <DialogContent>Delete this item?</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDelete(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirmDelete()} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message="Success"
        variant="success"
      />
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </>
  );
}
