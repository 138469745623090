import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import XHR from "../../util/xhr";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import Switch from "@material-ui/core/Switch";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Geocode from "react-geocode";
import API_KEY from "../../util/config";

import AutoComplete from "../../components/AutoComplete";
import DrawMaps from "../../components/DrawMapsNew";
import Slide from "@material-ui/core/Slide";

import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { SketchPicker } from "react-color";
import SaveIcon from "@material-ui/icons/Save";
import ReorderIcon from "@material-ui/icons/Reorder";

import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import * as XLSX from "xlsx";

import "./Map.css";

Geocode.setApiKey(API_KEY);
Geocode.enableDebug();

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  table: {
    minWidth: 650,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  input: {
    display: "none",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Map = (props) => (
  <DrawMaps
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=drawing`}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `500px` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    gps_location={props.gps_location}
    onClick={props.onMapClick}
    isProject={props.isProject}
    zoom_level={props.zoom_level}
    onZoomChanged={props.onZoomChanged}
    drawingControlEnabled={props.drawingControlEnabled}
    overlay={props.overlay}
    polyline={props.polyline}
    show_boundary={props.show_boundary}
    boundary_color={props.boundary_color}
  />
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "80%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function UpdateRera() {
  const classes = useStyles();

  const history = useHistory();
  const { row = {} } = history.location.state;
  const { id = 0 } = row;

  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [approveLocation, setApproveLocation] = useState(null);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState({
    rera_id: "",
    project_name: "",
    project_details: "",
    address: "",
    village: "",
    taluk: "",
    district: "",
    no_of_plots: "",
    promoter_name: "",
    promoter_address: "",
    geo_location: "",
    geo_location_display: "",
    approved_geo_location: "",
    approved_geo_location_display: "",
    zoom_level: 18,
    approved_zoom_level: 18,
  });
  const [statusMessage, setStatusMessage] = useState(null);

  useEffect(() => {
    loadData(id);
  }, []);

  const loadData = (refid) => {
    getPlotDetails(refid);
  };

  const getPlotDetails = (id) => {
    setIsLoading(true);
    const payload = { id };
    XHR.post("realestate/rera/get", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data && res.data.data) {
          setData({
            ...data,
            ...res.data.data,
            geo_location_display:
              res.data.data.geo_location &&
              `${res.data.data.geo_location.lat},${res.data.data.geo_location.lng}`,
            approved_geo_location_display:
              res.data.data.approved_geo_location &&
              `${res.data.data.approved_geo_location.lat},${res.data.data.approved_geo_location.lng}`,
          });
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const [snackOpen, setSnackOpen] = React.useState(false);

  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    setData({
      ...data,
      geo_location: location,
    });
  }, [location]);

  useEffect(() => {
    setData({
      ...data,
      approved_geo_location: approveLocation,
    });
  }, [approveLocation]);

  const updateProject = () => {
    setIsLoading(true);
    const payload = { ...data, id };
    XHR.post("realestate/rera/update", payload).then(
      (res) => {
        setIsLoading(false);
        if (res.data.status) {
          setStatusMessage("Success");
          handleSnackClick();
        } else {
          setStatusMessage("Already Exists / Error in saving");
          handleSnackClick();
        }
      },
      (err) => {
        setIsLoading(false);
        console.log("err", err);
      }
    );
  };

  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const routeTo = (routeName, props) => {
    history.push(routeName, props);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const onGeoLocationChange = (key, value) => {
    var partsOfStr = value.replace(/\s+/g, "").split(",");
    if (key === "approved_geo_location_display") {
      setData({
        ...data,
        [key]: value,
        ["approved_geo_location"]: {
          lat:
            typeof partsOfStr[0] !== "undefined" && partsOfStr[0] !== ""
              ? parseFloat(partsOfStr[0])
              : "",
          lng:
            typeof partsOfStr[1] !== "undefined" && partsOfStr[1] !== ""
              ? parseFloat(partsOfStr[1])
              : "",
        },
      });
    } else if (key === "geo_location_display") {
      setData({
        ...data,
        [key]: value,
        ["geo_location"]: {
          lat:
            typeof partsOfStr[0] !== "undefined" && partsOfStr[0] !== ""
              ? parseFloat(partsOfStr[0])
              : "",
          lng:
            typeof partsOfStr[1] !== "undefined" && partsOfStr[1] !== ""
              ? parseFloat(partsOfStr[1])
              : "",
        },
      });
    }
  };

  const onMapClick = (e) => {
    setData({
      ...data,
      geo_location: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const onMapApprovedClick = (e) => {
    setData({
      ...data,
      approved_geo_location: {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      },
    });
  };

  const handleParentChange = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const onZoomChanged = (zoom) => {
    setData({
      ...data,
      zoom_level: zoom,
    });
  };

  const onZoomApprovedChanged = (zoom) => {
    setData({
      ...data,
      approved_zoom_level: zoom,
    });
  };

  const handleLocationClick = (url) => {
    if (url != "") {
      window.open(url, "_blank");
    }
  };

  const renderGeneralInfo = () => {
    return (
      <>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            RERA info
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={(data && data.rera_id) || ""}
              onChange={(event) =>
                handleParentChange("rera_id", event.target.value)
              }
              label="RERA ID"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Project Details
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={(data && data.project_name) || ""}
              onChange={(event) =>
                handleParentChange("project_name", event.target.value)
              }
              label="Project Name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              multiline
              value={(data && data.project_details) || ""}
              onChange={(event) =>
                handleParentChange("project_details", event.target.value)
              }
              rows="3"
              fullWidth
              label="Project Details"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              multiline
              value={(data && data.address) || ""}
              onChange={(event) =>
                handleParentChange("address", event.target.value)
              }
              rows="3"
              fullWidth
              label="Address"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={(data && data.village) || ""}
              onChange={(event) =>
                handleParentChange("village", event.target.value)
              }
              label="Village"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={(data && data.taluk) || ""}
              onChange={(event) =>
                handleParentChange("taluk", event.target.value)
              }
              label="Taluk"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={(data && data.district) || ""}
              onChange={(event) =>
                handleParentChange("district", event.target.value)
              }
              label="District"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Plot Details
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              value={(data && data.no_of_plots) || ""}
              onChange={(event) =>
                handleParentChange("no_of_plots", event.target.value)
              }
              label="No of Plots"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Promoter Details
          </Grid>
          <Grid item xs={6}>
            <TextField
              multiline
              value={(data && data.promoter_name) || ""}
              onChange={(event) =>
                handleParentChange("promoter_name", event.target.value)
              }
              rows="3"
              fullWidth
              label="Name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              multiline
              value={(data && data.promoter_address) || ""}
              onChange={(event) =>
                handleParentChange("promoter_address", event.target.value)
              }
              rows="3"
              fullWidth
              label="Address"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Approx. Geo Location
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              fullWidth
              value={data && data.geo_location_display}
              onChange={(event) =>
                onGeoLocationChange("geo_location_display", event.target.value)
              }
              label="Approx. Geo Location"
            />
          </Grid>
          {data && data.geo_location && (
            <Grid item xs={12} md={12} lg={12}>
              <span
                style={{
                  fontSize: 16,
                  textDecoration: "underline",
                  fontWeight: "bolder",
                  cursor: "pointer",
                }}
                onClick={() =>
                  data && data.geo_location
                    ? handleLocationClick(
                        `https://www.google.com/maps/search/${data.geo_location.lat},${data.geo_location.lng}`
                      )
                    : null
                }
              >
                {data && data.geo_location
                  ? `https://www.google.com/maps/search/${data.geo_location.lat},${data.geo_location.lng}`
                  : ""}
              </span>
            </Grid>
          )}
          {/* <Grid item xs={12} md={12} lg={12}>
            {data && data.geo_location && (
              <Map
                gps_location={
                  (data && data.geo_location) || {
                    lat: 13.086289405322379,
                    lng: 80.29239116173903,
                  }
                }
                isProject={true}
                onMapClick={onMapClick}
                onZoomChanged={onZoomChanged}
                zoom_level={(data && data.zoom_level) || 18}
                drawingControlEnabled={false}
                polyline={(data && data.polyline) || []}
                show_boundary={data && data.show_boundary}
                boundary_color={data && data.boundary_color}
              />
            )}
          </Grid> */}
        </Grid>
        <Grid container spacing={1} item xs={12} className="grid-bottom-border">
          <Grid item xs={12} className="grid-header-title">
            Actual Geo Location
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              fullWidth
              value={data && data.approved_geo_location_display}
              onChange={(event) =>
                onGeoLocationChange(
                  "approved_geo_location_display",
                  event.target.value
                )
              }
              label="Actual Geo Location"
            />
          </Grid>
          {data && data.approved_geo_location && (
            <Grid item xs={12} md={12} lg={12}>
              <span
                style={{
                  fontSize: 16,
                  textDecoration: "underline",
                  fontWeight: "bolder",
                  cursor: "pointer",
                }}
                onClick={() =>
                  data && data.approved_geo_location
                    ? handleLocationClick(
                        `https://www.google.com/maps/search/${data.approved_geo_location.lat},${data.approved_geo_location.lng}`
                      )
                    : null
                }
              >
                {data && data.approved_geo_location
                  ? `https://www.google.com/maps/search/${data.approved_geo_location.lat},${data.approved_geo_location.lng}`
                  : ""}
              </span>
            </Grid>
          )}
          {/* <Grid item xs={12} md={12} lg={12}>
            {data && data.approved_geo_location && (
              <Map
                gps_location={
                  (data && data.approved_geo_location) || {
                    lat: 13.086289405322379,
                    lng: 80.29239116173903,
                  }
                }
                isProject={true}
                onMapClick={onMapApprovedClick}
                onZoomChanged={onZoomApprovedChanged}
                zoom_level={(data && data.approved_zoom_level) || 18}
                drawingControlEnabled={false}
                polyline={(data && data.polyline) || []}
                show_boundary={data && data.show_boundary}
                boundary_color={data && data.boundary_color}
              />
            )}
          </Grid> */}
        </Grid>
      </>
    );
  };

  const chooseLocation = () => {
    console.log("data", data);
    return <Grid container spacing={3}></Grid>;
  };

  const chooseApproveLocation = () => {
    console.log("data", data);
    return <Grid container spacing={3}></Grid>;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          <span style={{ fontWeight: "bold", fontSize: 20 }}>
            Project - {name}
          </span>
        </Grid>
        <Grid item xs={6} style={{ textAlign: "right", marginBottom: 10 }}>
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => history.replace("/rera", {})}
            color="primary"
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={() => updateProject()}
            color="primary"
          >
            Update Details
          </Button>
        </Grid>
        <Grid container spacing={1}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab label="1. Basic Details" {...a11yProps(0)} />
            {/* <Tab label="2. Location Details" {...a11yProps(1)} /> */}
            {/* <Tab label="3. Approved Location" {...a11yProps(2)} /> */}
          </Tabs>

          <TabPanel value={value} index={0}>
            {renderGeneralInfo()}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* {chooseLocation()} */}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* {chooseApproveLocation()} */}
          </TabPanel>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        message={statusMessage}
        variant="success"
      ></Snackbar>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            top: "45%",
            left: "50%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress thickness={"3"} size="4rem" />
        </div>
      )}
    </>
  );
}
